import React from 'react';
import Firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const useStyles = styled((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '/',
  signInOptions: [
    Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: Firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: Firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
    }
  ],
};

const SignIn = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">

      <Box mt={5} mb={5}>
        <Typography
          variant="h3"
          align="center"
        >
          Lynky.IO
        </Typography>
      </Box>

      <Paper
        elevation={3}
        className={classes.paper}
      >
        <Grid
          container
          direction="column"
        >
          <Grid item xs={12}>
            <Box mt={3} mb={3}>
              <Typography
                variant="h4"
                component="h1"
                align="center">
                Sign in
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={Firebase.auth()} />
          </Grid>

          <Box mt={3}>
            <Typography variant="body1" align="center" gutterBottom>
              Don't have an account? 
              <Link to={'/signup'}>
                Sign up
              </Link>
            </Typography>
          </Box>
          
        </Grid>
      </Paper>
    </Container>
  )
};

export default SignIn
