import { nanoid } from 'nanoid'

const condition = () => {
  return {
    id: nanoid(6),
    operator: 'if',
    type: '',
    target: {},
  }
};

const item = () => {
  return {
    id: nanoid(6),
    type: 'direct',
    url: 'https://',
    conditions: [
      condition(),
    ],
  }
};

const initialState = () => {
  return {
    type: 'redirect',
    title: '',
    default: {
      type: 'direct',
      url: 'https://',
    },
    items: [
      item(),
      item(),
    ],
    groups: [],
  }
}

export { initialState, item, condition }
