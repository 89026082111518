import React, { useState, useEffect } from 'react';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Redirect from './Redirect';

import {
  ADD_REDIRECT,
  MOVE_REDIRECT,
} from '../Constants';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReorderIcon from '@mui/icons-material/Reorder';
import DoneIcon from '@mui/icons-material/Done';

const Redirects = ({ redirects, dispatch }) => {
  const [editing, setEditing] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    if (redirects.length === 1)
      setEditing(false);
  }, [redirects.length]);

  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="h6" component="p">
          Redirects:
        </Typography>

        <Stack
          direction="row"
          spacing={1}
        >
          {redirects.length > 1 &&
            <Button
              variant="contained"
              color="secondary"
              startIcon={editing ? <DoneIcon /> : <ReorderIcon />}
              size="small"
              onClick={() => setEditing(!editing)}
            >
              {editing ? 'Done' : 'Edit'}
            </Button>
          }

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddBoxIcon />}
            size="small"
            onClick={() =>
              dispatch({
                type: ADD_REDIRECT,
              })
            }
          >
            Redirect
          </Button>
        </Stack>
      </Stack>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(e) =>
          dispatch({ type: MOVE_REDIRECT, value: e })
        }
      >
        <SortableContext
          items={redirects}
          strategy={verticalListSortingStrategy}
        >
          {redirects.map((redirect) =>
            <Redirect
              key={redirect.id}
              id={redirect.id}
              data={redirect}
              editing={editing}
              dispatch={dispatch}
            />
          )}
        </SortableContext>
      </DndContext>
    </Stack>
  );
};

export default Redirects;
