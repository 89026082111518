import React, { useContext, useState } from 'react';
import ChoiceContext from './Context';

import Title from 'forms/components/Title';
import Description from './Components/Description';
import Choices from './Components/Choices';
import Params from 'forms/components/Params';
import Groups from 'forms/components/Groups';
import UTM from 'forms/components/UTM';

// Components
import Avatar from './Components/Images/Avatar/Index';
import Background from './Components/Images/Background/Index';

// UI
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const Form = ({ submit, cancel }) => {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(ChoiceContext);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);
    submit(state);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>

        <Grid container>
          <Grid item xs={12}>
            <Background
              data={state.background}
              dispatch={dispatch}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
              >
                <Grid item xs={5} sm={3}>
                  <Avatar
                    data={state.avatar}
                    dispatch={dispatch}
                  />
                </Grid>
              </Grid>
            </Background>
          </Grid>
        </Grid>

        <Title
          data={state.title}
          dispatch={dispatch}
        />

        <Description
          data={state.description}
          dispatch={dispatch}
        />

        <Choices
          data={state.items}
          dispatch={dispatch}
        />

        <UTM
          data={state.utm}
          dispatch={dispatch}
        />

        <Params
          data={state.params}
          dispatch={dispatch}
        />
        
        <Groups
          data={state.groups}
          dispatch={dispatch}
        />

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={cancel}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            // disabled={JSON.stringify(data) === JSON.stringify(state)}
            startIcon={loading && <CircularProgress style={{color: '#fff'}} size={20} />}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;
