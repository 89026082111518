import React, {
  useState,
  useEffect,
  useContext,
  createContext
} from "react";
import Firebase from 'firebase/app';
import { UserContext } from './user';

const InvitesContext = createContext();

const InvitesProvider = ({ children }) => {
  const { userLoading, userId } = useContext(UserContext);

  const [state, setState] = useState({
    invitesLoading: true,
    invitesError: false,
    invitesData: null,
  });

  useEffect(() => {
    if (!userLoading && userId) {

      const unsubscribe = Firebase
        .firestore()
        .collection('invites')
        .where('invitedBy', '==', userId)
        .onSnapshot((querySnapshot) => {
          const invites = [];

          querySnapshot.forEach((doc) => invites.push({
            id: doc.id,
            ...doc.data(),
          }));

          setState(prevState => ({
            ...prevState,
            invitesLoading: false,
            invitesError: false,
            invitesData: invites,
          }));
        });

      return () => unsubscribe();

    } else if (!userLoading && !userId) {
      setState({
        invitesLoading: false,
        invitesError: false,
        invitesData: null,
      });
    }
  }, [userLoading, userId]);

  return (
    <InvitesContext.Provider value={state}>
      {children}
    </InvitesContext.Provider>
  );
};

export { InvitesContext, InvitesProvider };
