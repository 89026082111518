import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Title = ({ children }) =>
  <Box sx={{ margin: (theme) => `${theme.spacing(2)} 0` }}>
    <Typography variant="h5" component="h1">
      {children}
    </Typography>
  </Box>

export default Title;
