import React, { useState } from 'react';

// Components
import Param from './Param';

// UI
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const Create = () => {
  const [ open, setOpen ] = useState(false);

  const handleSubmit = () => setOpen(false);
  const handleCancel = () => setOpen(false);

  return (
    <React.Fragment>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
      >
        Add
      </Button>

      <Param
        open={open}
        submit={handleSubmit}
        cancel={handleCancel}
      />
    </React.Fragment>
  );
};

export default Create;
