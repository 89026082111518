import React, { useState, useContext, useEffect } from 'react';
import Firebase from 'firebase/app'
import { HeaderContext } from 'stores/header';

// UI
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Profile = () => {
  const [alert, setAlert] = useState(false);
  const user = Firebase.auth().currentUser;
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => setHeader({ title: 'Profile' }), []);

  const save = (e) => {
    e.preventDefault();

    const { value } = e.target.displayName;

    if (user.displayName !== value) {
      user.updateProfile({
        displayName: value,
      }).then(() => setAlert(true));
    }
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <>
      <form onSubmit={save}>
        <TextField
          name="displayName"
          id="displayName"
          label="Full name"
          defaultValue={user.displayName ? user.displayName : ''}
          helperText={!user.displayName ? 'Please insert your full name here' : ''}
          fullWidth
          required
        />

        <Box mt={3}>
          <TextField
            name="email"
            id="email"
            label="Email"
            value={user.email}
            helperText={!user.emailVerified ? 'Please verify your email address' : ''}
            fullWidth
            required
            disabled
          />
        </Box>

        <Box mt={3}>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </Box>
      </form>

      <Snackbar open={alert} autoHideDuration={3000} onClose={handleAlert}>
        <Alert onClose={handleAlert} severity="success">
          Settings saved  
        </Alert>
      </Snackbar>
    </>
  );
};

export default Profile;
