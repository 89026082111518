import React, { useContext } from 'react';

// Data
import { LinksContext } from 'stores/links';

// Components
import LinksList from 'components/LinksList';
import Card from 'components/Card';

const LatestLinksCard = () => {
  const { linksList } = useContext(LinksContext);

  return (
    <Card
      title="Recently added"
    >
      <LinksList
        data={linksList.filter((link, index) => index < 10)}
      />
    </Card>
  );
};

export default LatestLinksCard;
