import React, { useState, useEffect } from 'react';
import { nanoid } from 'nanoid'
import IPut from 'iput';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';

const ip = () => {
  return {
    id: nanoid(6),
  }
}

const IPAddress = ({ target, save }) => {
  const [ state, setState ] = useState(target.values ? target.values : [ip()]);

  useEffect(() => {
    save({
      values: [
        ...state,
      ],
    });
  }, [state]);

  const handleChange = (value, id) => {
    const newState = [...state];

    const index = newState.findIndex(ip => ip.id === id);
    newState[index].value = value;

    setState(newState);
  };

  const add = () =>
    setState(prevState => {
      return [
        ...prevState,
        ip(),
      ]
    });

  const remove = (id) =>
    setState(prevState => {
      return [
        ...prevState.filter((el) => el.id !== id)
      ]
    });

  return (
    <Stack spacing={2} alignItems="center">
      {state.map((ip) =>
        <Stack key={ip.id} direction="row" spacing={2}>
          <IPut
            defaultValue={ip.value ?? ip.value}
            onChange={(e) => handleChange(e, ip.id)}
            isError={(val) => val ? false : true}
          />

          <IconButton onClick={() => remove(ip.id)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      )}

      <Button
        onClick={add}
        variant="outlined"
        startIcon={<AddBoxIcon />}
      >
        OR Another IP
      </Button>
    </Stack>
  )
};

export default IPAddress;
