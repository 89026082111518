import React from 'react';

// Components
import LinkUrl from 'components/LinkUrl';

// UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// Actions
import { SET_FIELD } from '../Constants';

const Destination = ({ data, dispatch }) => {

  const handleChange = (e) =>
    dispatch({
      type: SET_FIELD,
      value: {
        field: 'url',
        value: e.target.value,
      }
    })

  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Typography variant="h6" component="p">
        Destination:
      </Typography>

      <LinkUrl
        id="url"
        label="URL"
        name="url"
        data={data}
        label="URL"
        save={handleChange}
        required
        fullWidth
      />
    </Stack>
  );
};

export default Destination;
