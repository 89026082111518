import React, { useState } from 'react';

// UI
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Card = ({ data, edit, remove }) => {
  const [ expanded, setExpanded ] = useState(false);

  return (
    <Accordion
      key={data.id}
      component={Paper}
      elevation={expanded  ? 6 : 1}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      sx={{ width: '100%' }}
    >
      <AccordionSummary
        sx={{
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            justifyContent: 'space-between',
          }
        }}
      >
        <Typography>
          {data.data().title}
        </Typography> 

        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <Table> 
            <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data().params.map((param) =>
                <TableRow key={param.key}>
                  <TableCell>{param.key}</TableCell>
                  <TableCell>{param.value}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> 

          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            <Button
              onClick={() => remove(data)}
              startIcon={<DeleteIcon />} 
              variant="contained"
              size="small"
              color="secondary"
            >
              Delete
            </Button>

            <Button
              onClick={() => edit(data)}
              startIcon={<EditIcon />} 
              variant="contained"
              size="small"
              color="primary"
            >
              Edit
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default Card;
