import React from 'react';

import Affiliate from './Affiliate/Index';
import Delete from './Delete';

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const List = ({ organization, data, hasPrivileges }) => {

  return (
    <Stack spacing={2}>
      {data.map((program, programIndex) =>
        <Card key={programIndex}>
          <CardHeader
            title={program.title}
            action={
              hasPrivileges &&
              <React.Fragment>
                <Delete 
                  organization={organization}
                  program={program}
                />

                <Affiliate
                  organization={organization}
                  data={data}
                  index={programIndex}
                />
              </React.Fragment>
            }
            titleTypographyProps={{
              variant: "body1"
            }}
          />

          <Divider />

          <CardContent>
            <Stack spacing={2}>
              {program.params.map((param, paramIndex) =>
                <Typography key={paramIndex}>
                  {param.key}: {param.value}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card> 
      )}
    </Stack>
  );
};

export default List;
