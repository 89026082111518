import React, { useState, useEffect, useContext, createContext } from "react";

import { AlertsContext } from './alerts';
import { SubscriptionContext } from './subscription';
import { OrganizationsContext } from './organizations';
import { InvitesContext } from './invites';
import { isActive } from '../utilities/user';
import { analyticsGetCount } from 'services/analytics';

const getClicks = async (plan, organizations) => {
  if (isActive(plan)) {
    const fromDate = new Date(plan.current_period_start.seconds * 1000).toISOString().slice(0, 19).replace('T', ' ');
    const toDate = new Date(plan.current_period_end.seconds * 1000).toISOString().slice(0, 19).replace('T', ' ');

    return Promise.all(
        organizations.map((organization) =>
          analyticsGetCount({
            column: 'organization',
            value: organization.id,
            from: fromDate,
            to: toDate,
          }).then((res) => res.data[0]['value'])
        )
      )
      .then((data) => data.reduce((total, clicks) => total + clicks, 0))
      .catch(() => 0);
  } else {
    return 0;
  }
}

const getMembers = (organizations, invites) => {
  if (!organizations.length) return 0;
  
  const membersInOrganization = organizations.reduce((total, organization) => total + (Object.keys(organization.roles).length - 1), 0);
  const totalMembers = membersInOrganization + invites.length;

  return totalMembers;
}

const UsageContext = createContext();

const UsageProvider = (props) => {
  const { setAlert, removeAlert } = useContext(AlertsContext);
  const { organizationsData } = useContext(OrganizationsContext);
  const { invitesData } = useContext(InvitesContext);
  const { subscriptionLoading, subscriptionData, subscriptionLimits } = useContext(SubscriptionContext);

  const [state, setState] = useState({
    usageLoading: true,
    usageError: false,
    usageData: {},
  });

  useEffect(async () => {
    if (!subscriptionLoading && organizationsData && invitesData) {

      // If clicks go over the limit + 10%
      // - set alert for plan upgrade
      // - otherwise freeze the links until the next renewal
      const clicks = await getClicks(subscriptionData, organizationsData);

      if (isActive(subscriptionData))
        clicks > subscriptionLimits.clicks
          ? setAlert({
              id: 'clicks-limit-crossed',
              message: 'Clicks limit reached, please consider upgrading your plan'})
          : removeAlert('clicks-limit-crossed');
      
      // organizations
      // If plan was downgraded and there are more organizations than allowed by the
      // new plan
      // set alert to remove the unused organizations
      // otherwise lock the oldest organizations
      if (isActive(subscriptionData))
        organizationsData.length > subscriptionLimits.organizations
          ? setAlert({
              id: 'organizations-limit-crossed',
              message: 'Organizations limit reached, please remove unused organizations or consider upgrading your plan'
            })
          : removeAlert('organizations-limit-crossed')
      
      // Alerts for members
      // If plan was downgraded and there are more members than allowed in the
      // organization
      // set alert to remove some members
      // otherwise lock the organization
      const members = getMembers(organizationsData, invitesData);

      if (isActive(subscriptionData))
        members > subscriptionLimits.members
          ? setAlert({
              id: 'members-limit-crossed',
              message: `Your have crossed the limit of ${subscriptionLimits.members} members, please remove unactive members or consider upgrading your plan` })
          : removeAlert('members-limit-crossed');

      setState(prevState => ({
        ...prevState,
        usageLoading: false,
        usageError: false,
        usageData: {
          clicks,
          organizations: organizationsData.length,
          members,
        },
      }));

    } else if (!subscriptionLoading && !organizationsData) {
      setState({
        usageLoading: false,
        usageError: false,
        usageData: {
          clicks: 0,
          organizations: 0,
          members: 0,
        },
      });

    };
  }, [subscriptionLoading, organizationsData, invitesData]);

  return (
    <UsageContext.Provider value={state}>
      {props.children}
    </UsageContext.Provider>
  );
};

export { UsageContext, UsageProvider };
