import React, { useState, useReducer, useEffect, useMemo } from 'react';
import Firebase from 'firebase/app';

import ProgramContext from './Context';
import Reducer from './Reducer';
import Form from './Form';

import { SET_STATE } from './Constants';
import { initialState } from './Model';

import Modal from 'components/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const Affiliate = ({ organization, data, index }) => {
  const [ state, dispatch ] = useReducer(Reducer, initialState());
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    data
      ? dispatch({ type: SET_STATE, value: data[index] })
      : dispatch({ type: SET_STATE, value: initialState() })
  }, [open]);

  const store = useMemo(() =>
    ({ state, dispatch }), [state]
  );

  const toggleModal = () => setOpen(!open);

  const add = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organization)
      .update({
        affiliates: Firebase.firestore.FieldValue.arrayUnion(state)
      })
      .then(() => toggleModal());

  const update = () => {
    const dataCopy = [...data];
    dataCopy[index] = state;

    Firebase
      .firestore()
      .collection('organizations')
      .doc(organization)
      .update({
        affiliates: [
          ...dataCopy,
        ]
      })
      .then(() => toggleModal());
  };

  const handleSubmit = () =>
    data
      ? update()
      : add();

  return (
    <React.Fragment>
      {data
        ? <IconButton onClick={toggleModal}>
            <EditIcon />
          </IconButton>
        : <Button
            onClick={toggleModal}
            startIcon={<AddIcon />}
            variant="contained"
            size="small"
          >
            Add
          </Button>
      }

      <Modal
        isOpen={open}
        handleClose={toggleModal}
        title={data ? 'Edit program' : 'Add program'}
      >
        <ProgramContext.Provider
          value={store}
        >
          <Form
            data={data}
            submit={handleSubmit}
            cancel={toggleModal}
          />
        </ProgramContext.Provider>
      </Modal>
    </React.Fragment>
  );
};

export default Affiliate;
