const initialState = () => {
  return [
    {
      key: 'utm_id',
      value: '',
    },
    {
      key: 'utm_source',
      value: '',
    },
    {
      key: 'utm_medium',
      value: '',
    },
    {
      key: 'utm_campaign',
      value: '',
    },
    {
      key: 'utm_term',
      value: '',
    },
    {
      key: 'utm_content',
      value: '',
    },
  ]
};

export default initialState;
