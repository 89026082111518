import { arrayMove } from "@dnd-kit/sortable";
import { item, condition } from './Model';

import {
  SET_STATE, 

  SET_FIELD,
  DELETE_FIELD,

  UPDATE_DEFAULT,
  ADD_REDIRECT,
  DELETE_REDIRECT,
  UPDATE_REDIRECT,
  MOVE_REDIRECT,

  ADD_CONDITION,
  UPDATE_CONDITION,
  DELETE_CONDITION,
} from './Constants';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE: {
      return {
        ...action.value,
      };
    };

    case SET_FIELD: {
      const { field, value } = action.value;
      const copy = { ...state };
      copy[field] = value;

      return {
        ...copy,
      };
    };

    case DELETE_FIELD: {
      const field = action.value;
      const copy = {...state}

      delete copy[field];

      return {
        ...copy,
      };
    };

    case UPDATE_DEFAULT: {
      const { field, value } = action.value;

      return {
        ...state,
        default: {
          ...state.default,
          [field]: value,
        },
      };
    };

    case ADD_REDIRECT: {
      return {
        ...state,
        items: [
          item(),
          ...state.items,
        ],
      };
    };

    case DELETE_REDIRECT: {
      const id = action.value;

      return {
        ...state,
        items: [
          ...state.items.filter((redirect) => redirect.id !== id),
        ]
      }
    };

    case UPDATE_REDIRECT: {
      const { id, field, value } = action.value;
      const stateCopy = state.items.map(item => ({...item}));
      const index = stateCopy.findIndex(redirect => redirect.id === id);

      stateCopy[index][field] = value;

      return {
        ...state,
        items: [
          ...stateCopy,
        ],
      }
    };

    case MOVE_REDIRECT: {
      const { active, over } = action.value;

      if (active.id !== over.id) {
        const oldIndex = state.items.findIndex(item => item.id === active.id);
        const newIndex = state.items.findIndex(item => item.id === over.id);
        const newOrder = arrayMove(state.items, oldIndex, newIndex);

        return {
          ...state,
          items: [
            ...newOrder
          ],
        };
      }
    };

    case ADD_CONDITION: {
      const id = action.value;
      const index = state.items.findIndex(redirect => redirect.id === id);
      const stateCopy = state.items.map(item => ({...item}));

      stateCopy[index].conditions.unshift(condition());

      return {
        ...state,
        items: [
          ...stateCopy,
        ],
      }
    };

    case UPDATE_CONDITION: {
      const { id, index, field, value } = action.value;
      const redirectIndex = state.items.findIndex(redirect => redirect.id === id);
      const stateCopy = state.items.map(item => ({...item}));

      stateCopy[redirectIndex].conditions[index][field] = value;

      return {
        ...state,
        items: [
          ...stateCopy,
        ],
      }
    };

    case DELETE_CONDITION: {
      const { id, index } = action.value;
      const redirectIndex = state.items.findIndex(redirect => redirect.id === id);
      const stateCopy = state.items.map(item => ({...item}));

      stateCopy[redirectIndex].conditions.splice(index, 1);

      return {
        ...state,
        items: [
          ...stateCopy,
        ],
      }
    };
  }
};

export default Reducer;
