import React from 'react';

import Box from '@mui/material/Box';
import Preview from '../Preview';
import Upload from './Upload';
import Edit from './Edit';
import Delete from './Delete';

const Background = ({ data, dispatch, children }) =>
  <Box
    sx={{
      position: 'relative',
      height: 250,
    }}
  >
    <Preview background={data} />

    <Upload dispatch={dispatch}>
      {children}
    </Upload>

    <Delete data={data} dispatch={dispatch} />
    <Edit dispatch={dispatch} />
  </Box>

export default Background;
