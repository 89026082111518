import React, { useState, useEffect, useContext, createContext } from "react";
import Firebase from 'firebase/app';

import { UserContext } from './user';

const OrganizationsContext = createContext();

const OrganizationsProvider = (props) => {
  const { userLoading, userId } = useContext(UserContext);

  const [state, setState] = useState({
    organizationsLoading: true,
    organizationsError: false,
    organizationsData: null,
  });

  useEffect(() => {
    if (!userLoading && userId) {

      const unsubscribe = Firebase
        .firestore()
        .collection('organizations')
        .where('status', '==', 'active')
        .where(`roles.${userId}`, 'in', ['owner'])
        .onSnapshot((querySnapshot) => {
          const organizations = [];

          querySnapshot.forEach((doc) => organizations.push({
            id: doc.id,
            ...doc.data(),
          }));

          setState(prevState => ({
            ...prevState,
            organizationsLoading: false,
            organizationsError: false,
            organizationsData: organizations,
          }));
        });

      return () => unsubscribe();

    } else if (!userLoading && !userId) {
      setState({
        organizationsLoading: false,
        organizationsError: false,
        organizationsData: null,
      });
    }
  }, [userLoading, userId]);

  return (
    <OrganizationsContext.Provider value={state}>
      {props.children}
    </OrganizationsContext.Provider>
  );
};

export { OrganizationsContext, OrganizationsProvider };
