import React, { useState, useContext, useEffect } from 'react';
import Firebase from 'firebase/app';
import { HeaderContext } from 'stores/header';

// Hooks
import { useAuthState } from 'react-firebase-hooks/auth';

// Components
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Delete = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
	const [user] = useAuthState(Firebase.auth());
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => setHeader({ title: 'Delete account' }), []);

  const deleteAccount = () =>
    user
      .delete()
      .then(() => Firebase.auth().signOut())
      .catch((err) => {
        setErrorMsg(err.message);
        setErrorOpen(true);
      });

  const handleError = () => setErrorOpen(false);

  return (
    <>
      <Typography variant="body1">
        If you are here, we hope you know what you're doing. In this section you can permanently delete your account, and you will loose access to:
      </Typography>

      <List>
        <ListItem>
          - All organizations
        </ListItem>
        <ListItem>
          - All links
        </ListItem>
        <ListItem>
          - All related analytics
        </ListItem>
      </List>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => setConfirmOpen(true)}>
          Delete Account
      </Button>

      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
        <Alert onClose={handleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <ConfirmDialog
        title={`Delete ${user.displayName ? user.displayName : 'account'}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteAccount}
      >
        This operation is irreversible, are you sure you want to delete your account?
      </ConfirmDialog>
    </>
  );
};

export default Delete;
