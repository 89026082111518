import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom'

// Components
import StatsRange from 'components/StatsRange';
import ClickStatsCard from 'components/cards/ClickStatsCard';
import CountryStats from 'components/cards/CountryStats';
import SourceStats from 'components/cards/SourceStats';

// UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';

const GroupAnalyticsPage = () => {
  const { group } = useOutletContext();
  const [ from, setFrom ] = useState(null);
  const [ to, setTo ]     = useState(null);

  return (
    <Box sx={{ padding: 2 }}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="flex-end">
          <StatsRange
            from={setFrom}
            to={setTo}
            createdOn={group.data().createdOn}
          />
        </Stack>

        <Card variant="outlined" sx={{ bgcolor: grey[50] }}>
          <CardHeader title="Clicks" />

          <CardContent>
            <ClickStatsCard
              column="sections"
              id={group.id}
              from={from}
              to={to}
            />
          </CardContent>
        </Card>

        <CountryStats
          column="sections"
          id={group.id}
          from={from}
          to={to}
        />

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
        >
          <SourceStats
            title="referers"
            column="sections"
            field="referer"
            id={group.id}
            from={from}
            to={to}
          />

          <SourceStats
            title="browsers"
            column="sections"
            field="browser"
            id={group.id}
            from={from}
            to={to}
          />
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
        >
          <SourceStats
            title="device types"
            column="sections"
            field="device"
            id={group.id}
            from={from}
            to={to}
          />

          <SourceStats
            title="operating systems"
            column="sections"
            field="os"
            id={group.id}
            from={from}
            to={to}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default GroupAnalyticsPage;
