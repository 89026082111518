import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ConditionType = React.memo(({ value, onChange }) => {
  return (
    <Select
      name="type"
      value={value}
      onChange={onChange}
      variant="standard"
      fullWidth
      required
    >
      <MenuItem value="continent">Continent</MenuItem>
      <MenuItem value="country">Country</MenuItem>
      <MenuItem value="region">Region</MenuItem>
      <MenuItem value="geoarea">Geo Area</MenuItem>
      <MenuItem value="language">Language</MenuItem>
      <MenuItem value="os">Operating Sytem</MenuItem>
      <MenuItem value="browser">Browser</MenuItem>
      <MenuItem value="browserengine">Browser Engine</MenuItem>
      <MenuItem value="devicetype">Device Type</MenuItem>
      <MenuItem value="datetime">Date / Time</MenuItem>
      <MenuItem value="timezone">Time Zone</MenuItem>
      <MenuItem value="referer">Referer</MenuItem>
      <MenuItem value="ipaddress">IP Address</MenuItem>
    </Select>
  );
});

export default ConditionType;
