import React from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Link from 'components/Link';
import Weight from './Weight';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import {
  UPDATE_DESTINATION,
  DELETE_DESTINATION,
} from '../Constants';

const Destination = ({ id, data, dispatch, editing }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dispatchAction = (field, value) =>
    dispatch({
      type: UPDATE_DESTINATION,
      value: {
        id,
        name: field,
        value: value
      }
    });

  const handleLinkChange = (link) => {
    dispatchAction('url', link.url);
    dispatchAction('type', link.type);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      ref={setNodeRef}
      style={style}
    >
      {editing &&
        <IconButton {...attributes} {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      }

      <Stack
        component={Paper}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 5, sm: 2 }}
        alignItems="flex-end"
        sx={{ flex: 1, padding: (theme) => theme.spacing(2) }}
      >
        <Link
          type={data.type}
          url={data.url}
          save={handleLinkChange}
        />

        <Weight
          id={id}
          value={data.weight}
          dispatch={dispatch}
        />
      </Stack>

      {editing &&
        <IconButton
          onClick={() =>
            dispatch({
              type: DELETE_DESTINATION,
              value: id,
            })
          }
        >
          <DeleteIcon />
        </IconButton>
      }
    </Stack>
  );
};

export default Destination;
