import React from 'react';
import { UPDATE_DESTINATION } from '../Constants';
import Slider from '@mui/material/Slider';

const areProsEqual = (prev, next) =>
  prev.id === next.id && prev.value === next.value;

const Weight = React.memo(({ id, value, dispatch }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch({
      type: UPDATE_DESTINATION,
      value: {
        id,
        name,
        value
      }
    });
  };

  return (
    <Slider
      name="weight"
      value={value}
      onChange={handleChange}
      aria-label="Weight"
      valueLabelDisplay="on"
      valueLabelFormat={(val) => `${val}%`}
      sx={{
        width: {
          xs: '100%',
          sm: '30%',
        },
      }}
    />
  );
}, areProsEqual);

export default Weight;
