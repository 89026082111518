import { param } from './Model';

import {
  SET_STATE,

  EDIT_TITLE,
  EDIT_HOSTNAME,

  ADD_PARAM,
  EDIT_PARAM,
  DELETE_PARAM,
} from './Constants';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return {
        ...action.value,
      };

    case EDIT_TITLE:
      return {
        ...state,
        title: action.value,
      };

    case EDIT_HOSTNAME:
      return {
        ...state,
        hostname: action.value,
      };

    case ADD_PARAM:
      return {
        ...state,
        params: [
          ...state.params,
          param(),
        ],
      };

    case EDIT_PARAM: {
      const { index, field, value } = action.value;

      const stateCopy = { ...state };
      stateCopy.params[index][field] = value;

      return {
        ...stateCopy,
      }
    };

    case DELETE_PARAM:
      return {
        ...state,
        params: [
          ...state.params.filter((doc, index) => index !== action.value),
        ]
      }

    default:
      return state;
  }
};

export default Reducer;
