import React, { useState, useEffect } from 'react';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Destination from './Destination';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReorderIcon from '@mui/icons-material/Reorder';
import DoneIcon from '@mui/icons-material/Done';

import {
  ADD_DESTINATION,
  MOVE_DESTINATION,
} from '../Constants';

const Destinations = ({ data, dispatch }) => {
  const [ editing, setEditing ] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    if (data.length === 1)
      setEditing(false);
  }, [data.length]);

  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h6" component="p">
          Destinations:
        </Typography>

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          {data.length > 1 &&
            <Button
              variant="contained"
              color="secondary"
              startIcon={editing ? <DoneIcon /> : <ReorderIcon />}
              size="small"
              onClick={() => setEditing(!editing)}
            >
              {editing ? 'Done' : 'Edit'}
            </Button>
          }

          <Button
            variant="contained"
            color="primary"
            aria-controls="add-choice"
            aria-haspopup="true"
            startIcon={<AddBoxIcon />}
            size="small"
            onClick={() =>
              dispatch({ type: ADD_DESTINATION })
            }
          >
            Add
          </Button>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(e) =>
            dispatch({ type: MOVE_DESTINATION, value: e })
          }
        >
          <SortableContext
            items={data}
            strategy={verticalListSortingStrategy}
          >
            {data.map((destination) =>
              <Destination
                key={destination.id}
                id={destination.id}
                data={destination}
                dispatch={dispatch}
                editing={editing}
              />
            )}
          </SortableContext>
        </DndContext>
      </Stack>

    </Stack>
  );
};

export default Destinations;
