import React, { useState } from 'react';
import styled from "styled-components";

const FullScreen = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  border-radius: 10px;
  overflow: hidden;
`;

const GradientPreview = styled.div`
  position: absolute;
  background: linear-gradient(
    20deg,
    hsl(${props => props.hue}, 60%, 65%),
    hsl(${props => props.hue - 305}, 64%, 60%)
  );
  height: 100%;
  width: 100%;
`;

const Centered = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const RangeInput = styled.input.attrs({
  type: "range"
})``;

const Gradient = ({ onChange }) => {
  const [hue, setHue] = useState(340);

  const handleScroll = (e) => {
    const { value } = e.target;
    setHue(value)

    onChange({
      type: 'gradient',
      value: `
        linear-gradient(
          20deg,
          hsl(${value}, 60%, 65%),
          hsl(${value - 305}, 64%, 60%)
        )
      `
    });
  };

  return (
     <FullScreen>
        <GradientPreview hue={hue} />

        <Centered>
          <RangeInput
            value={hue}
            onChange={handleScroll}
            min="0"
            max="360"
          />
        </Centered>
      </FullScreen>       
  );
};

export default Gradient;
