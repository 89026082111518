import React, { useState, useContext } from 'react';
import Firebase from 'firebase/app';

// Data
import { OrganizationContext } from 'stores/organization';
import initialState from './Model';

// Components
import UTMParams from 'components/UTMParams';

// UI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const UTMForm = React.memo(({ id, data, submit, cancel }) => {
  const { organizationId } = useContext(OrganizationContext);
  const [ state, setState ] = useState({
    ...initialState(),
    ...data,
  });
  const [ loading, setLoading ] = useState(false);

  const add = () =>
    Firebase
      .firestore()
      .collection('utms')
      .add({
        ...state,
        organization: organizationId,
      })
      .then((res) => submit(res));

  const update = () =>
    Firebase
      .firestore()
      .collection('utms')
      .doc(id)
      .set(state)
      .then(() => submit());

  const handleTitleChange = (e) =>
    setState(prevState => ({
      ...prevState,
      title: e.target.value,
    }));

  const handleParamsChange = (params) =>
    setState(prevState => ({
      ...prevState,
      params: [
        ...params,
      ],
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  
    setLoading(true);

    id
      ? update()
      : add();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          id="title"
          name="title"
          label="Title"
          placeholder="UTM Title"
          value={state.title}
          onChange={handleTitleChange}
          variant="standard"
          required
        /> 

        <UTMParams
          id="custom"
          data={state.params}
          change={handleParamsChange}
        />

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button
            onClick={cancel}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={loading && <CircularProgress size={16} sx={{ color: '#fff' }} />}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
});

export default UTMForm;
