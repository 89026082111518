import React from 'react';

import DeviceTypeList from './DeviceTypeList';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

const DeviceType = ({ target, save }) => {

  const handleChange = (e, selected) => {
    const codes = selected.reduce((acc, el) => {
      acc.push(el.value ? el.value : el);

      return acc;
    }, []);

    save({
      values: [
        ...codes
      ]
    });
  };

  return (
    <Autocomplete
      multiple
      limitTags={3}
      autoHighlight
      filterSelectedOptions
      disableClearable={true}
      value={target.values ? target.values : []}
      onChange={handleChange}
      options={DeviceTypeList}
      isOptionEqualToValue={(option, value) => option.value === value}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      renderTags={(tags, props) =>
        tags.map((tag, index) => {
          const deviceTypeIndex = DeviceTypeList.findIndex(device => device.value === tag);

          return (
            <Chip
              {...props({ index })}
              label={DeviceTypeList[deviceTypeIndex].label}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            required: target.values ? target.values.length === 0 : true
          }}
          required
        />
      )}
    />
  );
};

export default DeviceType;
