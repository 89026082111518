import React from 'react';

import { SET_FIELD } from '../Constants';

import TextField from '@mui/material/TextField';

const arePropsEqual = (prev, next) =>
  prev.data === next.data;

const Description = React.memo(({ data, dispatch }) => {

  const handleChange = (e) =>
    dispatch({
      type: SET_FIELD,
      value: {
        field: 'description',
        value: e.target.value,
      },
    });

  return (
    <TextField
      label="Description"
      name="description"
      value={data}
      onChange={handleChange}
      variant="standard"
      fullWidth
    />
  );
}, arePropsEqual);

export default Description;
