const SET_STATE = 'SET_STATE';

const EDIT_TITLE = 'EDIT_TITLE';
const EDIT_HOSTNAME = 'EDIT_HOSTNAME';

const ADD_PARAM = 'ADD_PARAM';
const EDIT_PARAM = 'EDIT_PARAM';
const DELETE_PARAM = 'DELETE_PARAM';

export {
  SET_STATE,

  EDIT_TITLE,
  EDIT_HOSTNAME,

  ADD_PARAM,
  EDIT_PARAM,
  DELETE_PARAM,
};
