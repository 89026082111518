const SET_STATE = 'SET_STATE';

const SET_FIELD = 'SET_FIELD';
const DELETE_FIELD = 'DELETE_FIELD';

export {
  SET_STATE,

  SET_FIELD,
  DELETE_FIELD,
};
