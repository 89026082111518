import react, { useState } from 'react';
import Firebase from 'firebase/app'

// Components
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const RemoveMember = ({ organizationId, userId }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const removeMember = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .set({
        roles: {
          [userId]: Firebase.firestore.FieldValue.delete()
        }
      }, { merge: true });

  return (
    <>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => setConfirmOpen(true)}
      >
        <DeleteIcon />
      </IconButton>

      <ConfirmDialog
        title="Remove user"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={removeMember}
      >
       Are you sure you want to remove this user? 
      </ConfirmDialog>
    </>
  );
};

export default RemoveMember;
