import React, { useState, createContext, useEffect } from "react";

const AlertsContext = createContext();

const AlertsProvider = ({ children }) => {
  const [state, setState] = useState({
    alertsData: [],
  });

  const removeAlert = (alertId) => {
    if (state.alertsData.length)
      setState(prevState => ({
        ...prevState,
        alertsData: prevState.alertsData.filter(doc => doc.id !== alertId),
      }));
    };

  const setAlert = (newAlert) => {
    const isPresent = state.alertsData.find(o => o.id === newAlert.id);

    if (!isPresent)
      setState(prevState => ({
        ...prevState,
        alertsData: [
          ...prevState.alertsData,
          newAlert,
        ],
      }));
  };

  useEffect(() => {
    setState({
      alertsData: [],
    });
  }, []);

  return (
    <AlertsContext.Provider value={{ ...state, setAlert, removeAlert }}>
      {children}
    </AlertsContext.Provider>
  );
};

export { AlertsContext, AlertsProvider };
