import React, { useState, useEffect, useContext, createContext } from "react";
import Firebase from 'firebase/app';
import { useParams, useNavigate } from 'react-router-dom'
import { UserContext } from './user';
import LinearProgress from '@mui/material/LinearProgress';

const OrganizationContext = createContext();

let unsubscribe;

const OrganizationProvider = ({ children }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { userLoading, userId, userData } = useContext(UserContext);
  const [state, setState] = useState({
    organizationLoading: true,
    organizationError: false,
    organizationId: null,
    organizationData: null,
  });

  // On mount get organization data
  useEffect(async () => {
    if (!userLoading && userData) {
      unsubscribe = Firebase
        .firestore()
        .collection('organizations')
        .where('slug', '==', slug)
        .where('status', '==', 'active')
        .where(`roles.${userId}`, 'in', ['owner', 'admin', 'editor', 'reader'])
        .onSnapshot(async (querySnapshot) => {
          const organization = querySnapshot.docs[0];

          if (!organization) {
            navigate('/');
          } else {
            if (state.organizationLoading) {
              setState({
                organizationLoading: false,
                organizationError: false,
                organizationId: organization.id,
                organizationData: organization.data(),
              })
            } else {
              setState(prevState => ({
                ...prevState,
                organizationData: organization.data(),
              }))
            };
          }
        });
    }
  }, [userLoading, userData]);

  // Clean up on unmount 
  useEffect(() => {
    return () => {
      unsubscribe();

      setState({
        organizationLoading: true,
        organizationError: false,
        organizationId: null,
        organizationData: null,
      });
    }
  }, []);

  if (state.organizationLoading) return <LinearProgress />

  return (
    <OrganizationContext.Provider value={{ ...state }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export { OrganizationProvider, OrganizationContext };
