import React, { useState, useContext, useEffect } from 'react';

// Data
import { UTMContext } from 'stores/utms';
import Fields from './Fields';
import initialState from './Model';

// UI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const UTMParams = ({ id, data, change }) => {
  const { utmsData } = useContext(UTMContext);
  const [ params, setParams ] = useState(initialState());

  useEffect(() => {
    if (id !== 'custom') {
      const index = utmsData.findIndex((utm) => utm.id === id);

      if (index !== -1) setParams(utmsData[index].data().params);
    } else {
      data.length
        ? setParams(data)
        : setParams(initialState());
    };
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const copy = [
      ...params.map((param) => param.key === name
        ? {
            key: name,
            value: value,
          }
        : param
      )
    ];

    setParams(copy);
    change(copy);
  };

  return (
    <Stack spacing={2}>
      {Fields.map((field) => {
        const item = params.find((param) =>
          field.id === param.key
        )

        return (
          <TextField
            key={field.id}
            id={field.id}
            name={field.name}
            label={field.title}
            placeholder={field.placeholder}
            value={item.value}
            onChange={handleChange}
            variant="standard"
            required={field.required}
            disabled={id !== 'custom'}
          /> 
        )
      })}
    </Stack>
  );
};

export default UTMParams;
