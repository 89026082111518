import React, {
  useState,
  useEffect,
  useContext,
  createContext
} from "react";
import Firebase from 'firebase/app';
import { OrganizationContext } from './organization';

const GroupsContext = createContext();

const GroupsProvider = ({ children }) => {
  const { organizationLoading, organizationId } = useContext(OrganizationContext);

  const [state, setState] = useState({
    groupsLoading: true,
    groupsError: false,
    groupsData: [],
  });

  useEffect(() => {
    if (!organizationLoading && organizationId) {

      const unsubscribe = Firebase
        .firestore()
        .collection('groups')
        .where("organization", "==", organizationId)
        .onSnapshot((querySnapshot) => {
          const groups = [];

          if (!querySnapshot.empty)
            querySnapshot.forEach((doc) => groups.push(doc));

          setState(prevState => ({
            ...prevState,
            groupsLoading: false,
            groupsData: groups,
          }));
        });

      return () => unsubscribe();

    } else if (!userLoading && !userId) {
      setState({
        groupsLoading: false,
        groupsError: false,
        groupsData: [],
      });
    }
  }, [organizationLoading, organizationId]);

  return (
    <GroupsContext.Provider value={state}>
      {children}
    </GroupsContext.Provider>
  );
};

export { GroupsContext, GroupsProvider };
