import React, {
  useState,
  useEffect,
  useContext,
  createContext
} from "react";
import Firebase from 'firebase/app';
import { OrganizationContext } from './organization';

const UTMContext = createContext();

const UTMProvider = ({ children }) => {
  const { organizationLoading, organizationId } = useContext(OrganizationContext);

  const [state, setState] = useState({
    utmsLoading: true,
    utmsError: false,
    utmsData: [],
  });

  useEffect(() => {
    if (!organizationLoading && organizationId) {

      const unsubscribe = Firebase
        .firestore()
        .collection('utms')
        .where('organization', '==', organizationId)
        .onSnapshot((querySnapshot) => {
          const utms = [];

          if (!querySnapshot.empty)
            querySnapshot.forEach((doc) => utms.push(doc));

          setState(prevState => ({
            ...prevState,
            utmsLoading: false,
            utmsData: utms,
          }));
        });

      return () => unsubscribe();

    } else if (!userLoading && !userId) {
      setState({
        utmsLoading: false,
        utmsError: false,
        utmsData: [],
      });
    }
  }, [organizationLoading, organizationId]);

  return (
    <UTMContext.Provider value={state}>
      {children}
    </UTMContext.Provider>
  );
};

export { UTMContext, UTMProvider };
