import React from 'react';
import Firebase from 'firebase/app'
import { useDocument } from 'react-firebase-hooks/firestore';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const Member = ({ currentUser, id }) => {
  const [user, loading, error] = useDocument(
    Firebase.firestore()
      .collection('users')
      .doc(id),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  return (
    <React.Fragment>
      {loading && <CircularProgress size={20} />}

      {!loading && !error && user.data() &&
        <Typography noWrap variant="body1">
          {user.data().email}
          {currentUser === id && ' (You)'}
        </Typography>
      }
    </React.Fragment>
  );
};

export default Member;
