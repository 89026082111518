import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone'

import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';

import { EDIT_BACKGROUND } from '../../../Constants';

// TODO: Add activeStyle on hover also
const baseStyle = {
  position: 'relative',
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'solid',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderStyle: 'dashed',
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const Upload = React.memo(({ dispatch, children }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    maxSize: 5000000,
    onDrop: acceptedFile => {
      dispatch({
        type: EDIT_BACKGROUND,
        value: {
          type: 'image',
          source: 'upload',
          upload: acceptedFile[0],
          file: Math.random().toString(20).substr(2, 16),
          urls: {
            thumb: '',
            small: '',
            regular: '',
            large: '',
            full: '',
          },
        }
      });
    }
  });

  /*
  useEffect(() => () => {
    image => URL.revokeObjectURL(image);
  }, [image]);
  */

	const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div {...getRootProps({style})}>
      {children}

      <input {...getInputProps()} />

      <IconButton
        sx={{
          position: 'absolute',
          bottom: '8px',
          right: '10px',
          padding: '5px',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        size="small"  
        aria-label="edit"
        onClick={open}
      >
        <PublishIcon fontSize="small" />
      </IconButton>

      {isDragActive && <p>Drag and drop background image here, or click to select</p>}

      {isDragReject && <p>Can't load, the file is probably too big or not in a jpeg format.</p>}
    </div>
  )
});

export default Upload;
