import React, { useState, useEffect, useContext, createContext } from "react";
import { getUnixTime, fromUnixTime, addDays, differenceInDays } from 'date-fns';
import Firebase from 'firebase/app';

import { AlertsContext } from './alerts';
import { UserContext } from './user';

const SubscriptionContext = createContext();

const getPlanLimits = async (user) => {
  const userPlan = user ? user.data().role : false;

  if (userPlan) {
    const plan = await Firebase
      .firestore()
      .collection('products')
      .where('role', '==', userPlan)
      .where('active', '==', true)
      .get();

    const planData = plan.docs[0].data();

    return {
      name: planData.name,
      description: planData.description,
      clicks: planData.stripe_metadata_clicks,
      organizations: planData.stripe_metadata_organizations,
      members: planData.stripe_metadata_members,
    }
  } else {
    /* Solo trial plan */
    return {
      name: 'Trial',
      description: 'You are on a trial plan',
      clicks: 50000,
      organizations: 3,
      members: 3,
    }
  };
}

const getStripeData = (userData, userPlan) => {
  const plan = userPlan ? userPlan.data() : false;

  if (!plan) {
    const today = new Date();
    const periodStart = fromUnixTime(userData.createdOn.seconds);
    const periodEnd = addDays(periodStart, 8);
    const trialDaysRemaining = differenceInDays(periodEnd, today);

    if (trialDaysRemaining > 0) {
      return {
        role: 'trial',
        status: 'trialing',
        current_period_start: {
          seconds: userData.createdOn.seconds,
        },
        current_period_end: {
          seconds: getUnixTime(periodEnd),
        },
        trial_end: trialDaysRemaining,
      }
    } else {
      return {
        role: 'ghost',
        status: 'subscribe',
      }
    }
  }

  // Stripe plan
  return plan;
};

const SubscriptionProvider = (props) => {
  const { setAlert, removeAlert } = useContext(AlertsContext);
  const { userLoading, userId, userData } = useContext(UserContext);

  const [state, setState] = useState({
    subscriptionLoading: true,
    subscriptionError: false,
    subscriptionData: {},
    subscriptionLimits: {},
  });

  useEffect(() => {
    if (!userLoading && userData) {
      const unsubscribe = Firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('subscriptions')
        .where('status', 'in', ['active', 'incomplete'])
        .onSnapshot(async (querySnapshot) => {
          const plan = querySnapshot.docs[0];
          const planData = getStripeData(userData, plan);
          const planLimits = await getPlanLimits(plan);

          planData.status === 'subscribe'
            ? setAlert({
                id: 'needs-subscription',
                message: 'Please subscribe to continue using Lynky.'
              })
            : removeAlert('needs-subscription');

          planData.status === 'trialing'
            ? setAlert({
                id: 'trialing',
                message: `You are currently on a trial plan, which will end in ${planData.trial_end} days.`
              })
            : removeAlert('trialing');

          setState(prevState => ({
            ...prevState,
            subscriptionLoading: false,
            subscriptionError: false,
            subscriptionData: planData,
            subscriptionLimits: planLimits,
          }));
        });

      return () => unsubscribe();

    } else if (!userLoading && !userData) {
      setState({
        subscriptionLoading: false,
        subscriptionError: false,
        subscriptionData: {},
        subscriptionLimits: {},
      });
    }
  }, [userLoading, userData]);

  return (
    <SubscriptionContext.Provider value={state}>
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionProvider };
