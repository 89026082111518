import React, { useRef, useState, useEffect } from 'react';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import L from 'leaflet';

// Styles
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const generateLeafletGeoJSON = (coords) =>
  new L.GeoJSON({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              ...coords,
            ]
          ]
        }
      }
    ]
  });

const formatCoords = (array) =>
  array.reduce((acc, coord) => {
    acc.push([coord.lng, coord.lat]);

    return acc;
  }, []);

const GeoArea = ({ target, save }) => {
  const [ map, setMap ] = useState(null);
  const [ center, setCenter ] = useState([48.063397, 9.954250]);
  const [ area, setArea ] = useState(target.values ? target.values : []);

  const featureGroupRef = useRef();
  const stateRef = useRef();

  stateRef.current = area;

  // On area change
  useEffect(() => {
    // Filter out prototype methods
    const newArea = area.reduce((acc, point) => {
      acc.push({
        lat: point.lat,
        lng: point.lng
      });

      return acc;
    }, []);

    save({
      values: newArea,
    });
  }, [area]);

  const setAreaFromState = () => {
    const coords = formatCoords(stateRef.current);
    const leafletGeoJSON = generateLeafletGeoJSON(coords);

    leafletGeoJSON.eachLayer(layer => layer.addTo(featureGroupRef.current));
  };

  const onMounted = () => {
    if (area) setAreaFromState();
  };

  // Start
  const onDrawStart = () => featureGroupRef.current.clearLayers();

  // Created
  const onCreated = (e) => {
    const coordinates = e.layer.getLatLngs();

    setArea(coordinates[0]);
  };

  // Stop
  const onDrawStop = () => {
    const collection = featureGroupRef.current.toGeoJSON();

    // If zero, set from state
    if (!collection.features.length) setAreaFromState();
  }

  // Edited
  const onEdited = (e) => {
    if (e.layers)
      e.layers.eachLayer((layer) => {
        const coords = layer.getLatLngs();
        setArea(coords[0]);
      });
  };

  return (
    <MapContainer
      center={!area.length ? center : null}
      bounds={area.length ? area : null}
      zoom={4}
      scrollWheelZoom={false}
      whenCreated={setMap}
      style={{ width: '100%', height: 300 }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <FeatureGroup
        ref={featureGroupRef}
      >
        <EditControl
          position="topright"
          onMounted={onMounted}
          onDrawStart={onDrawStart}
          onDrawStop={onDrawStop}
          onEdited={onEdited}
          onCreated={onCreated}
          edit={{
            edit: false,
            remove: false,
          }}
          draw={{
            polygon: true,
            polyline: false,
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
    </MapContainer> 
  );
};

export default GeoArea;
