const ContinentList = [
  {
    label: 'Africa',
    code: 'AF'
  },
  {
    label: 'Antartica',
    code: 'AN'
  },
  {
    label: 'Asia',
    code: 'AS'
  },
  {
    label: 'Europe',
    code: 'EU'
  },
  {
    label: 'North America',
    code: 'NA'
  },
  {
    label: 'South America',
    code: 'SA'
  },
  {
    label: 'Oceania',
    code: 'OC'
  },
];

export default ContinentList;
