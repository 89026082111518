import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

const getLink = (link) => {
  const nodeEnv = process.env.NODE_ENV;
  const appEnv = process.env.REACT_APP_ENV;

  if (nodeEnv === 'production') {
    switch (appEnv) {
      case 'live':
        return `https://lynky.to/${link}`;
      case 'stage':
        return `https://stage.lynky.to/${link}`;
      case 'dev':
        return `https://dev.lynky.to/${link}`;
    }
  }

  if (nodeEnv === 'development') return `http://localhost:8080/${link}`;
};

const CopyLinkToClipboard = ({ link }) => {
  const [open, setOpen] = useState(false);
  const linkTo = getLink(link);

  const showSnackbar = () => setOpen(true);
  const hideSnackbar = () => setOpen(false);

  return (
    <React.Fragment>
      <CopyToClipboard text={linkTo} onCopy={showSnackbar}>
        <Button
          size="small"
          sx={{ paddingTop: 0, paddingBottom: 0 }}
          aria-label="copy link"
          endIcon={<FileCopyIcon fontSize="small" />}
        >
          {link}
        </Button>
      </CopyToClipboard>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={hideSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={hideSnackbar}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ width: '100%' }}
        >
          Link copied to your clipboard
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CopyLinkToClipboard;
