import React, { useEffect, useState } from 'react';

import CountryRegionData from 'country-region-data/dist/data-umd';

import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

const propsAreEqual = (prev, next) =>
  prev.target.country === next.target.country &&
  prev.target.values === next.target.values;

const Region = React.memo(({ target, save }) => {
  const [ regionOptions, setRegionOptions ] = useState([]);

  useEffect(() => {
    const countryIndex = CountryRegionData.findIndex(country => country.countryShortCode === (target.country ? target.country : 'US'));

    setRegionOptions(CountryRegionData[countryIndex].regions);
  }, [target.country]);

  const handleCountryChange = (e, selected) => {
    const index = selected.countryShortCode;
    const countryIndex = CountryRegionData.findIndex(country => country.countryShortCode === index);

    setRegionOptions(CountryRegionData[countryIndex].regions);

    save({
      country: selected.countryShortCode,
      values: [],
    });
  };

  const handleRegionChange = (e, selected) => {
    const codes = selected.reduce((acc, el) => {
      acc.push(el.shortCode ? el.shortCode : el);

      return acc;
    }, []);

    save({
      country: target.country,
      values: [
        ...codes,
      ]
    });
  };

  return (
    <Stack spacing={2}>

      <Autocomplete
        disableClearable={true}
        value={target.country ? target.country : 'US'}
        onChange={handleCountryChange}
        options={CountryRegionData}
        isOptionEqualToValue={(option, value) => option.countryShortCode === value}
        getOptionLabel={(option) => {
          if (option.countryName) return option.countryName;

          const index = CountryRegionData.findIndex(country => country.countryShortCode === option);
          return CountryRegionData[index].countryName;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
          />
        )}
        required
      />

      {regionOptions.length &&
        <Autocomplete
          multiple
          limitTags={3}
          filterSelectedOptions
          disableClearable={true}
          value={target.values ? target.values : []}
          onChange={handleRegionChange}
          options={regionOptions}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.shortCode === value}
          renderTags={(tags, props) =>
            tags.map((tag, index) => {
              const regionIndex = regionOptions.findIndex(region => region.shortCode === tag);

              return (
                <Chip
                  {...props({ index })}
                  label={regionOptions[regionIndex].name}
                  size="small"
                />
              );
            })
          }
          renderInput={(params) =>
            <TextField
              {...params}
              variant="standard"
              inputProps={{
                ...params.inputProps,
                required: target.values ? target.values.length === 0 : true
              }}
              required
            />
          }
        />
      }

    </Stack>
  );
}, propsAreEqual);

export default Region;
