import React, { useState, useContext, useEffect } from 'react';
import { Outlet } from "react-router";
import { Link, useMatch } from 'react-router-dom';
import { HeaderContext } from 'stores/header';

// UI
import ResponsiveMenu from 'components/ResponsiveMenu';
import HeaderBar from 'components/HeaderBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';

const Menu = 
  <List>
    <ListItem>
      <Link to='profile'>
        Profile
      </Link>
    </ListItem>

    <ListItem>
      <Link to='subscription'>
        Subscription
      </Link>
    </ListItem>

    <ListItem>
      <Link to='usage'>
        Usage
      </Link>
    </ListItem>

    <ListItem>
      <Link to='delete'>
        Delete account
      </Link>
    </ListItem>
  </List>

const AccountSettings = () => {
  const { title, setHeader } = useContext(HeaderContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => setMenuOpen(!menuOpen);
  const isIndex = useMatch('account');

  useEffect(() => setHeader({ title: 'Account' }), []);

  return (
    <React.Fragment>
      <ResponsiveMenu
        open={menuOpen}
        handleClose={handleMenuToggle}
      >
        <Toolbar>
          <Button
            component={Link}
            to="/"
            color="inherit"
            aria-label="navigate back"
            startIcon={<HomeIcon />}
          >
            Home
          </Button>
        </Toolbar>

        <Divider />

        {Menu}
      </ResponsiveMenu>

      <Container
        maxWidth="none"
        disableGutters
      >
        <HeaderBar title={title}>
          {!isIndex ?
            <IconButton
              component={ Link }
              to="/account"
              edge="start"
              color="inherit"
              aria-label="go back"
              sx={{
                display: {
                  md: 'none',
                }
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          : <IconButton
              component={ Link }
              to="/"
              edge="start"
              color="inherit"
              aria-label="go home"
              sx={{
                display: {
                  md: 'none',
                }
              }}
            >
              <HomeIcon />
            </IconButton>
          }
        </HeaderBar>

        <Container
          maxWidth="sm"
          sx={{ marginTop: (theme) => theme.spacing(4) }}
        >
          {isIndex
            ? <Box sx={{ display: { md: 'none' } }}>
                {Menu}
              </Box>
            : <Outlet />
          }
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default AccountSettings;
