import React from 'react';

import OperatingSystemList from './OperatingSystemList';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const OperatingSystem = ({ target, save }) => {

  const handleChange = (e, selected) => {
    const codes = selected.reduce((acc, el) => {
      acc.push(el);

      return acc;
    }, []);

    save({
      values: [
        ...codes
      ]
    });
  };

  return (
    <Autocomplete
      multiple
      limitTags={3}
      autoHighlight
      filterSelectedOptions
      disableClearable={true}
      value={target.values ? target.values : []}
      onChange={handleChange}
      options={OperatingSystemList}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            required: target.values ? target.values.length === 0 : true
          }}
          required
        />
      )}
    />
  );
};

export default OperatingSystem;
