import React, { useState, useRef, useEffect, useContext, createContext } from "react";
import Firebase from 'firebase/app';
import { OrganizationContext } from './organization';

const LinksContext = createContext();

const pagination = 10;

const LinksProvider = ({ children }) => {
  const { organizationLoading, organizationId } = useContext(OrganizationContext);

  const [ linksLoading, setLinksLoading ] = useState(false);
  const [ linksError, setLinksError ] = useState(false);
  const [ linksHasMore, setLinksHasMore ] = useState(true);
  const [ linksList, setLinksList ] = useState([]);

  const linksListRef = useRef([]);
  const listeners = useRef([]);

  const pushLink = (link) => {
    if (!linksListRef.current.some(doc => doc.id === link.id))
      setLinksList(prevState => ([
        ...prevState,
        link,
      ]));
  };

  const modifyLink = (link) => {
    setLinksList(prevState => ([
      ...prevState.map((doc) =>
        doc.id === link.id
          ? link
          : doc),
    ]));
  };

  const removeLink = (link) => {
    setLinksList(prevState => ([
      ...prevState.filter(doc => doc.id !== link.id),
    ]));
  }

  const loadMoreLinks = () => {
    if (linksHasMore) {
      createListener();
    }
  };

  const createListener = () => {
    const lastItem = linksListRef.current.length
      ? linksListRef.current[linksListRef.current.length - 1]
      : '';

    const listener = Firebase
      .firestore()
      .collection('links')
      .where('organization', '==', organizationId)
      .orderBy('createdOn', 'desc')
      .startAfter(lastItem)
      .limit(pagination)
      .onSnapshot((snapshot) => {
        // setLinksLoading(true);

        if (!snapshot.size) setLinksHasMore(false);

        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") pushLink(change.doc);
          if (change.type === "modified") modifyLink(change.doc);
          if (change.type === "removed") removeLink(change.doc);
        });

        // setLinksLoading(false);
      });
    
    listeners.current = listeners.current.concat([listener]);
  };

  // Observe links changes
  useEffect(() => {
    // Set ref
    linksListRef.current = linksList

    // Order list
    setLinksList((prevState) => ([
      ...prevState.sort((a, b) => (a.data().createdOn < b.data().createdOn) ? 1 : -1) 
    ]));
  }, [linksList.length]);

  // On load get first links
  useEffect(() => {
    if (!organizationLoading && organizationId)
      createListener();
  }, [organizationLoading, organizationId]);

  // Clean up on unmount 
  useEffect(() => {
    return () => {
      listeners.current.map(unsubscribe => unsubscribe())

      setLinksList([]);
    }
  }, []);

  return (
    <LinksContext.Provider value={{ linksLoading, linksError, linksHasMore, linksList, loadMoreLinks }}>
      {children}
    </LinksContext.Provider>
  );
};

export { LinksProvider, LinksContext };
