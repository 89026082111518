import React, { useContext, useEffect } from 'react';

import { SubscriptionContext } from 'stores/subscription';
import { UsageContext } from 'stores/usage';
import { HeaderContext } from 'stores/header';

import { isActive } from 'utilities/user';

import { fromUnixTime, format } from 'date-fns';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const calcUsage = (value, max) => {
  if (value > max) return 100;

  return (value/max) * 100;
};

const parseDate = (date) => {
  const parsed = fromUnixTime(date.seconds);

  return format(parsed, 'yyyy-MM-dd')
};

const isUnlimited = (value) => value === '99999' ? 'Unlimited' : value;

const Usage = () => {
  const { subscriptionData, subscriptionLimits } = useContext(SubscriptionContext);
  const { usageData } = useContext(UsageContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => setHeader({ title: 'Usage' }), []);

  return (
    <Stack spacing={2}>
      {!isActive(subscriptionData) &&
        <Alert severity="error">
          Your plan is not active, please subscribe to continue using Lynky.
        </Alert>
      }

      {isActive(subscriptionData) &&
        <>
          <Alert severity="info">
            Usage for a period from {parseDate(subscriptionData.current_period_start)} to {parseDate(subscriptionData.current_period_end)}.
          </Alert>

          <Card>
            <CardHeader title="Organizations" sx={{ paddingBottom: 0 }} />

            <CardContent>
              <Typography gutterBottom component="p">
                You have used <b>{usageData.organizations} organizations of {isUnlimited(subscriptionLimits.organizations)}</b>
              </Typography>

              <LinearProgress
                variant="determinate"
                color={usageData.organizations > subscriptionLimits.organizations ? "secondary" : "primary"}
                value={calcUsage(usageData.organizations, subscriptionLimits.organizations)}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Members" sx={{ paddingBottom: 0 }} />

            <CardContent>
              <Typography gutterBottom component="p">
                You have used <b>{usageData.members} members of {isUnlimited(subscriptionLimits.members)}</b>
              </Typography>

              <LinearProgress
                variant="determinate"
                color={usageData.members > subscriptionLimits.members ? "secondary" : "primary"}
                value={calcUsage(usageData.members, subscriptionLimits.members)}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Clicks" sx={{ paddingBottom: 0 }} />

            <CardContent>
              <Typography gutterBottom component="p">
                You have used <b>{usageData.clicks} clicks of {subscriptionLimits.clicks}</b>
              </Typography>

              <LinearProgress
                variant="determinate"
                color={usageData.clicks > subscriptionLimits.clicks ? "secondary" : "primary"}
                value={calcUsage(usageData.clicks, subscriptionLimits.clicks)}
              />
            </CardContent>
          </Card>
        </>
      }
    </Stack>
  );
};

export default Usage;
