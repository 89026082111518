import React, { useReducer, useContext } from 'react';
import { storage } from 'utilities/firebase';
import Resizer from "react-image-file-resizer";

import { initialState } from './Model';
import ChoiceContext from './Context';
import Reducer from './Reducer';

// Data
import { OrganizationContext } from 'stores/organization';

// Actions
import createLink from 'actions/links/createLink';
import updateLink from 'actions/links/updateLink';

// Components
import Form from './Form';

const resizeImage = async (file, size) => {
  let widthHeight;

  switch (size) {
    case 'thumb':
      widthHeight = 50;
      break;
    case 'x-small':
      widthHeight = 128;
      break;
    case 'small':
      widthHeight = 600;
      break;
    case 'regular':
      widthHeight = 1080;
      break;
    case 'large':
      widthHeight = 1680;
      break;
    case 'full':
      widthHeight = 1920;
      break;
  }

  return new Promise((resolve) => Resizer.imageFileResizer(file, widthHeight, widthHeight, 'JPEG', 90, 0, (uri) => resolve(uri), 'blob')); 
}


const uploadImage = async (file, ref, size) => {
  const blob = await resizeImage(file, size);
  const storageCopy = storage.ref();
  const metadata = { contentType: file.type };

  return await storageCopy.child(`images/${ref}/${size}`).put(blob, metadata).then((snapshot) => snapshot.ref.getDownloadURL());
};

const uploadAvatar = async (file, ref) =>
  await Promise
    .all([
      uploadImage(file, ref, 'thumb'),
      uploadImage(file, ref, 'x-small'),
      uploadImage(file, ref, 'small'),
    ])
    .then((images) => {
      return {
        'thumb': images[0],
        'small': images[1],
        'regular': images[2],
      }
    })
    .catch(() => false);

const uploadBackground = async (file, ref) =>
  await Promise
    .all([
      uploadImage(file, ref, 'thumb'),
      uploadImage(file, ref, 'small'),
      uploadImage(file, ref, 'regular'),
      uploadImage(file, ref, 'large'),
      uploadImage(file, ref, 'full'),
    ])
    .then((images) => {
      return {
        'thumb': images[0],
        'small': images[1],
        'regular': images[2],
        'large': images[3],
        'full': images[4],
      }
    })
    .catch(() => console.log(false));

const deleteOldImages = (oldData, newData) => {
  if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
    if (oldData.file) {
      const storageCopy = storage.ref();
      const imageRef = storageCopy.child(`images/${oldData.file}`);
      
			imageRef
        .listAll()
        .then((result) =>
          result.items.forEach(function (file) {
             file.delete();
          })
        )
        .catch((error) => console.log(error));
    }
  };
};

const areProsEqual = (prev, next) => prev.id === next.id;

const ChoicesForm = React.memo(({ id, data, submit, cancel }) => {
  const { organizationLoading, organizationId } = useContext(OrganizationContext);
  const [ state, dispatch ] = useReducer(Reducer, data ? data : initialState());

  const handleSubmit = async (choice) => {
    const newChoice = Object.assign({}, choice);

    // Delete old uploaded images
    if (data) {
      deleteOldImages(data.avatar, newChoice.avatar);
      deleteOldImages(data.background, newChoice.background);
    }
    
    // Upload avatar
    if (newChoice.avatar.upload) {
      const file = newChoice.avatar.upload;
      const ref = newChoice.avatar.file;
      const avatars = await uploadAvatar(file, ref);

      if (avatars) {
        newChoice.avatar.urls = avatars;
        delete newChoice.avatar.upload;
      }
    }

    // Upload background
    if (newChoice.background.upload) {
      const file = newChoice.background.upload;
      const ref = newChoice.background.file;
      const bgs = await uploadBackground(file, ref);

      if (bgs) {
        newChoice.background.urls = bgs;
        delete newChoice.background.upload;
      }
    };

    const link = !id
      ? await createLink({
          organization: organizationId,
          ...newChoice
        })
      : await updateLink(id, data, newChoice);

    submit(link);
  };

  if (organizationLoading) return <p>Loading</p>;

  return (
    <ChoiceContext.Provider value={{ state, dispatch }}>
      <Form
        submit={handleSubmit}
        cancel={cancel}
      />
    </ChoiceContext.Provider>
  )
}, areProsEqual);

export default ChoicesForm;
