const DeviceTypeList = [
  { label: "PC", value: "pc" },
  { label: "Mobile", value: "mobile" },
  { label: "Tablet", value: "tablet" },
  { label: "Wearable", value: "wearable" },
  { label: "Smart TV", value: "smarttv" },
  { label: "Console", value: "console" },
  { label: "Embedded", value: "embedded" }
];

export default DeviceTypeList;
