import React, { useState, useContext, useEffect } from 'react';

import { OrganizationContext } from 'stores/organization';
import { HeaderContext } from 'stores/header';

import StatsRange from 'components/StatsRange';
import ClickStatsCard from 'components/cards/ClickStatsCard';
import CountryStats from 'components/cards/CountryStats';
import SourceStats from 'components/cards/SourceStats';
import Card from 'components/Card';

import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const AnalyticsPage = () => {
  const [ from, setFrom ] = useState(null);
  const [ to, setTo ] = useState(null);
  const { organizationId, organizationData } = useContext(OrganizationContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => setHeader({ title: 'Analytics' }), []);

  return (
    <React.Fragment>
      <Toolbar>
        {organizationData &&
          <StatsRange
            from={setFrom}
            to={setTo}
            createdOn={organizationData.createdOn}
          />
        }
      </Toolbar>

      <Box sx={{ padding: '1rem' }}>
        <Stack spacing={2}>
          <Card title="Clicks">
            <ClickStatsCard
              column="organization"
              id={organizationId}
              from={from}
              to={to}
            />
          </Card>

          <CountryStats
            column="organization"
            id={organizationId}
            from={from}
            to={to}
          />

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <SourceStats
              title="referers"
              column="organization"
              field="referer"
              id={organizationId}
              from={from}
              to={to}
            />

            <SourceStats
              title="browsers"
              column="organization"
              field="browser"
              id={organizationId}
              from={from}
              to={to}
            />
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <SourceStats
              title="device types"
              column="organization"
              field="device"
              id={organizationId}
              from={from}
              to={to}
            />

            <SourceStats
              title="operating systems"
              column="organization"
              field="os"
              id={organizationId}
              from={from}
              to={to}
            />
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default AnalyticsPage;
