import React from 'react';

import Continent from './Conditions/Continent';
import Country from './Conditions/Country';
import Region from './Conditions/Region';
import GeoArea from './Conditions/GeoArea';
import Language from './Conditions/Language';
import OperatingSystem from './Conditions/OperatingSystem';
import DeviceType from './Conditions/DeviceType';
import Browser from './Conditions/Browser';
import BrowserEngine from './Conditions/BrowserEngine';
import DateTime from './Conditions/DateTime';
import TimeZone from './Conditions/TimeZone';
import IPAddress from './Conditions/IPAddress';
import Referer from './Conditions/Referer';

const renderConditionComponent = (type, data, onChange) => {
  switch (type) {
    case 'continent':
      return <Continent target={data} save={onChange} />
    case 'country':
      return <Country target={data} save={onChange} />
    case 'region':
      return <Region target={data} save={onChange} />
    case 'geoarea':
      return <GeoArea target={data} save={onChange} />
    case 'language':
      return <Language target={data} save={onChange} />
    case 'os':
      return <OperatingSystem target={data} save={onChange} />
    case 'browser':
      return <Browser target={data} save={onChange} />
    case 'browserengine':
      return <BrowserEngine target={data} save={onChange} />
    case 'devicetype':
      return <DeviceType target={data} save={onChange} />
    case 'datetime':
      return <DateTime data={data} save={onChange} />
    case 'timezone':
      return <TimeZone target={data} save={onChange} />
    case 'ipaddress':
      return <IPAddress target={data} save={onChange} />
    case 'referer':
      return <Referer target={data} save={onChange} />
    default:
      return null;
  };
};

const ConditionValues = ({ type, target, onChange }) => {
  return (
    renderConditionComponent(type, target, onChange)
  );
};

export default ConditionValues;
