import React, { useContext, useEffect } from 'react';

import { UserContext } from 'stores/user';
import { SubscriptionContext } from 'stores/subscription';
import { HeaderContext } from 'stores/header';

import Plans from './Plans';
import BillingPortal from './BillingPortal';
import { isActive, isTrialing } from 'utilities/user';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Subscription = () => {
  const { userId, userData } = useContext(UserContext);
  const { subscriptionData } = useContext(SubscriptionContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => setHeader({ title: 'Subscription' }), []);

  const isPaid = () => ['active', 'incomplete'].includes(subscriptionData.status);
  const isUnpaid = () => ['incomplete_expired', 'past_due', 'unpad'].includes(subscriptionData.status);

  const cancelAtPeriodEnd = () => subscriptionData.cancel_at_period_end;

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        {isTrialing(subscriptionData) &&
          <Typography variant="h5" gutterBottom>
            You are currently on 7-days Growth plan trial,
            which will expire in {subscriptionData.trial_end} days.
          </Typography>
        }

        {isPaid() && !cancelAtPeriodEnd() &&
          <Typography component="h5" gutterBottom>
            You have successfully subscribed to {subscriptionData.role} plan, which will renew on {subscriptionData.current_period_end.toDate().toDateString()}.
          </Typography>
        }

        {isPaid() && cancelAtPeriodEnd() &&
          <Typography component="h5" gutterBottom>
            Your subscription to {subscriptionData.role} plan is canceled and will be valid until {subscriptionData.cancel_at.toDate().toDateString()}. You can resubscribe at any moment.
          </Typography>
        }

        {isUnpaid() && 
          <Typography component="h5" gutterBottom>
            We are sorry to inform you that something went wrong while processing your credit card, plase update your credit card information to activate your Lynky account.
          </Typography>
        }

        {(isPaid() || isUnpaid()) && <BillingPortal />}

        {(!isActive(subscriptionData) || isTrialing(subscriptionData)) &&
          <>
            <Typography component="h5" gutterBottom>
              Plase subscribe to one of the plans to continue using Lynky.
            </Typography>

            <Plans user={userId} trial={!userData.stripeId} />
          </>
        }
      </Grid>
    </Grid>
  );
};

export default Subscription;
