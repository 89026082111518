import react, { useState } from 'react';
import Firebase from 'firebase/app'

// Components
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const RemoveInvite = ({ id }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setConfirmOpen(false);
    setLoading(true);

    Firebase
      .firestore()
      .collection('invites')
      .doc(id)
      .delete();
  }

  return (
    <>
      {loading && <CircularProgress size={20} />}

      {!loading &&
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => setConfirmOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
      }

      <ConfirmDialog
        title="Delete invitation"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      >
       Are you sure you want to delete this invitation? 
      </ConfirmDialog>
    </>
  );
};

export default RemoveInvite;
