import React, {
  useState,
  useEffect,
  useContext,
  createContext
} from "react";
import Firebase from 'firebase/app';
import { OrganizationContext } from './organization';

const ParamsContext = createContext();

const ParamsProvider = ({ children }) => {
  const { organizationLoading, organizationId } = useContext(OrganizationContext);

  const [state, setState] = useState({
    paramsLoading: true,
    paramsError: false,
    paramsData: [],
  });

  useEffect(() => {
    if (!organizationLoading && organizationId) {

      const unsubscribe = Firebase
        .firestore()
        .collection('params')
        .where('organization', '==', organizationId)
        .onSnapshot((querySnapshot) => {
          const data = [];

          if (!querySnapshot.empty)
            querySnapshot.forEach((doc) => data.push(doc));

          setState(prevState => ({
            ...prevState,
            paramsLoading: false,
            paramsData: data,
          }));
        });

      return () => unsubscribe();

    } else if (!userLoading && !userId) {
      setState({
        paramsLoading: false,
        paramsError: false,
        paramsData: [],
      });
    }
  }, [organizationLoading, organizationId]);

  return (
    <ParamsContext.Provider value={state}>
      {children}
    </ParamsContext.Provider>
  );
};

export { ParamsContext, ParamsProvider };
