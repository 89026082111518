import React from 'react';
import { isActive } from 'utilities/user';
import { Link } from "react-router-dom";

// Material UI
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

const OrganizationCard = ({ user, organization, subscription }) => {
  return (
    <Card
      component={Link}
      to={organization.slug}
      variant="outlined"
      sx={{
        height: '100%',
        textDecoration: 'none',
      }}
    >
      <CardActionArea
        sx={{
          height: '100%',
        }}
      >
        <CardContent>
          <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
            {organization.title}
          </Typography>
          
          <Typography variant="body1" gutterBottom>
            {organization.website}
          </Typography>

          <Grid container gap={1}>
            {organization.roles[user] === 'owner' && !isActive(subscription) &&
              <Chip label="Inactive"
                    size="small"
                    color="secondary"
              />
            }

            <Chip label={organization.roles[user]}
                  size="small"
                  color="primary"
            />
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OrganizationCard;
