import React, { useState } from 'react';

// UI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const AddParam = ({ add, error }) => {
  const [ key, setKey ] = useState('');
  const [ value, setValue ] = useState('');

  const handleAdd = () => {
    add({
      key: key,
      value: value,
    });

    setKey('');
    setValue('');
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <TextField
        name="key"
        placeholder="Key"
        value={key}
        onChange={(e) => setKey(e.target.value)}
        required={error}
        error={error}
        variant="standard"
        fullWidth
      />

      <TextField
        name="value"
        placeholder="Value"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        required={error}
        error={error}
        variant="standard"
        fullWidth
      />

      <IconButton
        onClick={handleAdd}
        size="small"
      >
        <AddIcon />
      </IconButton>
    </Stack>
  );
};

export default AddParam;
