import Firebase from 'firebase/app'
import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

const updateLink = async (linkId, old, updated) => {

  // If redirect, update default reference
  if (old.default) {
    if (old.default.url !== updated.default.url) {
      if (old.default.type !== "direct") {
        await Firebase
          .firestore()
          .collection('links')
          .doc(old.default.url)
          .update({
            refsFrom: Firebase.firestore.FieldValue.arrayRemove(linkId)
          })
      };

      if (updated.default.type !== "direct") {
        await Firebase
          .firestore()
          .collection('links')
          .doc(updated.default.url)
          .update({
            refsFrom: Firebase.firestore.FieldValue.arrayUnion(linkId)
          })
      };
    };
  };

  // Update link references
  const removedRefs = old.items
    ? old.items.filter(item => {
        if (item.type !== 'direct')
          return !updated.items.some(({ url }) => url === item.url)
      })
    : false;

  const addedRefs = updated.items
    ? updated.items.filter(item => {
        if (item.type !== 'direct')
          return !old.items.some(({ url }) => url === item.url)
      })
    : false;

  if (removedRefs.length)
    await Promise.all(removedRefs.map((ref) =>
      Firebase
        .firestore()
        .collection('links')
        .doc(ref.url)
        .update({
          refsFrom: Firebase.firestore.FieldValue.arrayRemove(linkId)
        })
        .catch(function(error) {
          console.error("Error removing the reference document: ", error);
        })
    ))

  if (addedRefs.length)
    await Promise.all(addedRefs.map((ref) =>
      Firebase
        .firestore()
        .collection('links')
        .doc(ref.url)
        .update({
          refsFrom: Firebase.firestore.FieldValue.arrayUnion(linkId)
        })
    ))

  // Update
  return await Firebase
    .firestore()
    .collection('links')
    .doc(linkId)
    .set(prepareDocForUpdate(updated));
}

export default updateLink
