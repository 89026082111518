import React, { useContext, useReducer } from 'react';

// Data
import { OrganizationContext } from 'stores/organization';

// Actions
import createLink from 'actions/links/createLink';
import updateLink from 'actions/links/updateLink';

// Form
import { initialState } from './Model';
import SplitContext from './Context';
import Reducer from './Reducer';
import Form from './Form';

const SplitForm = ({ id, data, submit, cancel }) => {
  const [ state, dispatch ] = useReducer(Reducer, data ? data : initialState());
  const { organizationId } = useContext(OrganizationContext);

  const handleSubmit = async () => {
    const link = id
      ? await updateLink(id, data, state)
      : await createLink({
          organization: organizationId,
          ...state,
        });

    submit(link);
  };

  return (
    <SplitContext.Provider value={{ state, dispatch }}>
      <Form
        submit={handleSubmit}
        cancel={cancel}
      />
    </SplitContext.Provider>
  );
};

export default SplitForm;
