import axios from 'axios';

const analyticsGetCount = (params) => 
  axios.get(`${process.env.REACT_APP_ANALYTICS_DOMAIN}/analytics/count`, {
    params: {
      ...params,
    }
  })

const analyticsGetBySource = (params) =>
  axios.get(`${process.env.REACT_APP_ANALYTICS_DOMAIN}/analytics/source`, {
    params: {
      ...params,
    }
  })


export { analyticsGetCount, analyticsGetBySource };
