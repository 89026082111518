import React, { useState } from 'react';
import Firebase from 'firebase/app'

import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import createOrganization from 'actions/organizations/createOrganization';
import slugify from 'slugify';

import HeaderBar from 'components/HeaderBar';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// It can only contain letters, numbers and '_'
const createNewSlug = title =>
  slugify(title,
    {
      replacement: '',
      lower: true,
      remove: /[^a-zA-Z0-9_.]/
    }
  );

const OrganizationNew = () => {
  const [slug, setSlug] = useState('');
  const [slugError, setSlugError] = useState(false);
	const [user] = useAuthState(Firebase.auth());
  const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

  const checkIfSlugAvailable = async (slug) => {
    const query = await Firebase.firestore()
      .collection('organizations')
      .where('slug', '==', slug)
      .get();

    return query.empty;
  }

  const updateSlug = e => {
    const newSlug = createNewSlug(e.target.value);
    const isAvailable = checkIfSlugAvailable(newSlug);

    setSlug(newSlug);

    isAvailable.then((val) => val
      ? setSlugError(false)
      : setSlugError(true)
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (slugError) return;

    setLoading(true);

    const {title, slug, website} = event.target.elements

    const values = {
      title: title.value,
      slug: slug.value,
      website: website.value,
      affiliates: [],
      status: 'active',
      roles: {
        [user.uid]: 'owner'
      },
    };

    createOrganization(values).then(() => navigate(`/${slug.value}`));
  }

  if (loading) return <LinearProgress />;

  return (
    <Container maxWidth="none" disableGutters>
      <HeaderBar title="New organization">
        <IconButton
          component={Link}
          to="/"
          edge="start"
          color="inherit"
          aria-label="navigate back"
        >
          <ArrowBackIosIcon />
        </IconButton>
      </HeaderBar>

      <Container maxWidth="sm">
        <form onSubmit={onSubmit}>
          <TextField
            id="title"
            label="Title"
            onChange={updateSlug}
            variant="standard"
            required
            sx={{ marginTop: (theme) => theme.spacing(2) }}
            fullWidth
          />

          <TextField
            id="slug"
            label="Slug"
            onChange={updateSlug}
            value={slug}
            InputProps={{
              startAdornment: <InputAdornment position="start">lynky.to/</InputAdornment>
            }}
            error={slugError}
            helperText={slugError ? 'Not available' : 'It can only contain letters, numbers and "_"'}
            required
            fullWidth
            variant="standard"
            sx={{ marginTop: '1rem' }} 
          />

          <TextField
            id="website"
            label="Website"
            variant="standard"
            fullWidth
          />

          <Box sx={{ marginTop: (theme) => theme.spacing(2) }}>
            <Button variant="contained" color="primary" type="submit">
              Create
            </Button>
          </Box>
        </form>
      </Container>
    </Container>
  )
}

export default OrganizationNew
