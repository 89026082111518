// - URL must be valid
import React, { useState, useContext, useEffect } from 'react';

// Data
import { OrganizationContext } from 'stores/organization';

// Libs
import { parseDomain, fromUrl } from "parse-domain";

// UI
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Tooltip from '@mui/material/Tooltip';

const equals = (a, b) =>
  a.length === b.length &&
  a.every((v, i) => v === b[i]);

const LinkUrl = React.memo(({ data, save, ...params }) => {
  const { organizationData } = useContext(OrganizationContext);
  const [state, setState] = useState('https://');
  const [affiliated, setAffiliated] = useState(false);

  // Update input value on change
  useEffect(() => {
    if (data && data !== '') setState(data)
  }, [data]);

  // Check if is affiliated
  useEffect(() => {
    const { domain, topLevelDomains } = parseDomain(fromUrl(state));

    const affiliateProgram = organizationData.affiliates.find(program => {
      const target = parseDomain(fromUrl(program.hostname));

      return target.domain === domain && equals(target.topLevelDomains, topLevelDomains);
    })

    affiliateProgram ? setAffiliated(true) : setAffiliated(false); 
  }, [state]);

  // Update on type
  const handleChange = e => {
    const { value } = e.target;

    setState(value);
    save(e);
  }

  return (
    <TextField
      type="url"
      value={state}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          affiliated
            ? <InputAdornment position="start">
                <Tooltip title="Affiliated" aria-label="affiliated">
                  <MonetizationOnIcon fontSize="small" />
                </Tooltip>
              </InputAdornment>
            : <div></div>
        ),
      }}
      variant="standard"
      {...params}
    />
  );
});

export default LinkUrl;
