import React, { useState, useEffect } from 'react';
import Firebase from 'firebase/app';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const Prices = ({ planId, subscribe }) => {
  const [ prices, setPrices ] = useState([]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isEurope = timezone.substring(0, 6) === "Europe";
  const currency = isEurope ? 'eur' : 'usd';

  const [data, loading, error] = useCollectionOnce(
    Firebase
      .firestore()
      .collection('products')
      .doc(planId)
      .collection('prices')
      .where('active', '==', true)
      .where('currency', '==', currency)
  );

  // Sort prices by month first
  useEffect(() => {
    if (data)
      setPrices(data.docs.sort((a, b) => a.data().interval === "month" ? -1 : 1));
  }, [data]);

  const subscriptionPeriod = (period) =>
    period === 'month' ? 'Monthly ' : 'Yearly ';

  const currencySymbol = (currency) => {
    switch (currency) {
      case 'usd':
        return '$';
      case "eur":
        return '€';
      default:
        return '$';
    };
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      sx={{ width: '100%' }}
    >
      {!loading && prices.length && prices.map((doc) => {
        const price = doc.data();

        return(
          <Button
            variant="contained"
            color="primary"
            onClick={() => subscribe(doc.id, false)}
            fullWidth
            key={doc.id}
          >
            {subscriptionPeriod(price.interval)}
            {currencySymbol(price.currency)}{price.unit_amount/100}
            {price.interval === 'year' ? ' (save 20%)': ''}
          </Button>
        )
      })}
    </Stack>
  );
};

export default Prices;
