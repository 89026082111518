import React, { useState } from 'react';
import { format } from 'date-fns'

// Components
import GroupForm from 'forms/Group';

// UI
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Info = ({ group }) => {
  const [ formOpen, setFormOpen ] = useState(false);

  const toggleForm = () => setFormOpen(!formOpen);

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Stack spacing={1} sx={{ flex: 1, padding: 2 }}>
          <Typography variant="h4">
            {group.data().title}
          </Typography>

          <Typography color="text.secondary" variant="body2"> 
            Group ID: {group.data().slug}
          </Typography>

          <Typography color="text.secondary" variant="body2"> 
            Created on: {format(group.data().createdOn.toDate(), "dd MMM yyyy 'at' HH:mm")}
          </Typography>

          {group.data().updatedOn &&
            <Typography color="text.secondary" variant="body2"> 
              Last update: {format(group.data().updatedOn.toDate(), "dd MMM yyyy 'at' HH:mm")}
            </Typography>
          }

          <Button onClick={toggleForm}>
            Edit 
          </Button>
        </Stack>
      </Container>

      <GroupForm
        open={formOpen}
        id={group.id}
        data={group.data()}
        submit={toggleForm}
        cancel={toggleForm}
      />
    </React.Fragment>
  );
};

export default Info;
