import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Firebase from 'firebase/app'
import slugify from 'slugify';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

// Actions
import updateOrganization from 'actions/organizations/updateOrganization';

// UI
import Title from 'components/Title';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const createNewSlug = title =>
  slugify(title,
    {
      replacement: '',
      lower: true,
      remove: /[^a-zA-Z0-9_.]/
    }
  );

const GeneralSettings = () => {
	const navigate = useNavigate();
  const [slugError, setSlugError] = useState(false);
  const { organizationLoading, organizationData, organizationId } = useContext(OrganizationContext);
  const { userId } = useContext(UserContext);
  const [alert, setAlert] = useState(false);
  const [ fields, setFields ] = useState({
    title: '',
    slug: '',
    website: '',
  });

  const hasPermissions = () => ['owner'].includes(organizationData.roles[userId]);

  useEffect(() => {
    if (organizationData) {
      const values = {
        title: organizationData.title,
        slug: organizationData.slug,
        website: organizationData.website,
      };

      setFields(values);
    }
  }, [organizationLoading]);

  const checkIfSlugAvailable = async (slug) => {
    if (organizationData.slug === slug) return true;

    const query = await Firebase.firestore()
      .collection('organizations')
      .where('slug', '==', slug)
      .get();

    return query.empty;
  }

  const handleAlert = () => {
    setAlert(false);
  };

  const handleChange = e => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  }

  const handleSlugChange = e => {
    const newSlug = createNewSlug(e.target.value);
    const isAvailable = checkIfSlugAvailable(newSlug);

    setFields({
      ...fields,
      [e.target.name]: newSlug,
    });

    isAvailable.then((val) => val
      ? setSlugError(false)
      : setSlugError(true)
    );
  };

  const save = e => {
    e.preventDefault();
    if (slugError) return;

    updateOrganization(organizationId, fields).then(() => {
      if (organizationData.slug !== fields.slug) {
        navigate('/')
      } else {
        setAlert(true);
      }
    });
  }

  return (
    <React.Fragment>
      <Title>
        General
      </Title>

      <Box mt={2}>
        <form onSubmit={save}>
          <TextField
            id="title"
            name="title"
            label="Title"
            onChange={handleChange}
            value={fields.title}
            disabled={hasPermissions() ? false : true}
            variant="standard"
            required
            fullWidth
          />

          <TextField
            id="slug"
            name="slug"
            label="Slug"
            value={fields.slug}
            onChange={handleSlugChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">lynky.to/</InputAdornment>
            }}
            error={slugError}
            helperText={slugError ? 'Not available' : 'It can only contain letters, numbers and "_"'}
            disabled={hasPermissions() ? false : true}
            style={{ marginTop: '1rem' }} 
            variant="standard"
            required
            fullWidth
          />

          <TextField
            id="website"
            name="website"
            label="Website"
            value={fields.website}
            onChange={handleChange}
            disabled={hasPermissions() ? false : true}
            style={{ marginTop: '1rem' }} 
            variant="standard"
            fullWidth
          />

          {hasPermissions() &&
            <Box mt={3}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          }

          <Snackbar open={alert} autoHideDuration={3000} onClose={handleAlert}>
            <Alert onClose={handleAlert} severity="success">
              Settings saved  
            </Alert>
          </Snackbar>
        </form>
      </Box>
    </React.Fragment>
  )
}

export default GeneralSettings;
