import React, { useContext, useEffect } from 'react';

// Data
import { HeaderContext } from 'stores/header';
import { LinksContext } from 'stores/links';

// Components
import LinksList from 'components/LinksList';

// UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const LinksPage = () => {
  const { setHeader } = useContext(HeaderContext);
  const {
    linksLoading,
    linksHasMore,
    linksList,
    loadMoreLinks
  } = useContext(LinksContext);

  useEffect(() => setHeader({ title: 'Links'}), []);

  return (
    <React.Fragment>
      <LinksList data={linksList} />

      { linksHasMore &&
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          {linksLoading && <CircularProgress />}

          {!linksLoading && linksHasMore &&
            <Button
              variant="contained"
              onClick={() => loadMoreLinks()}
            >
              Load more
            </Button>
          }
        </Box> }
    </React.Fragment>
  );
};

export default LinksPage;
