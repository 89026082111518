import React from 'react';

import LinkTypeSelect from './LinkTypeSelect';
import LinkUrl from './LinkUrl';
import LinkSearch from './LinkSearch';
import LinkModal from 'components/LinkModal';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const arePropsEqual = (prev, next) =>
  prev.type === next.type &&
  prev.url === next.url;

const Link = React.memo(({ type, url, save }) => {
  const handleTypeChange = (e) =>
    save({
      type: e.target.value,
      url: '',
    });

  const handleURLChange = (e) =>
    save({
      type: type,
      url: e.target.value,
    });

  const handleSearchChange = (e) => {
    const linkID =  e && e.objectID ? e.objectID : '';

    save({
      type: type,
      url: linkID,
    });
  };

  const handleLinkSave = (id) =>
    save({
      type: type,
      url: id,
    });

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Box sx={{ width: '30%' }}>
        <LinkTypeSelect
          value={type}
          change={handleTypeChange}
        />
      </Box>

      {type === 'direct'
        ? <LinkUrl
            id="url"
            label="URL"
            name="url"
            data={url}
            save={handleURLChange}
            required
            fullWidth
          />
        : <Stack
            direction="row"
            alignItems="flex-end"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <LinkSearch
              type={type}
              selected={url}
              onSelect={handleSearchChange}
            />

            <LinkModal
              type={type}
              id={url}
              save={handleLinkSave}
            />
        </Stack>
      }
    </Stack>
  );
}, arePropsEqual);

export default Link;
