import { nanoid } from 'nanoid'

const item = () => {
  return {
    id: nanoid(6),
    title: '',
    type: 'direct',
    url: 'https://',
  }
};

const initialState = () => {
  return {
    type: 'choice',
    title: '',
    description: '',
    avatar: {
      type: '',
      value: '',
    },
    background: {
      type: 'color',
      value: '#ffffff',
    },
    items: [
      item(),
      item(),
    ],
    groups: [],
  }
};

export { initialState, item }
