import React from 'react';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

const Plan = ({ plan, children }) =>
  <Paper
    elevation={3}
    sx={{ width: '100%' }}
  >
    <Card variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h6">
          {plan.name}
        </Typography>
        
        <Typography component="p">
          {plan.description}
        </Typography>
      </CardContent>
      
      <CardActions>
        {children}
      </CardActions>
    </Card>
  </Paper>;

export default Plan;
