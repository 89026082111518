 export const Options = [
    {
      id: "amazon-uk",
      title: "Amazon United Kingdom",
      hostname: "amazon.co.uk",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-de",
      title: "Amazon Germany",
      hostname: "amazon.de",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-fr",
      title: "Amazon France",
      hostname: "amazon.fr",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-jp",
      title: "Amazon Japan",
      hostname: "amazon.co.jp",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-ca",
      title: "Amazon Canada",
      hostname: "amazon.ca",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-cn",
      title: "Amazon China",
      hostname: "amazon.cn",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-es",
      title: "Amazon Spain",
      hostname: "amazon.es",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-in",
      title: "Amazon India",
      hostname: "amazon.in",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-ae",
      title: "Amazon UAE",
      hostname: "amazon.ae",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-sa",
      title: "Amazon Saudi Arabia",
      hostname: "amazon.sa",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-sg",
      title: "Amazon Singapore",
      hostname: "amazon.sg",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-nl",
      title: "Amazon Netherlands",
      hostname: "amazon.nl",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-se",
      title: "Amazon Sweden",
      hostname: "amazon.se",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-au",
      title: "Amazon Australia",
      hostname: "amazon.com.au",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-us",
      title: "Amazon United States",
      hostname: "amazon.com",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-mx",
      title: "Amazon Mexico",
      hostname: "amazon.com.mx",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-brasil",
      title: "Amazon Brazil",
      hostname: "amazon.com.br",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "amazon-italia",
      title: "Amazon Italy",
      hostname: "amazon.it",
      params: [
        {
          key: "tag",
          value: ""
        }
      ]
    },
    {
      id: "barnesandnoble",
      title: "Barnes & Noble",
      hostname: "barnesandnoble.com",
      params: [
        {
          key: "pid",
          value: ""
        },
        {
          key: "aid",
          value: ""
        }
      ]
    },
 ]

export default Options;
