import React from 'react';

// Components
import Modal from 'components/Modal';
import UTMForm from 'forms/UTM';

// UI
import Box from '@mui/material/Box';

const UTM = ({ open, id, data, submit, cancel }) => {
  return (
    <Modal
      isOpen={open}
      handleClose={cancel}
      title={id ? 'Edit UTM' : 'Add UTM'}
    >
      <Box sx={{ padding: (theme) => theme.spacing(2) }}>
        <UTMForm
          id={id}
          data={data}
          submit={submit}
          cancel={cancel}
        />
      </Box>
    </Modal>
  );
};

export default UTM;
