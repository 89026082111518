import React from 'react';

import Preview from '../Preview';
import Upload from './Upload';
import Delete from './Delete';

import Box from '@mui/material/Box';

const Avatar = ({ data, dispatch }) =>
  <Box
    sx={{
      position: 'relative',
      height: 140,
      marginTop: '20px',
      background: '#eee',
    }}
  >
    <Preview background={data} />
    <Upload dispatch={dispatch} />
    <Delete data={data} dispatch={dispatch} />
  </Box>

export default Avatar;
