import React, { useState, useContext, useEffect } from 'react';

// Data
import { UTMContext } from 'stores/utms';

// Components
import Modal from 'components/Modal';
import UTMForm from 'forms/UTM';

// UI
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const UTMSelect = ({ data, change }) => {
  const { utmsData } = useContext(UTMContext);

  const [ formOpen, setFormOpen ] = useState(false);
  const [ formData, setFormData ] = useState();

  const [ selected, setSelected ] = useState(null);
  const [ inputValue, setInputValue ] = useState('');
  const [ options, setOptions ] = useState([]);

  const handleCreate = (utm) => {
    change(utm);
    setFormOpen(false);
  };

  useEffect(() => {
    const parsed = utmsData.reduce((acc, utm) => {
      acc.push({
        id: utm.id,
        title: utm.data().title,
        params: [
          ...utm.data().params,
        ],
      });

      return acc;
    }, [{
      id: 'custom',
      title: 'Custom',
    }]);

    setOptions(parsed);
  }, [utmsData]);

  useEffect(() => {
    if (options.length) {
      const index = options.findIndex((option) =>
        option.id === data
      );

      if (index !== -1) {
        setSelected(options[index]);
        setInputValue(options[index].title);
      } else {
        setSelected({
          title: 'UTM deleted'
        });
      };
    }
  }, [data, options]);

  return (
    <React.Fragment>
      <Autocomplete
        disableClearable
        value={selected}
        inputValue={inputValue}
        options={options}
        noOptionsText="No UTM codes defined"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.title}

        onChange={(e, newValue) => {
          if (newValue && newValue.inputValue) {
            setFormData({
              title: newValue.inputValue,
            });

            setFormOpen(true);
          } else {
            change(newValue)
          };
        }}

        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.title);

          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered; 
        }}

        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) =>
          <TextField
            {...params}
            placeholder="Select codes"
            variant="standard"
            fullWidth
          />
        }
      />

      <Modal
        isOpen={formOpen}
        handleClose={() => setFormOpen(false)}
        title="Create UTM codes"
      >
        <Box sx={{ padding: (theme) => theme.spacing(2) }}>
          <UTMForm
            data={formData}
            submit={handleCreate}
            cancel={() => setFormOpen(false)}
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default UTMSelect;
