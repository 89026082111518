import { arrayMove } from "@dnd-kit/sortable";
import { item } from './Model';

import {
  SET_STATE,

  SET_FIELD,
  DELETE_FIELD,

  ADD_DESTINATION,
  DELETE_DESTINATION,
  UPDATE_DESTINATION,
  MOVE_DESTINATION,

  UPDATE_GROUPS,
} from './Constants';

const getTotalWeight = (weights) => weights.reduce((total, destination) => total + destination.weight, 0);

const getIndexOfMaxValue = (weights, ignore) =>
  weights.reduce((iMax, x, i, arr) => {
    if (i === ignore) return iMax;
    
    return x.weight > arr[iMax].weight ? i : iMax
  }, ignore === 0 ? 1 : 0);

const getIndexOfLowValue = (weights, ignore) =>
  weights.reduce((iMax, x, i, arr) => {
    if (i === ignore) return iMax;
    
    return x.weight < arr[iMax].weight ? i : iMax
  }, ignore === 0 ? 1 : 0);

const Reducer = (state, action) => {
  switch (action.type) {

    case SET_STATE:
      return {
        ...action.value,
      };

    case SET_FIELD: {
      const { field, value } = action.value;
      const copy = { ...state };
      copy[field] = value;

      return {
        ...copy,
      };
    };

    case DELETE_FIELD: {
      const field = action.value;
      const copy = { ...state };

      delete copy[field];

      return {
        ...copy,
      };
    };

    case ADD_DESTINATION: {
      return {
        ...state,
        items: [
          ...state.items,
          item(0),
        ],
      };
    }

    case DELETE_DESTINATION: {
      const copy = state.items.map(item => ({...item}));
      const items = copy.filter((doc) => doc.id !== action.value);
      const total = getTotalWeight(items);
      const rest = (100 - total) / items.length;
      const updated = items.map((item) => {
        item.weight = item.weight + rest;
        return item;
      });

      return {
        ...state,
        items: [
          ...updated,
        ]
      }
    }

    case UPDATE_DESTINATION: {
      const { id, name, value } = action.value;
      const stateCopy = state.items.map(item => ({...item}));
      const index = stateCopy.findIndex(item => item.id === id);

      // Set new value
      stateCopy[index][name] = value;

      // Distribuite weights
      if (name === 'weight') {
        const total = getTotalWeight(stateCopy);
        const rest = total - 100;

        if (rest > 0) {
          const indexOfMaxValue = getIndexOfMaxValue(stateCopy, index);
          stateCopy[indexOfMaxValue]['weight'] = stateCopy[indexOfMaxValue]['weight'] - rest;
        } else {
          const indexOfLowValue = getIndexOfLowValue(stateCopy, index);
          stateCopy[indexOfLowValue]['weight'] = stateCopy[indexOfLowValue]['weight'] + Math.abs(rest);
        };
      }

      return {
        ...state,
        items: [
          ...stateCopy,
        ],
      }
    }

    case MOVE_DESTINATION: {
      const { active, over } = action.value;

      if (active.id !== over.id) {
        const oldIndex = state.items.findIndex(item => item.id === active.id);
        const newIndex = state.items.findIndex(item => item.id === over.id);
        const newOrder = arrayMove(state.items, oldIndex, newIndex);

        return {
          ...state,
          items: [
            ...newOrder
          ],
        };
      }
    }

    case UPDATE_GROUPS: {
      const { value } = action;

      return {
        ...state,
        groups: [
          ...value,
        ],
      };
    };

    default:
      return state;
  }
};

export default Reducer;
