import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Firebase from 'firebase/app';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { LinksContext } from 'stores/links';
import { HeaderContext } from 'stores/header';

// Utilities
import { canEdit } from 'utilities/member';

// Components
import ShortForm from 'forms/short/Index';
import RedirectForm from 'forms/redirect/Index';
import ChoicesForm from 'forms/choices/Index';
import SplitForm from 'forms/split/Index';

// UI
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

const LinkEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { userLoading, userId } = useContext(UserContext);
  const { organizationLoading, organizationData } = useContext(OrganizationContext);
  const { linksLoading, linksList } = useContext(LinksContext);
  const { setHeader } = useContext(HeaderContext);

  const [ link, setLink ] = useState();

  // Set title
  useEffect(() => setHeader({ title: 'Edit link'}), []);

  // Load link data
  useEffect(async () => {
    if (id && linksList.length && !link) {
      const linkIndex = linksList.findIndex(doc => doc.id === id);

      const linkData = linkIndex !== -1
        ? linksList[linkIndex]
        : await Firebase
            .firestore()
            .collection('links')
            .doc(id)
            .get()
            .then((snapshot) => snapshot);

      setLink(linkData);
    }
  }, [linksLoading]);

  // Submit
  const handleSubmit = () => navigate(-1);

  // Cancel
  const handleCancel = () => navigate(-1);

  return (
    <React.Fragment>
      { userLoading || organizationLoading || !link
        ? <LinearProgress />
        : <Container
            sx={{ marginTop: (theme) => theme.spacing(2) }}
            maxWidth="sm"
          >
            {canEdit(organizationData.roles[userId])
              ? (link.data().type === 'short' &&
                  <ShortForm
                    id={link.id}
                    data={link.data()}
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
                || (link.data().type === 'redirect' &&
                  <RedirectForm
                    id={link.id}
                    data={link.data()}
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
                || (link.data().type === 'split' &&
                  <SplitForm
                    id={link.id}
                    data={link.data()}
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
                || (link.data().type === 'choice' &&
                  <ChoicesForm
                    id={link.id}
                    data={link.data()}
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
              : <p>Huh, it seems like you don't have a permission to edit links</p>
            }
          </Container>
      }
    </React.Fragment>
  );
};

export default LinkEdit;
