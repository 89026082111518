import React, { useContext } from 'react';
import Firebase from 'firebase/app'
import { Link } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';

import { OrganizationContext } from 'stores/organization';

// Components
import Member from './Member';
import MemberRole from './MemberRole';
import RemoveMember from './RemoveMember';
import RemoveInvite from './RemoveInvite';

// UI
import Title from 'components/Title';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {Divider} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const MembersPage = () => {
	const [user] = useAuthState(Firebase.auth()); // use context
  const { organizationLoading, organizationData, organizationId } = useContext(OrganizationContext);
  const [invites, invitesLoading, invitesError] = useCollection(
    Firebase
      .firestore()
      .collection('invites')
      .where('organization', '==', organizationId)
  );

  const isOwner = () => organizationData.roles[user.uid] === 'owner';
  const hasPrivileges = () => ['owner'].includes(organizationData.roles[user.uid]);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>
          Members
        </Title>
        
        {!organizationLoading &&
          organizationData &&
          hasPrivileges() &&
          <Link to="invite">
            <Button
              variant="contained"
              color="primary"
              endIcon={<AddCircleIcon />}
              size="small"
            >
              Add
            </Button>
          </Link>
        }
      </Grid>

      {organizationData &&
        <Box mt={2}>
          <TableContainer component={Paper}>
            <Table aria-label="organization users" size="small">

              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(organizationData.roles).map((role) =>
                  <TableRow key={role}>
                    <TableCell component="th" scope="row">
                      <Member currentUser={user.uid} id={role} />
                    </TableCell>

                    <TableCell>
                      {organizationData.roles[role] !== 'owner' ?
                        <MemberRole
                          organizationId={organizationId}
                          userId={role}
                          userRole={organizationData.roles[role]}
                          disabled={
                            isOwner() ? false :
                            (hasPrivileges() && ['editor', 'reader'].includes(organizationData.roles[role]) && role !== user.uid) ? false : true
                          }
                        />
                        : <Typography variant="body1">Owner</Typography>
                      }
                    </TableCell>

                    {isOwner() && organizationData.roles[role] !== 'owner'
                      ? <TableCell>
                          <RemoveMember organizationId={organizationId} userId={role} />
                        </TableCell>
                      : hasPrivileges() && ['editor', 'reader'].includes(organizationData.roles[role]) && role !== user.uid &&
                        <TableCell>
                          <RemoveMember organizationId={organizationId} userId={role} />
                        </TableCell>
                    }
                  </TableRow>
                )}

                {!invitesLoading && invites.docs.map((invite) => {
                  const data = invite.data();
                  
                  return (
                    <TableRow key={invite.id}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                          {data.email}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="body1">
                          Pending invite
                        </Typography>
                      </TableCell>

                      {['owner', 'admin'].includes(organizationData.roles[user.uid]) &&
                        <TableCell>
                          <RemoveInvite id={invite.id} />
                        </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </TableBody>

            </Table>
          </TableContainer>
        </Box>
      }

      <Box mt={3}>
        <Title>
          Roles
        </Title>

        <Divider style={{ marginBottom: '10px' }} />

        <Typography component="h3" variant="h6">
          Admin role has access to:
        </Typography>

        <List> 
          <ListItem>- View links</ListItem>
          <ListItem>- Create links</ListItem>
          <ListItem>- Update links</ListItem>
          <ListItem>- Delete links</ListItem>
        </List>

        <Typography component="h3" variant="h6">
          Editor role has access to:
        </Typography>

        <List> 
          <ListItem>- View links</ListItem>
          <ListItem>- Create links</ListItem>
          <ListItem>- Update links</ListItem>
        </List>

        <Typography component="h3" variant="h6">
          Reader role has access to:
        </Typography>

        <List> 
          <ListItem>- View links</ListItem>
        </List>
      </Box>
    </React.Fragment>
  );
};

export default MembersPage;
