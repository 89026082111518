import React, { useState } from 'react';

import { DELETE_AVATAR } from '../../../Constants';

import ConfirmDialog from 'components/ConfirmDialog';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const arePropsEqual = (prev, next) =>
  prev.data.value === next.data.value;

const Delete = React.memo(({ data, dispatch }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleOpen = () => setConfirmOpen(true)
  const handleDelete = () =>
    dispatch({
      type: DELETE_AVATAR,
    });

  return ( 
    data.value !== '' &&
      <React.Fragment>
        <IconButton
          size="small"  
          aria-label="edit"
          onClick={handleOpen}
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '50px',
            padding: '5px',
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <ConfirmDialog
          title="Delete avatar?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        >
          Are you sure you want to delete this avatar?
        </ConfirmDialog>
      </React.Fragment>
  );
}, arePropsEqual);

export default Delete;
