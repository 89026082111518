// Helper functions for working with Firebase Firestore
import Firebase from 'firebase/app'

const prepareDocForCreate = doc => {
  return {
    ...doc,
    createdBy: Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null,
    createdOn: Firebase.firestore.Timestamp.now(),
  };
}

const prepareDocForUpdate = doc => {

  // timestamps
  const objCopy = {
    ...doc,
    updatedBy: Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null,
    updatedOn: Firebase.firestore.Timestamp.now(),
  };

  // don't save the id as part of the document
  delete objCopy.id

  // don't save values that start with an underscore (these are calculated by the backend)
  Object.keys(objCopy).forEach( key => {
    if (key.indexOf('_') === 0) {
      delete objCopy[key]
    }
  })

  return objCopy
}

export {
  prepareDocForCreate,
  prepareDocForUpdate,
}
