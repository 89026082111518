import React, { useState, useEffect, useContext } from 'react';

// Data
import { HeaderContext } from 'stores/header';

// Components
import List from './List';
import GroupForm from 'forms/Group';

// UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const GroupsPage = () => {
  const { setHeader } = useContext(HeaderContext);

  const [ formOpen, setFormOpen ] = useState(false);

  useEffect(() => setHeader({ title: 'Groups' }), []);

  const toggleForm = () => setFormOpen(!formOpen);

  return (
    <Box sx={{ padding: (theme) => theme.spacing(2) }}>
      <Stack spacing={2}>
        <Stack>
          <Button onClick={toggleForm}>
            Create 
          </Button>
        </Stack>

        <List />
      </Stack>

      <GroupForm
        open={formOpen}
        submit={toggleForm}
        cancel={toggleForm}
      />
    </Box>
  )
}

export default GroupsPage;
