import React, { useState } from 'react';

import Param from './Param';

import {
  ADD_PARAM,
  DELETE_PARAM,
} from '../Constants';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

const Params = ({ data, dispatch }) => {
  const [ editing, setEditing ] = useState(false);

  const handleAdd= () =>
    dispatch({ type: ADD_PARAM });

  const handleDelete = (index) => {
    dispatch({
      type: DELETE_PARAM,
      value: index
    });

    if ((data.length - 1) === 1) setEditing(false);
  }

  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>
          Params
        </Typography>

        <Stack
          direction="row"
          spacing={1}
        >
          {data.length > 1 &&
            <Button
              startIcon={editing ? <DoneIcon /> : <EditIcon />}
              onClick={() => setEditing(!editing)}
            >
              {editing ? 'Done' : 'Edit'}
            </Button>
          }

          <Button
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      {data.map((param, index) =>
        <Stack
          key={index}
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Param
            index={index}
            data={param}
            dispatch={dispatch}
            sx={{ flex: 1 }}
          />

          {data.length > 1 && editing &&
            <IconButton onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </IconButton>
          }
        </Stack>
      )}
    </React.Fragment>
  );
};

export default Params;
