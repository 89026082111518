import React, { useState, useEffect, useContext } from 'react';
import Firebase from 'firebase/app'
import slugify from 'slugify';

// Data
import { OrganizationContext } from 'stores/organization';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const initialState = () => {
  return {
    title: '',
    slug: '',
  };
};

// It can only contain letters, numbers and '_'
const createNewSlug = title =>
  slugify(title,
    {
      replacement: '',
      lower: true,
      remove: /[^a-zA-Z0-9_.]/
    }
  );

const checkIfSlugAvailable = async (organization, slug) => {
  const query = await Firebase
    .firestore()
    .collection('groups')
    .where("organization", "==", organization)
    .where('slug', '==', slug)
    .get();

  return query.empty;
}

const Form = React.memo(({ id, data, submit, cancel }) => {
  const { organizationId } = useContext(OrganizationContext);
  const [ state, setState ] = useState(initialState());
  const [ slugError, setSlugError ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  // Set data
  useEffect(() => {
    if (data) 
      setState(prevState => ({
        ...prevState,
        ...data,
      }));
  }, []);

  // Observer slug changes
  useEffect(async () => {
    const oldSlug = data
      ? data.slug
      : false;

    const isAvailable = oldSlug === state.slug
      ? true
      : await checkIfSlugAvailable(organizationId, state.slug);

    isAvailable
      ? setSlugError(false)
      : setSlugError(true)
  }, [state.slug]);

  // Handle title change
  const handleTitleChange = (e) => {
    const { value } = e.target;

    setState(prevState => ({
      ...prevState,
      title: value,
      slug: createNewSlug(value),
    }));
  };

  // Handle slug change
  const handleSlugChange = (e) => {
    const { name, value } = e.target;

    setState(prevState => ({
      ...prevState,
      [name]: createNewSlug(value),
    }));
  };

  // Submit changes
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (slugError) return;

    setLoading(true);
    submit(state);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        spacing={2}
        sx={{ padding: (theme) => theme.spacing(2) }}
      >
        <TextField
          name="title"
          label="Title"
          placeholder="Insert a group title"
          value={state.title}
          onChange={handleTitleChange}
          variant="standard"
          required
        /> 

        <TextField
          name="slug"
          label="Slug"
          placeholder="Insert a group slug"
          value={state.slug}
          onChange={handleSlugChange}
          error={slugError}
          helperText={slugError ? 'Not available' : 'It can only contain letters, numbers and "_"'}
          variant="standard"
          required
        /> 

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button
            onClick={cancel}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={loading && <CircularProgress size={16} sx={{ color: '#fff' }} />}
            disabled={slugError}
          >
            {id ? 'Save' : 'Add'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
});

export default Form;
