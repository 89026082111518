import React, { useState } from 'react';
import { analyticsGetBySource } from 'services/analytics';

import { useQuery } from 'react-query';
import { format, subDays } from 'date-fns';

import Card from 'components/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';

const getPercentage = (value, total) => value/total * 100;

const BrowsersStats = React.memo(({ title, column, id, field, from, to }) => {
  const [ total, setTotal ] = useState(0);

  if (!from && !to) {
    const now = new Date();
    from = subDays(now, 30);
    to = now;
  };

  const { isLoading, isError, data } = useQuery(
    [
      `${title}-stats`,
      column,
      id,
      from,
      to,
    ],
    async () => {
      const res = await analyticsGetBySource({
        column: column,
        value: id,
        field: field,
        from: format(from, 'yyyy-MM-dd HH-mm'),
        to: format(to, 'yyyy-MM-dd HH-mm'),
      });

      const totalCount = res.data.reduce((total, entry) => total + entry.value, 0);
      const orderedData = res.data.sort((a, b) => parseInt(b.value) - parseInt(a.value));

      setTotal(totalCount);
          
      return orderedData;
    },
    {
      enabled: !!(column && id && from && to),
    }
  );

  return (
    <Card title={`Visits by ${title}`}>
      {!isLoading
        ? <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '50%', textTransform: 'capitalize' }}>
                    {title}
                  </TableCell>
                  <TableCell sx={{ width: '50%' }}></TableCell>
                </TableRow>
              </TableHead>  

              <TableBody>
                {data && data.length
                  ? data.map((row) => {
                    if (row[field] !== null)
                      return (
                        <TableRow key={row[field]}>
                          <TableCell component="th" scope="row">
                            {row[field]}
                          </TableCell>
                          <TableCell align="right">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography variant="body2">{row.value}</Typography>
                              <LinearProgress variant="determinate" value={getPercentage(row.value, total)} sx={{ width: '100%' }} />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )
                  })
                  : <TableRow>
                      <TableCell component="th" scope="row" colSpan={2} align="center">
                        No data yet
                      </TableCell>
                    </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        : <Stack spacing={1}>
            <Skeleton variant="text" height={20} animation="wave" />
            <Skeleton variant="text" height={20} animation="wave" />
            <Skeleton variant="text" height={20} animation="wave" />
            <Skeleton variant="text" height={20} animation="wave" />
            <Skeleton variant="text" height={20} animation="wave" />
          </Stack>
      }
    </Card>
  );
});

export default BrowsersStats;
