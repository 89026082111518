import React, { useState } from 'react';
import Firebase from 'firebase/app';
import getStripe from '../../Stripe';

import { useCollectionOnce } from 'react-firebase-hooks/firestore';

import Plan from './Plan';
import Prices from './Prices';

import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Plans = ({ user, trial }) => {
  const [ loading, setLoading ] = useState(false);

  const [plans, plansLoading, error] = useCollectionOnce(
    Firebase
      .firestore()
      .collection('products')
      .where('active', '==', true)
  );

  /*
   * priceId (string)
   * trial: (boolean)
   */
  const subscribe = async (priceId, trial) => {
    setLoading(true);
    
    const checkoutSessionRef = await Firebase
      .firestore()
      .collection('users')
      .doc(user)
      .collection('checkout_sessions')
      .add({
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

    checkoutSessionRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();

      if (error) console.log(error);

      if (sessionId) {
        const stripe = await getStripe();
        stripe.redirectToCheckout({ sessionId });
      }
    })
  };

  return (
    <React.Fragment>
      {plansLoading && <p>Loading</p>}

      {!plansLoading && 
        <Stack direction="column" spacing={2}>
          {plans && plans.docs.map((doc) => {
            const plan = doc.data();

            return (
              <Plan plan={plan} key={doc.id}>
                <Prices
                  trial={trial}
                  planId={doc.id}
                  subscribe={subscribe}
                />
              </Plan>
            );
          })}
        </Stack>
      }

     	<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> 
    </React.Fragment>
  );
};

export default Plans;
