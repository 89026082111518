import Firebase from 'firebase/app'

const deleteLink = async (linkId, linkData) => {

  // If link is redirect, remove default url ref
  if (linkData.default) {
    if (linkData.default.type !== "direct")
      Firebase
        .firestore()
        .collection('links')
        .doc(linkData.default.url)
        .update({
          refsFrom: Firebase.firestore.FieldValue.arrayRemove(linkId)
        })
  };

  // Remove references from redirects/splits/choices
  if (linkData.items)
    await Promise.all(linkData.items.map((items) => {
      if (items.type !== 'direct')
        return Firebase
          .firestore()
          .collection('links')
          .doc(items.url)
          .update({
            refsFrom: Firebase.firestore.FieldValue.arrayRemove(linkId)
          });
    }));

  // Delete
  return Firebase
    .firestore()
    .collection('links')
    .doc(linkId)
    .delete()
    .catch( error => {
      alert(`Whoops, couldn't delete the link: ${error.message}`)
    })
}

export default deleteLink
