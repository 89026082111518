import React, { useContext, useState } from 'react';
import RedirectContext from './Context';

// Components
import Title from 'forms/components/Title';
import DefaultDestination from './Components/DefaultDestination';
import Redirects from './Components/Redirects';
import Params from 'forms/components/Params';
import Groups from 'forms/components/Groups';
import UTM from 'forms/components/UTM';

// UI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const Form = ({ submit, cancel }) => {
  const { state, dispatch } = useContext(RedirectContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    submit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Title
          data={state.title}
          dispatch={dispatch}
        />

        <DefaultDestination
          type={state.default.type}
          url={state.default.url}
          dispatch={dispatch}
        />
        
        <Redirects
          redirects={state.items}
          dispatch={dispatch}
        />

        <UTM
          data={state.utm}
          dispatch={dispatch}
        />

        <Params
          data={state.params}
          dispatch={dispatch}
        />

        <Groups
          data={state.groups}
          dispatch={dispatch}
        />

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={cancel}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            // disabled={JSON.stringify(data) === JSON.stringify(state)}
            startIcon={loading && <CircularProgress sx={{color: '#fff'}} size={20} />}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default Form
