import React from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Link from 'components/Link';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';

import { EDIT_CHOICE, DELETE_CHOICE } from '../Constants';

// TODO: improve comparison of data
const arePropsEqual = (prev, next) =>
  prev.id === next.id &&
  prev.data === next.data &&
  prev.editing === next.editing;

const Choice = ({ id, data, editing, dispatch }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dispatchAction = (field, value) =>
    dispatch({
      type: EDIT_CHOICE,
      value: {
        id,
        field: field,
        value: value
      }
    });

  const handleTitleChange = (e) =>
    dispatchAction('title', e.target.value);

  const handleLinkChange = (link) => {
    dispatchAction('url', link.url);
    dispatchAction('type', link.type);
  };

  const handleLinkDelete = () =>
    dispatch({
      type: DELETE_CHOICE,
      value: id,
    })

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      ref={setNodeRef}
      style={style}
    >
      {editing &&
        <IconButton {...attributes} {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      }

      <Stack
        component={Paper}
        spacing={2}
        alignItems="flex-end"
        sx={{ flex: 1, padding: (theme) => theme.spacing(2) }}
      >
        <TextField
          label="Title"
          name="title"
          value={data.title}
          onChange={handleTitleChange}
          variant="standard"
          fullWidth
          required
        />

        <Link
          type={data.type}
          url={data.url}
          save={handleLinkChange}
        />
      </Stack>

      {editing &&
        <IconButton onClick={handleLinkDelete}>
          <DeleteIcon />
        </IconButton>
      }
    </Stack>
  );
};

export default Choice;
