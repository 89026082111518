import { nanoid } from 'nanoid';

const param = () => {
  return {
    key: '',
    value: '',
  }
}

const initialState = () => {
  return {
    id: nanoid(6),
    title: '',
    hostname: '',
    params: [
      param(),
    ],
  }
};

export { initialState, param };
