import React, { useContext } from 'react';

import { OrganizationContext } from 'stores/organization';
import { GroupsContext } from 'stores/groups';
import { UTMContext } from 'stores/utms';
import { ParamsContext } from 'stores/params';
import { LinksContext } from 'stores/links';

import CircularProgress from '@mui/material/CircularProgress';

const OrganizationLoading = ({ children }) => {
  const { organizationLoading } = useContext(OrganizationContext);
  const { groupsLoading } = useContext(GroupsContext);
  const { utmsLoading } = useContext(UTMContext);
  const { paramsLoading } = useContext(ParamsContext);
  const { linksLoading } = useContext(LinksContext);

	if (organizationLoading || groupsLoading || utmsLoading || paramsLoading || linksLoading)
		return (
      <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </div>
    );

  return children
};

export default OrganizationLoading;
