import react, { useState, useContext } from 'react';
import Firebase from 'firebase/app';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { UserContext } from 'stores/user';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

const Invite = ({ id, data }) => {
  const { userId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [user, userLoading, userError] = useDocumentOnce(
    Firebase
      .firestore()
      .collection('users')
      .doc(data.invitedBy)
  );

  const [organization, organizationLoading, organizationError] = useDocumentOnce(
    Firebase
      .firestore()
      .collection('organizations')
      .doc(data.organization)
  );

  const accept = () => {
    setLoading(true);

    Firebase
      .firestore()
      .collection('organizations')
      .doc(data.organization)
      .set({
        roles: {
          [userId]: data.role,
        },
      }, { merge: true })
      .then(() => deleteInvitation());
  };

  const refuse = () => {
    setLoading(true);
    deleteInvitation();
  }

  const deleteInvitation = () =>
    Firebase
      .firestore()
      .collection('invites')
      .doc(id)
      .delete();

  return (
    <Card key={id}>
      {loading &&
        <CardContent>
          <CircularProgress />
        </CardContent>
      }

      {!loading &&
        <>
          {userLoading && organizationLoading &&
            <CardContent>
              <CircularProgress />
            </CardContent>
          }

          {userError && organizationError &&
            <CardContent>
              <Typography variant="body1" component="p">
                Something went wrong while loading, try again later.
              </Typography>
            </CardContent>
          }

          {!userLoading && !organizationLoading && user && organization &&
            <>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {data.createdOn.toDate().toDateString()}
                </Typography>

                  <Typography variant="body1" component="p">
                    You have been invited by {user.data().email} to his {organization.data().title} organization as {data.role}.
                  </Typography>
              </CardContent>

              <Divider />

              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckCircleIcon />}
                  size="small"
                  onClick={accept}
                >
                  Accept
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  size="small"
                  onClick={refuse}
                >
                  Refuse
                </Button>
              </CardActions>
            </>
          }
        </>
      }
    </Card>
  )
};

export default Invite;
