import React, { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';

/*
 * todo
 * - check url validity
 * - show error when added protocol
 */

const Referer = React.memo(({ target, save }) => {
  const [ state, setState ] = useState(target.values ? target.values : ['']);

  useEffect(() => {
    save({
      values: [
        ...state,
      ],
    });
  }, [state]);

  const handleChange = (value, index) => {
    const newState = [...state];
    newState[index] = value;

    setState(newState);
  };

  const add = () =>
    setState(prevState => {
      return [
        ...prevState,
        '',
      ]
    });

  const remove = (index) =>
    setState(prevState => {
      return [
        ...prevState.filter((el, i) => i !== index)
      ]
    });

  return (
    <Stack spacing={2} alignItems="center">
      {state.map((referer, index) =>
        <Stack key={index} direction="row" spacing={1} sx={{ width: '100%' }}>
          <TextField
            variant="standard"
            placeholder="website.com"
            value={referer}
            onChange={(e) => handleChange(e.target.value, index)}
            fullWidth
            required
          />

          {state.length !== 0 &&
            <IconButton onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          }
        </Stack>
      )}

      <Button
        onClick={add}
        variant="outlined"
        startIcon={<AddBoxIcon />}
        size="small"
      >
        OR Another Referer
      </Button>
    </Stack>
  );
});

export default Referer;
