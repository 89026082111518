import React, { useContext } from 'react';
import { Outlet } from "react-router";
import { useRoutes, Navigate } from 'react-router-dom';

// Data
import { UserContext } from 'stores/user';
import { SubscriptionContext } from 'stores/subscription';
import { UsageContext } from 'stores/usage';

// Utilities
import { isActive } from 'utilities/user';

// Account
// User import SignUp from './SignUp';
import SignIn from './SignIn';
import Home from './Home';
import Account from './Account/Index';
import Profile from './Account/Profile';
import Delete from './Account/Delete';
import Subscription from './Account/Subscription/Index';
import Usage from './Account/Usage';

// Organization
import Organization from './organizations/Index';
import OrganizationNew from './organizations/OrganizationNew';
import OrganizationDashboard from './organizations/OrganizationDashboard';
import OrganizationSettings from './organizations/settings/index';
import OrganizationSettingsDelete from './organizations/settings/Delete';
import Leave from './organizations/settings/Leave';

import LinksPage from './Links';
import Search from './Search';
import GroupsPage from './Groups/Page';
import GroupPage from './Group';
import GroupAnalyticsPage from './Group/Analytics';
import GroupLinksPage from './Group/Links';
import AnalyticsPage from './Analytics/Page';

import GeneralSettings from './Settings/General/Page';
import MembersPage from './Settings/Members/Index';
import InviteMember from './Settings/Members/InviteMember';
import AffiliatesPage from './Settings/Affiliates/Page';
import UTMPage from './Settings/UTMs/Page';
import ParamsPage from './Settings/Params/Page';

// Link
import LinkPage from './link/Page';
import LinkNew from './link/New';
import LinkEdit from './link/Edit';

const isHavingOrganizations = (organizationsLimit, usedOrganizations) => usedOrganizations < organizationsLimit;
const isHavingMembers = (membersLimit, usedMembers) => usedMembers < membersLimit;

const routes = (isLoggedIn, subscription, limits,  usage) => [
	{ path: 'signin', element: isLoggedIn ? <Navigate to="/" /> : <SignIn /> },
	{ path: 'signup', element: isLoggedIn ? <Navigate to="/"  /> : <SignIn /> },
	{
		element: isLoggedIn ? <Outlet /> : <Navigate to="/signin" />,
		children: [
			{ path: '/', element: <Home /> },
			{ path: 'new', element: isActive(subscription) && isHavingOrganizations(limits.organizations, usage.organizations) ? <OrganizationNew /> : <Navigate to='/account/subscription' /> },
			{
				path: 'account',
				element: <Account />,
				children: [
					{ path: 'profile', element: <Profile /> },
					{ path: 'subscription', element: <Subscription /> },
					{ path: 'usage', element: <Usage /> },
					{ path: 'delete', element: <Delete /> },
				],
			},
			{
				path: ':slug',
				element: <Organization />,
				children: [
					{ path: '', element: <OrganizationDashboard /> },
					{ path: 'new', element: <LinkNew /> },
					{ path: ':id/edit', element: <LinkEdit /> },
					{ path: ':id', element: <LinkPage /> },
					{ path: 'links', element: <LinksPage /> },
					{ path: 'groups', element: <GroupsPage /> },
					{
						path: 'groups/:id',
						element: <GroupPage />,
						children: [
							{ path: 'analytics', element: <GroupAnalyticsPage /> },
							{ path: 'links', element: <GroupLinksPage /> },
						],
					},
					{ path: 'search', element: <Search /> },
					{ path: 'analytics', element: <AnalyticsPage /> },
					{
						path: 'settings',
						element: <OrganizationSettings />,
						children: [
							{ path: 'general', element: <GeneralSettings /> },
							{ path: 'members', element: <MembersPage /> },
							{ path: 'members/invite', element: isActive(subscription) && isHavingMembers(limits.members, usage.members) ? <InviteMember /> : <Navigate to='/account/subscription' /> },
							{ path: 'affiliates', element: <AffiliatesPage /> },
							{ path: 'utm', element: <UTMPage /> },
							{ path: 'params', element: <ParamsPage /> },
							{ path: 'delete', element: <OrganizationSettingsDelete /> },
							{ path: 'leave', element: <Leave /> },
						],
					},
				],
			},
		],
	},
];

const Routes = () => {
  const { userData } = useContext(UserContext);
  const { subscriptionData, subscriptionLimits } = useContext(SubscriptionContext);
  const { usageData } = useContext(UsageContext);

	const routesMap = useRoutes(routes(userData, subscriptionData, subscriptionLimits, usageData));

	return routesMap;
}

export default Routes;
