import React from 'react';
import { EDIT_TITLE } from '../Constants';
import TextField from '@mui/material/TextField';

const Title = ({ data, dispatch }) => {

  const handleChange = ({ target }) =>
    dispatch({
      type: EDIT_TITLE,
      value: target.value,
    });

  return (
    <TextField
      name="title"
      label="Title"
      value={data}
      onChange={handleChange}
      variant="standard"
      fullWidth
      required
    />
  );
};

export default Title;
