import React from 'react';

// UI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const Param = ({ index, data, change, disabled }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;

    change({
      ...data,
      [name]: value,
    }, index);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ flex: 1 }}
    >
      <TextField
        name="key"
        placeholder="Key"
        value={data.key}
        onChange={handleChange}
        disabled={disabled}
        variant="standard"
        fullWidth
        required
      />

      <TextField
        name="value"
        placeholder="Value"
        value={data.value}
        onChange={handleChange}
        disabled={disabled}
        variant="standard"
        fullWidth
        required
      />
    </Stack>
  );
};

export default Param;
