import React, { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Firebase from 'firebase/app';
import QRCode from "react-qr-code";
import { format } from 'date-fns'

import { HeaderContext } from 'stores/header';
import { LinksContext } from 'stores/links';

import LinksRefs from 'components/LinksRefs';
import StatsRange from 'components/StatsRange';
import ClickStatsCard from 'components/cards/ClickStatsCard';
import CountryStats from 'components/cards/CountryStats';
import SourceStats from 'components/cards/SourceStats';
import CopyLinkToClipboard from 'components/CopyLinkToClipboard';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';

const LinkPage = () => {
  const { linksLoading, linksList } = useContext(LinksContext);
  const { setHeader } = useContext(HeaderContext);

  const [ link, setLink ] = useState();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const { id } = useParams();

  useEffect(async () => {
    if (id && linksList.length) {
      const linkIndex = linksList.findIndex(doc => doc.id === id);

      const linkData = linkIndex !== -1
        ? linksList[linkIndex]
        : await Firebase
            .firestore()
            .collection('links')
            .doc(id)
            .get()
            .then((snapshot) => snapshot);

      setLink(linkData);
    }
  }, [id, linksLoading]);

  useEffect(() => {
    setHeader({ title: link ? id : 'Loading data' }) 
  }, [link]);

  return (
		<React.Fragment>
      {!link
        ? <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        : <Box sx={{ padding: '1rem' }}>
            <Stack spacing={2}>

              <Container maxWidth="sm">
                <Stack direction="row">
                  <Box
                    sx={{
                      display: {
                        'xs': 'none',
                        'sm': 'block',
                      },
                      marginRight: (theme) => theme.spacing(2)
                    }}
                  >
                    <QRCode value={`https://lynky.to/${id}`} />
                  </Box>
                  
                  <Stack spacing={1} sx={{ flex: 1 }}>
                    <Typography variant="h4">
                      {link.data().title}
                    </Typography>

                    <Stack direction="row" alignItems="center">
                      <Typography color="text.secondary" variant="body2"> 
                        Link ID:
                      </Typography>
                      
                      <CopyLinkToClipboard link={id} />
                    </Stack>

                    <Typography color="text.secondary" variant="body2"> 
                      Type: {link.data().type}
                    </Typography>

                    <Typography color="text.secondary" variant="body2"> 
                      Created on: {format(link.data().createdOn.toDate(), "dd MMM yyyy 'at' HH:mm")}
                    </Typography>

                    {link.data().updatedOn &&
                      <Typography color="text.secondary" variant="body2"> 
                        Last update: {format(link.data().updatedOn.toDate(), "dd MMM yyyy 'at' HH:mm")}
                      </Typography>
                    }

                    {link.data().refsFrom && link.data().refsFrom.length
                      ? <Stack direction="row">
                          <Typography
                            color="text.secondary"
                            variant="body2"
                          > 
                            Used by: &nbsp;
                          </Typography>

                          <LinksRefs data={link.data().refsFrom}>
                            <Typography
                              color="text.secondary"
                              variant="body2"
                            > 
                              {link.data().refsFrom.length} links
                            </Typography>
                          </LinksRefs>
                        </Stack>
                      : null
                    }

                    <Button
                      component={Link}
                      to="edit"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      size="small"
                      fullWidth
                    >   
                      Edit
                    </Button>
                  </Stack>
                </Stack>
              </Container>
    
              <Stack
                direction={{
                  xs: "column",
                  sm: "row"
                }}
                justifyContent={{
                  xs: "center",
                  sm: "space-between"
                }}
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h5"> 
                  Analytics
                </Typography>

                <StatsRange
                  from={setFrom}
                  to={setTo}
                  createdOn={link.data().createdOn}
                />
              </Stack>

              <Divider />

              <Card variant="outlined" sx={{ bgcolor: grey[50] }}>
                <CardHeader title="Clicks" />

                <CardContent>
                  <ClickStatsCard
                    column="link"
                    id={link.id}
                    from={from}
                    to={to}
                  />
                </CardContent>
              </Card>

              <CountryStats
                column="link"
                id={link.id}
                from={from}
                to={to}
              />

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <SourceStats
                  title="referers"
                  column="link"
                  field="referer"
                  id={link.id}
                  from={from}
                  to={to}
                />

                <SourceStats
                  title="browsers"
                  column="link"
                  field="browser"
                  id={link.id}
                  from={from}
                  to={to}
                />
              </Stack>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <SourceStats
                  title="device types"
                  column="link"
                  field="device"
                  id={link.id}
                  from={from}
                  to={to}
                />

                <SourceStats
                  title="operating systems"
                  column="link"
                  field="os"
                  id={link.id}
                  from={from}
                  to={to}
                />
              </Stack>
            </Stack>
          </Box>
      }
		</React.Fragment>
  );
};

export default LinkPage;
