import react, { useState } from 'react';
import Firebase from 'firebase/app'

// Components
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

const MemberRole = ({ organizationId, userId, userRole, disabled }) => {
  const [newRole, setNewRole] = useState(userRole);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const openDialog = (e) => {
    setNewRole(e.target.value);
    setConfirmOpen(true)
  };

  const updateRole = () => {
    setLoading(true);

    Firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .set({
        roles: {
          [userId]: newRole
        }
      }, { merge: true })
      .then(() => setLoading(false));
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Select
          id={`role-${userId}`}
          value={userRole}
          onChange={openDialog}
          style={{flexGrow: 1}}
          variant="standard"
          disabled={disabled}
          required
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="editor">Editor</MenuItem>
          <MenuItem value="reader">Reader</MenuItem>
        </Select>

        {loading &&
          <CircularProgress
            size={18}
            style={{marginLeft: 10}}
          />
        }
      </Grid>

      <ConfirmDialog
        title="Change user role"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={updateRole}
      >
       Are you sure you want to change role to {newRole}?
      </ConfirmDialog>
    </>
  )
};

export default MemberRole;
