import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom'
import Firebase from 'firebase/app';

// Components
import LinksList from 'components/LinksList';

// UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const ITEMS = 10;

const GroupLinksPage = () => {
  const { group } = useOutletContext();

  const [ loading, setLoading ] = useState(true);
  const [ hasMore, setHasMore ] = useState(false);
  const [ links  , setLinks   ] = useState([]);

  const loadLinks = () =>
    Firebase
      .firestore()
      .collection('links')
      .where('groups', 'array-contains', group.id)
      .orderBy('createdOn', 'desc')
      .startAfter(links.length ? links[links.length - 1] : '')
      .limit(ITEMS)
      .get()
      .then((res) => {
        res.docs.length < ITEMS
          ? setHasMore(false)
          : setHasMore(true);

        setLinks(prevState => ([
          ...prevState,
          ...res.docs,
        ]))

        setLoading(false);
      });

  useEffect(() => loadLinks(), []);

  if (loading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <CircularProgress />
      </Box>
    )

  return (
    <Stack
      spacing={2}
      alignItems="center"
    >
      <LinksList
        data={links}
      />

      {hasMore &&
        <Button
          onClick={loadLinks}
          variant="contained"
        >
          Load more
        </Button>
      }
    </Stack>
  );
};

export default GroupLinksPage;
