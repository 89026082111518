import { arrayMove } from "@dnd-kit/sortable";
import { item } from './Model';

import {
  SET_STATE,

  SET_FIELD,
  DELETE_FIELD,

  EDIT_AVATAR,
  DELETE_AVATAR, 

  EDIT_BACKGROUND,
  DELETE_BACKGROUND,

  ADD_CHOICE,
  EDIT_CHOICE,
  MOVE_CHOICE,
  DELETE_CHOICE,
} from './Constants';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return {
        ...action.value,
      };

    case SET_FIELD: {
      const { field, value } = action.value;
      const copy = { ...state };
      copy[field] = value;

      return {
        ...copy,
      };
    };

    case DELETE_FIELD: {
      const field = action.value;
      const copy = {...state}

      delete copy[field];

      return {
        ...copy,
      };
    };

    case EDIT_AVATAR: {
      return {
        ...state,
        avatar: {
          ...action.value,
        },
      };
    };

    case DELETE_AVATAR: {
      return {
        ...state,
        avatar: {
          type: '',
          value: '',
        },
      };
    };

    case EDIT_BACKGROUND: {
      return {
        ...state,
        background: {
          ...action.value,
        },
      };
    };

    case DELETE_BACKGROUND: {
      return {
        ...state,
        background: {
          type: 'color',
          value: '#ffffff',
        },
      };
    };

    case ADD_CHOICE: {
      return {
        ...state,
        items: [
          item(),
          ...state.items,
        ],
      };
    };

    case EDIT_CHOICE: {
      const { id, field, value } = action.value;
      const items = state.items.map(item => ({...item}));
      const index = items.findIndex(choice => choice.id === id);
      items[index][field] = value;

      return {
        ...state,
        items: [
          ...items,
        ],
      }
    };

    case MOVE_CHOICE: {
      const { active, over } = action.value;

      if (active.id !== over.id) {
        const oldIndex = state.items.findIndex(item => item.id === active.id);
        const newIndex = state.items.findIndex(item => item.id === over.id);
        const newOrder = arrayMove(state.items, oldIndex, newIndex);

        return {
          ...state,
          items: [
            ...newOrder
          ],
        };
      }
    }

    case DELETE_CHOICE: {
      const id = action.value;

      return {
        ...state,
        items: [
          ...state.items.filter((choice) => choice.id !== id),
        ]
      }
    };
  };
};

export default Reducer;
