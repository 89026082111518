import React, { useState } from 'react';
import { functions } from 'utilities/firebase';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BillingPortal = () => {
  const [ loading, setLoading ] = useState(false);

  const openPortal = async () => {
    setLoading(true);

    const functionRef = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });

    window.location.assign(data.url);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={openPortal}
        fullWidth
      >
        Update Subscription
      </Button>

     	<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> 
    </React.Fragment>
  );
};

export default BillingPortal;
