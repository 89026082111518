import React, { useContext } from 'react';
// import QRCode from "react-qr-code";

import { UserContext } from 'stores/user';
import { SubscriptionContext } from 'stores/subscription';
import { OrganizationContext } from 'stores/organization';

import { validateURLprotocol } from 'utilities/url';
import { isActive } from 'utilities/user';
import { isOwner } from 'utilities/member';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { Link as ExternalLink } from '@mui/material';

const formatUrl = (link) => {
  const nodeEnv = process.env.NODE_ENV;
  const appEnv = process.env.REACT_APP_ENV;

  if (nodeEnv === 'production') {
    switch (appEnv) {
      case 'live':
        return `https://lynky.to/${link}`;
      case 'stage':
        return `https://stage.lynky.to/${link}`;
      case 'dev':
        return `https://dev.lynky.to/${link}`;
    }
  } else {
    return `http://localhost:8080/${link}`;
  };
};

const Info = () => {
  const { userId } = useContext(UserContext);
  const { subscriptionData } = useContext(SubscriptionContext);
  const { organizationData } = useContext(OrganizationContext);
  const organizationUrl = formatUrl(organizationData.slug);

  return (
    <React.Fragment>
        <Typography variant="h5" component="h1">
          {organizationData.title}
        </Typography>

        {organizationData.website &&
          <Typography variant="subtitle2" component="p">
            <ExternalLink
              href={validateURLprotocol(organizationData.website)}
              target="_blank"
              rel="noopener"
            >
              {organizationData.website}
            </ExternalLink>
          </Typography>
        }

        <Typography variant="subtitle2" component="p" gutterBottom>
          <ExternalLink
            href={validateURLprotocol(organizationUrl)}
            target="_blank"
            rel="noopener"
          >
            {organizationUrl}
          </ExternalLink>
        </Typography>

        <Grid container gap={1}>
          {isOwner(organizationData.roles[userId]) && !isActive(subscriptionData) &&
            <Chip label="Inactive"
                  size="small"
                  color="secondary"
            />
          }

          <Chip label={organizationData.roles[userId]}
                size="small"
                color="primary"
          />
        </Grid>
    </React.Fragment>
  );
};

export default Info;
