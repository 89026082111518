import {
  SET_STATE,

  SET_FIELD,
  DELETE_FIELD,
} from './Constants';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE: {
      return {
        ...action.value,
      };
    };

    case SET_FIELD: {
      const { field, value } = action.value;
      const copy = { ...state };
      copy[field] = value;

      return {
        ...copy,
      };
    };

    case DELETE_FIELD: {
      const field = action.value;
      const copy = {...state}

      delete copy[field];

      return {
        ...copy,
      };
    };

    default:
      return state;
  }
};

export default Reducer;
