import React from 'react';

// Components
import GroupsSelect from 'components/GroupsSelect';

// UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Groups = ({ data, dispatch }) => {

  const handleChange = (values) =>
    dispatch({
      type: 'SET_FIELD',
      value: {
        field: 'groups',
        value: values,
      },
    })

  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Typography variant="h6" component="p">
        Groups:
      </Typography>

      <GroupsSelect
        data={data}
        change={handleChange}
      />
    </Stack>
  );
};

export default Groups;
