import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CountryRegionData from 'country-region-data/dist/data-umd';

const Country = React.memo(({ target, save }) => {

  const handleChange = (e, selected) => {
    const codes = selected.reduce((acc, el) => {
      acc.push(el.countryShortCode ? el.countryShortCode : el);

      return acc;
    }, []);

    save({
      values: [
        ...codes
      ]
    });
  };

  return (
    <Autocomplete
      multiple
      limitTags={3}
      filterSelectedOptions
      disableClearable={true}
      value={target.values ? target.values : []}
      onChange={handleChange}
      options={CountryRegionData}
      getOptionLabel={(option) => option.countryName}
      isOptionEqualToValue={(option, value) => option.countryShortCode === value}
      renderTags={(tags, props) =>
        tags.map((tag, index) => {
          const countryIndex = CountryRegionData.findIndex(country => country.countryShortCode === tag);

          return (
            <Chip
              {...props({ index })}
              label={CountryRegionData[countryIndex].countryName}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            required: target.values ? target.values.length === 0 : true
          }}
          required
        />
      )}
    />
  );
});

export default Country;
