import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationContext } from 'stores/organization';

import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const LinksRefs = ({ data, children }) => {
  const { organizationData } = useContext(OrganizationContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <div onClick={handleClick}>
        {children}
      </div>
    
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography gutterBottom>
            This link is used in:
          </Typography>

          <Stack spacing={1}>
            {data.map((ref, index) =>
              <Link
                key={index}
                to={`/${organizationData.slug}/${ref}`}
              >
                {ref}
              </Link>
            )}
          </Stack>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default LinksRefs;
