import React from 'react';
import { analyticsGetCount } from 'services/analytics';
import { useQuery } from 'react-query';
import { format, parse, differenceInDays, subHours, subDays, addDays, subMonths, isSameHour, isSameDay, isSameMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const getFormat = (range) => {
  if (range === 1) return 'dd-MM-yyyy HH:00';
  if (range <= 30) return 'dd-MM-yyyy';
  if (range <= 365) return 'MM-yyyy';

  return 'yyyy';
};

const formatXAxisTicker = (range, date) => {
  if (range === 1) return format(date, 'HH:00')
  if (range <= 30) return format(date, 'eee dd')
  if (range <= 365) return format(date, 'MMM')

  return format(date, 'yyyy')
}

const formatTooltipDate = (range, date) => {
  if (range === 1) return format(date, 'HH:00')
  if (range <= 30) return format(date, 'MMM dd yyyy')
  if (range <= 365) return format(date, 'MMM yyyy')

  return format(date, 'yyyy')
}

const getHourlyTimeline = () => {
  const now = new Date();
  const result = [];

  for(var i = 0; i < 24; i++) {
    result.unshift({
      date: subHours(now, i),
      value: 0
    });
  };

  return result;
};

const getDailyTimeline = (from, to) => {
  const diff = differenceInDays(to, from);
  const result = [];

  for(var i = 0; i <= diff; i++) {
    result.push({
      date: addDays(from, i),
      value: 0
    });
  };

  return result;
};

// Generate 12 months timeline
const getMonthlyTimeline = () => {
  const today = new Date();
  const result = [];

  for(var i = 0; i < 12; i++) {
    result.unshift({
      date: subMonths(today, i),
      value: 0
    });
  };

  return result;
};

const generateTimeline = (range, from, to) => {
  if (range === 1) return getHourlyTimeline();
  if (range <= 30) return getDailyTimeline(from, to);
  if (range <= 365) return getMonthlyTimeline();
  // Add year
}

const getDateValidator = (range) => {
  if (range === 1) return isSameHour;
  if (range <= 30) return isSameDay;
  if (range <= 365) return isSameMonth;
  // Add year
}

const parseData = (range, timeline, data) => {
  const dateFormat = getFormat(range);
  const validator = getDateValidator(range);

  data.forEach(row => {
    // UTC Date will be converted in date object
    // 8:00 UTC will be 8:00 +Timezone
    // It will not add/sub hours
    const parsedUTCDate = parse(row.date, dateFormat, new Date());

    // Hack: Convert parsed UTC to UTC
    const date = zonedTimeToUtc(
      parsedUTCDate,
      "yyyy-MM-dd kk:mm:ss xxx",
      {
        timeZone
      }
    );

    // TODO: Convert UTC to local directly

    timeline.map((entry) => validator(entry.date, date) ? entry.value = row.value : null);
  });

  return timeline;
};


const ClickStatsCard = React.memo(({ column, id, from, to }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!from && !to) {
    const now = new Date();
    from = subDays(now, 30);
    to = now;
  };

  const range = differenceInDays(to, from);
  const timeline = generateTimeline(range, from, to);

  const { isLoading, isError, data } = useQuery(
    [
      'clickstats',
      column,
      id,
      range,
    ],
    async () => {
      const res = await analyticsGetCount({
        column: column,
        value: id,
        groupby: range,
        from: format(from, 'yyyy-MM-dd HH-mm'),
        to: format(to, 'yyyy-MM-dd HH-mm'),
      })

      return parseData(range, timeline, res.data)
    },
    {
      enabled: !!(column && id && range && from && to),
      placeholderData: timeline,
    }
  );

  return (
    <ResponsiveContainer
      width="100%"
      height={isMobile ? 200 : 400}
    >
      <BarChart
        width="100%"
        data={data ? data : timeline}
      >
        <YAxis
          width={20}
          tick={{fontSize: 14}}
          hide={isMobile}
        />

        <XAxis
          name="Date"
          dataKey="date"
          tickFormatter={v => formatXAxisTicker(range, v)}
          tick={{fontSize: 14}}
        />

        <Tooltip
          labelFormatter={v => formatTooltipDate(range, v)}
        />

        <Bar
          name="Clicks"
          dataKey="value"
          fill={blue[500]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
});

export default ClickStatsCard;
