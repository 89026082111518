import React, { useState } from 'react';
import Firebase from 'firebase/app';

// Components
import UTM from './UTM';
import Card from './Card';
import ConfirmDialog from 'components/ConfirmDialog';

const List = ({ data }) => {
  const [ formOpen, setFormOpen ] = useState(false);
  const [ formId, setFormId ] = useState();
  const [ formData, setFormData ] = useState();

  const [ removeId, setRemoveId ] = useState();
  const [ confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

  const handleCancel = () => setFormOpen(false);
  const handleSubmit = () => setFormOpen(false);

  const handleEdit = (item) => {
    setFormId(item.id);
    setFormData(item.data());

    setFormOpen(true);
  };

  const confirmRemove = (item) => {
    setRemoveId(item.id);
    setConfirmRemoveOpen(true);
  };

  const handleRemove = () =>
    Firebase
      .firestore()
      .collection('utms')
      .doc(removeId)
      .delete()

  return (
    <>
      {data.map((item) =>
        <Card
          key={item.id}
          data={item}
          edit={handleEdit}
          remove={confirmRemove}
        />
      )}

      <UTM
        open={formOpen}
        id={formId}
        data={formData}
        submit={handleSubmit}
        cancel={handleCancel}
      />

      <ConfirmDialog
        title="Remove UTM params?"
        open={confirmRemoveOpen}
        setOpen={setConfirmRemoveOpen}
        onConfirm={handleRemove}
      >
       Are you sure you want to remove this UTM params? 
      </ConfirmDialog>
    </>
  );
};

export default List;
