import React, { useState, useContext } from 'react';
import ProgramContext from './Context';

import Programs from './Components/Programs/Index';
import Title from './Components/Title';
import Hostname from './Components/Hostname';
import Params from './Components/Params';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const Form = ({ data, submit, cancel }) => {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(ProgramContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    submit();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        spacing={2}
        sx={{ padding: (theme) => theme.spacing(2) }}
      >
        {!data && 
          <Programs data={state} dispatch={dispatch} />
        }

        <Title data={state.title} dispatch={dispatch} />
        <Hostname data={state.hostname} dispatch={dispatch} />
        <Params data={state.params} dispatch={dispatch} />
    
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button
            onClick={cancel}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={loading && <CircularProgress size={16} sx={{ color: '#fff' }} />}
          >
            {data ? 'Save' : 'Add'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;
