import React, { useContext } from 'react';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

// Components
import Affiliate from './Affiliate/Index';
import List from './List';

// UI
import Title from 'components/Title';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const AffiliatesPage = () => {
  const { organizationLoading, organizationData, organizationId } = useContext(OrganizationContext);
  const { userId } = useContext(UserContext);

  const hasPrivileges = () => ['owner'].includes(organizationData.roles[userId]);

  if (organizationLoading) return <p>Loading</p>;

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>
          Affiliate Programs
        </Title>
        
        {hasPrivileges() &&
          <Affiliate organization={organizationId} />
        }
      </Stack>

      {organizationData.affiliates.length
        ? <List
            organization={organizationId}
            data={organizationData.affiliates}
            hasPrivileges={hasPrivileges}
          />
        : <Alert severity="info">
            You have not added any programs yet
          </Alert>
      }
    </Stack>
  )
}

export default AffiliatesPage;
