import React, { useContext } from 'react';
import Firebase from 'firebase/app'

// Actions
import {
  prepareDocForCreate,
  prepareDocForUpdate,
} from 'actions/helpers/firestoreHelpers';

// Data
import { OrganizationContext } from 'stores/organization';

// Components
import Modal from 'components/Modal';
import Form from './Form';

// UI

const GroupForm = ({ open, id, data, submit, cancel }) => {
  const { organizationId } = useContext(OrganizationContext);

  const add = async (dataObj) => {
    const readyObject = prepareDocForCreate({
      ...dataObj,
      organization: organizationId,
    });

    return Firebase
      .firestore()
      .collection('groups')
      .add(readyObject)
      .then((res) => submit(res));
  };

  const update = async (dataObj) => {
    const readyObject = prepareDocForUpdate(dataObj);

    return Firebase
      .firestore()
      .collection('groups')
      .doc(id)
      .set(readyObject)
      .then(() => submit());
  };

  const handleSubmit = async (dataObj) =>
    id
      ? await update(dataObj)
      : await add(dataObj);

  return (
    <Modal
      isOpen={open}
      handleClose={cancel}
      title={id ? 'Edit group' : 'Add group'}
    >
      <Form
        id={id}
        data={data}
        submit={handleSubmit}
        cancel={cancel}
      />
    </Modal>
  );
};

export default GroupForm;
