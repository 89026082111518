import React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const LinkTypeSelect = React.memo(({ value, change }) =>
  <FormControl
    variant="standard"
    fullWidth
  >
    <InputLabel id="type-label">
      Type
    </InputLabel>

    <Select
      name="type"
      labelId="type-label"
      id="type-select"
      value={value}
      onChange={change}
      label="Type"
    >
      <MenuItem value="direct">Direct</MenuItem>
      <MenuItem value="short">Short</MenuItem>
      <MenuItem value="redirect">Redirect</MenuItem>
      <MenuItem value="choice">Choice</MenuItem>
      <MenuItem value="split">Split A/B</MenuItem>
    </Select>
  </FormControl>
);

export default LinkTypeSelect;
