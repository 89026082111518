import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ContinentList from './ContinentList';

const Continent = React.memo(({ target, save }) => {

  const handleChange = (e, selected) => {
    const codes = selected.reduce((acc, el) => {
      acc.push(el.code ? el.code : el);

      return acc;
    }, []);

    save({
      values: [
        ...codes
      ]
    });
  }

  return (
    <Autocomplete
      multiple
      limitTags={3}
      filterSelectedOptions
      disableClearable={true}
      value={target.values ? target.values : []}
      onChange={handleChange}
      options={ContinentList}
      isOptionEqualToValue={(option, value) => option.code === value}
      renderTags={(tags, props) =>
        tags.map((tag, index) => {
          const continentIndex = ContinentList.findIndex(continent => continent.code === tag);

          return (
            <Chip
              {...props({ index })}
              label={ContinentList[continentIndex].label}
            />
          );
        })
      }
      renderInput={(params) =>
        <TextField
          {...params}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            required: target.values ? target.values.length === 0 : true
          }}
          required
        />
      }
    />
  );
});

export default Continent;
