const Fields = [
  {
    id: 'utm_id',
    name: 'utm_id',
    title: 'Campaign ID',
    placeholder: 'The ads campaign id',
    required: false,
  },
  {
    id: 'utm_source',
    name: 'utm_source',
    title: 'Source',
    placeholder: 'The referrer (e.g. google, newsletter)',
    required: true,
  },
  {
    id: 'utm_medium',
    name: 'utm_medium',
    title: 'Medium',
    placeholder: 'Marketing medium (e.g. cpc, banner, email)',
    required: true,
  },
  {
    id: 'utm_campaign',
    name: 'utm_campaign',
    title: 'Campaign Name',
    placeholder: 'Product, promo code, or slogan (e.g. spring_sale)',
    required: true,
  },
  {
    id: 'utm_term',
    name: 'utm_term',
    title: 'Term',
    placeholder: 'Identify the paid keywords',
    required: false,
  },
  {
    id: 'utm_content',
    name: 'utm_content',
    title: 'Content',
    placeholder: 'Use to differentiate ads',
    required: false,
  },
];

export default Fields;
