import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ConditionOperator = React.memo(({ value, onChange, ...rest }) => {
  return (
    <Select
      name="operator"
      value={value}
      onChange={onChange}
      variant="standard"
      fullWidth
      required
      {...rest}
    >
      <MenuItem value="if">IF</MenuItem>
      <MenuItem value="ifnot">IF NOT</MenuItem>
    </Select>
  );
});

export default ConditionOperator;
