import React from 'react';

import Box from '@mui/material/Box';
// import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const getConditionOperator = (operator) =>
  operator === 'if'
  ? <CheckCircleIcon />
  : <DoNotDisturbOnIcon />

const getConditionTitle = (type) => {
  switch (type) {
    case 'continent':
      return 'Continent';
    case 'country':
      return 'Country';
    case 'region':
      return 'Region';
    case 'geoarea':
      return 'Geo area';
    case 'language':
      return 'Language';
    case 'os':
      return 'Operating System';
    case 'browser':
      return 'Browser';
    case 'browserengine':
      return 'Browser Engine';
    case 'devicetype':
      return 'Device Type';
    case 'datetime':
      return 'Date / Time';
    case 'timezone':
      return 'Time zone';
    case 'ipaddress':
      return 'IP Address';
    case 'referer':
      return 'Referer';
    default:
      return '';
  };
};

const isTargetEmpty = (target) =>
  Object.keys(target).length === 0;

const ConditionsPreview = React.memo(({ conditions }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {conditions.map((condition) => 
        <Chip
          key={condition.id}
          icon={getConditionOperator(condition.operator)}
          label={!isTargetEmpty(condition.target) ? getConditionTitle(condition.type) : 'No conditions'}
          size="small"
        />
      )}
    </Box>
  );
});

export default ConditionsPreview;
