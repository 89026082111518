import React, { useContext, useState, useEffect } from 'react';
import Firebase from 'firebase/app';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, connectAutoComplete } from 'react-instantsearch-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useIsMounted from 'ismounted';

import { OrganizationContext } from 'stores/organization';
import { LinksContext } from 'stores/links';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const searchIndex = process.env.REACT_APP_ENV;

const SearchBox = ({
  hits,
  currentRefinement,
  refine,
  selected,
  onSelect
}) => {
  const isMounted = useIsMounted();
  const { linksList } = useContext(LinksContext);
  const [ loading, setLoading ] = useState(true);
  const [ value, setValue ] = useState();
  
  useEffect(async () => {
    if (selected) {
      const index = linksList.findIndex(doc => doc.id === selected);

      const link = index !== -1
        ? linksList[index]
        : await Firebase
            .firestore()
            .collection('links')
            .doc(selected)
            .get()
            .then((snapshot) => {
              if (snapshot.exists) return snapshot;

              return false;
            });

      if (isMounted.current) {
        if (link.id) {
          setValue({
            title: link.data().title,
            objectID: link.id,
          })
        } else {
          setValue({
            title: 'Not found',
            objectID: 'deleted',
          })
        };

        setLoading(false);
      };
    }
  }, []);

  const handleChange = (e, hit) => {
    setValue(hit);
    onSelect(hit)
  };

  return (
    <Autocomplete
      id="link-search"
      loading={loading}
      options={hits}
      value={value ? value : null}
      onChange={handleChange}
      input={currentRefinement}
      onInputChange={(e, value) => refine(value)}
      getOptionLabel={(option) => `${option.title} (${option.objectID})`}
      isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
      renderInput={(params) => 
        <TextField
          {...params}
          label="URL"
          placeholder="Search link.."
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
          variant="standard"
          fullWidth
          required
        />
      }
      autoHighlight
      fullWidth
    />
  )
};

const LinkSearch = ({ type, selected, onSelect }) => {
  const { organizationId } = useContext(OrganizationContext);
  const CustomAutocomplete = connectAutoComplete(SearchBox);

  return (
    <InstantSearch
      indexName={searchIndex}
      searchClient={searchClient}
    >
      <Configure
        hitsPerPage={5}
        filters={`organization:${organizationId} AND type:${type}`}
      />

      <CustomAutocomplete
        selected={selected}
        onSelect={(val) => onSelect(val)}
      />
    </InstantSearch>
  );
};

export default LinkSearch;
