import React, { useState, useEffect, useContext } from 'react';
import Firebase from 'firebase/app';
import useIsMounted from 'ismounted';
import { LinksContext } from 'stores/links';

// Forms
import ShortForm from 'forms/short/Index';
import RedirectForm from 'forms/redirect/Index';
import ChoicesForm from 'forms/choices/Index';
import SplitForm from 'forms/split/Index';

// UI
import Modal from 'components/Modal';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';

const LinkModal = React.memo(({ type, id, save }) => {
  const isMounted = useIsMounted();

  const [ state, setState ] = useState();
  const [open, setOpen] = useState(false);

  const { linksList } = useContext(LinksContext);

  // Get link id on open
  useEffect(async () => {
    if (open && id) {
      const index = linksList.findIndex(doc => doc.id === id);

      const link = index !== -1
        ? linksList[index]
        : await Firebase
            .firestore()
            .collection('links')
            .doc(id)
            .get()
            .then((snapshot) => {
              if (snapshot.exists) return snapshot;

              return false;
            });

      if (isMounted.current) {
        setState(link.data());
      };
    }

    return () => setState();
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleCancel = () => setOpen(false);

  const handleSubmit = (link) => {
    if (!id)
      save(link.id);

    handleCancel();
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleOpen}>
        {id !== '' ? <EditIcon /> : <AddBoxIcon />}
      </IconButton>

      <Modal
        isOpen={open}
        handleClose={handleCancel}
        title={id !== '' ? `Editing ${id}` : 'Create'}
      >
        <DialogContent dividers>

          {id !== '' &&
            <Alert
              severity="warning"
              sx={{ marginBottom: (theme) => theme.spacing(2) }}
            >
              Careful here, you are editing the original link.
            </Alert>
          }

          {(type === 'short' &&
              <ShortForm
                id={id}
                data={state}
                submit={handleSubmit}
                cancel={handleCancel}
              />)
            || (type === 'redirect' &&
              <RedirectForm
                id={id}
                data={state}
                submit={handleSubmit}
                cancel={handleCancel}
              />)
            || (type === 'split' &&
              <SplitForm
                id={id}
                data={state}
                submit={handleSubmit}
                cancel={handleCancel}
              />)
            || (type === 'choice' &&
              <ChoicesForm
                id={id}
                data={state}
                submit={handleSubmit}
                cancel={handleCancel}
              />)
          }
        </DialogContent>
      </Modal>
    </React.Fragment>
  )
});

export default LinkModal;
