import Firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
};

Firebase.initializeApp(dbConfig);

const storage = Firebase.storage();
const functions = Firebase.app().functions('europe-west3');

// Dev env for functions
if (process.env.NODE_ENV === 'development')
  functions.useEmulator("localhost", 5001);

export { storage, functions };
