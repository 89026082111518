import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { AlertsProvider } from 'stores/alerts';
import { UserProvider } from 'stores/user';
import { SubscriptionProvider } from 'stores/subscription';
import { OrganizationsProvider } from 'stores/organizations';
import { InvitesProvider } from 'stores/invites';
import { UsageProvider } from 'stores/usage';
import { HeaderProvider } from 'stores/header';

import AccountLoading from 'components/AccountLoading';
import Routes from './Routes';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme();

const App = () =>
  <ThemeProvider theme={theme}>
    <UserProvider>
      <AlertsProvider>
        <SubscriptionProvider>
          <OrganizationsProvider>
            <InvitesProvider>
              <UsageProvider>
                <AccountLoading>
                  <HeaderProvider>
                    <BrowserRouter>
                      <Box sx={{ display: 'flex' }}>
                        <CssBaseline />

                        <Routes />
                      </Box>
                    </BrowserRouter>
                  </HeaderProvider>
                </AccountLoading>
              </UsageProvider>
            </InvitesProvider>
          </OrganizationsProvider>
        </SubscriptionProvider>
      </AlertsProvider>
    </UserProvider>
  </ThemeProvider>

export default App;
