import React from 'react';

// UI
import { Card as MuiCard } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';

const Card = ({ title, action, children }) =>
  <MuiCard
    variant="outlined"
    sx={{
      width: '100%',
      bgcolor: grey[50]
    }}
  >
    <CardHeader
      title={title}
      action={action}
    />

    <CardContent>
      {children}
    </CardContent>
  </MuiCard>

export default Card;
