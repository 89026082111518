import React from 'react';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { styled } from '@mui/material/styles';

/*
styled(Dialog)(({ theme }) => ({
  '& .MuiModal-root': {
    height: '100%',

    [theme.breakpoints.up('sm')]: {
      height: 500,
    },
  },
}));
*/

const Modal = ({ isOpen, handleClose, title, children }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="choice-modal"
      aria-describedby="choice-modal"
      fullScreen={isMobile}
      fullWidth
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography
            variant="body1"
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>

          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      {children}
    </Dialog>
  );
};

export default Modal;
