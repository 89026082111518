import React, { useContext, useState } from 'react';
import SplitContext from './Context';

// Components
import Destinations from './Components/Destinations';
import Title from 'forms/components/Title';
import Params from 'forms/components/Params';
import Groups from 'forms/components/Groups';
import UTM from 'forms/components/UTM';

// UI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const Form = ({ submit, cancel }) => {
  const [ loading, setLoading ] = useState(false);
  const { state, dispatch } = useContext(SplitContext);

  const handleSubmit = (e) => {
    setLoading(true);

    e.preventDefault();
    e.stopPropagation();

    submit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Title
          data={state.title}
          dispatch={dispatch}
        />
        
        <Destinations
          data={state.items}
          dispatch={dispatch}
        />

        <UTM
          data={state.utm}
          dispatch={dispatch}
        />

        <Params
          data={state.params}
          dispatch={dispatch}
        />

        <Groups
          data={state.groups}
          dispatch={dispatch}
        />

        <Stack
          direction="row"
          justifyContent="flex-end" 
          spacing={2}
        >
          <Button
            sx={{marginRight: '10px'}}
            variant="contained"
            color="secondary"
            onClick={cancel}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={loading && <CircularProgress sx={{color: '#fff'}} size={20} />}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;
