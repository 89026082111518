import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import iro from '@jaames/iro';

const createPicker = (container, ref) =>
  new iro.ColorPicker(ref, {
    width: container.offsetWidth,
    boxHeight: container.offsetHeight - 45,
    color: "rgb(255, 0, 0)",
    borderWidth: 1,
    borderColor: "#fff",
    layout: [
      {
        component: iro.ui.Box,
      },
      {
        component: iro.ui.Slider,
        options: {
          id: 'hue-slider',
          sliderType: 'hue'
        }
      }
    ]
  });

const Static = ({ onChange }) => {
  const containerRef = useRef();
  const elementRef = useRef();
  let colorPicker = null;

  useEffect(() => {
    colorPicker = createPicker(containerRef.current, elementRef.current);
    colorPicker.on('color:change', handleChange);

    return () => colorPicker.off('color:change', handleChange);
  }, []);

  const handleChange = (e) =>
    onChange({
      type: 'color',
      value: e.hexString,
    });

  return (
    <Box
      sx={{ height: '100%', minHeight: 500 }}
      ref={containerRef}
    >
      <div ref={elementRef}></div>
    </Box>
  );
};

export default Static;
