import Firebase from 'firebase/app';

import { prepareDocForCreate } from 'actions/helpers/firestoreHelpers'

const createOrganization = (values) => {
  // const { uid } = Firebase.auth().currentUser;

  // Create organization
  return Firebase.firestore()
    .collection('organizations')
    .add(prepareDocForCreate(values))
    .then((data) => data)
    .catch((error) => {
      alert(`Whoops, couldn't create the store: ${error.message}`)
    })

  // Add organization to the user
  /*
  await Firebase.firestore()
    .collection('users')
    .where('createdBy', '==', uid)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) =>
        doc.ref.update({
          organizations: Firebase.firestore.FieldValue.arrayUnion(organization.id) 
        })
      )
    });
  
  return organization;
  */
}

export default createOrganization;
