import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

const updateOrganization = (organizationId, values) => {

  ReactGA.event({
    category: 'Organization',
    action: 'Update organization',
  })

  const data = prepareDocForUpdate(values);

  return Firebase.firestore()
    .collection('organizations')
    .doc(organizationId)
    .set({
      ...data
    }, { merge: true });
}

export default updateOrganization;
