import { nanoid } from 'nanoid'

const item = (weight) => {
  return {
    id: nanoid(6),
    type: 'direct',
    url: 'https://',
    weight: weight,
  }
};

const initialState = () => {
  return {
    type: 'split',
    title: '',
    items: [
      item(50),
      item(50),
    ],
    groups: [],
  }
};

export { initialState, item }
