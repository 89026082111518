import React, { useState, createContext } from "react";

const HeaderContext = createContext();

const HeaderProvider = ({ children }) => {
  const [state, setState] = useState({
    title: 'Loading',
  });

  const setHeader = (obj) =>
    setState(prevState => ({
      ...prevState,
      ...obj,
    }));
  
  return (
    <HeaderContext.Provider value={{ ...state, setHeader }}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderProvider, HeaderContext };
