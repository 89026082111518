import React, { useContext } from 'react';

import { AlertsContext } from 'stores/alerts';
import Alert from '@mui/material/Alert';

const Alerts = () => {
  const { alertsData } = useContext(AlertsContext);

  return (
    alertsData.length
      ? alertsData.map((alert) => 
          <Alert severity="warning" key={alert.id}>
            {alert.message}
          </Alert>
        )
      : ''
  )
};

export default Alerts;
