const SET_STATE = 'SET_STATE';
const SET_FIELD = 'SET_FIELD';
const DELETE_FIELD = 'DELETE_FIELD';

const ADD_DESTINATION = 'ADD_DESTINATION';
const DELETE_DESTINATION = 'DELETE_DESTINATION';
const UPDATE_DESTINATION = 'UPDATE_DESTINATION';
const MOVE_DESTINATION = 'MOVE_DESTINATION';

const UPDATE_GROUPS = 'UPDATE_GROUPS';

export {
  SET_STATE,
  SET_FIELD,
  DELETE_FIELD,

  ADD_DESTINATION,
  DELETE_DESTINATION,
  UPDATE_DESTINATION,
  MOVE_DESTINATION,

  UPDATE_GROUPS, 
};
