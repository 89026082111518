import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { canCreate } from 'utilities/member';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const FabNewLink = () => {
  const { userId } = useContext(UserContext);
  const { organizationLoading, organizationError, organizationData } = useContext(OrganizationContext);

  return (
    <>
      {!organizationLoading && !organizationError && organizationData && canCreate(organizationData.roles[userId]) &&
        <Box
          sx={{
            '& > :not(style)': { m: 1 },
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <Fab
            component={ Link }
            to='new'
            color="primary"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
        </Box>
      }
    </>
  );
};

export default FabNewLink;
