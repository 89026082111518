import React, { useState, useEffect } from 'react';
import { useDebounce } from "rooks";
import axios from 'axios';

// UI
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';

const colors = [
  {
    label: 'White',
    value: 'white',
  },
  {
    label: 'Black',
    value: 'black',
  },
  {
    label: 'Yellow',
    value: 'yellow',
  },
  {
    label: 'Orange',
    value: 'orange',
  },
  {
    label: 'Red',
    value: 'red',
  },
  {
    label: 'Purple',
    value: 'purple',
  },
  {
    label: 'Magenta',
    value: 'magenta',
  },
  {
    label: 'Green',
    value: 'green',
  },
  {
    label: 'Teal',
    value: 'teal',
  },
  {
    label: 'Blue',
    value: 'blue',
  },
];

const useStyles = makeStyles({
  groupRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  buttonRoot: {
    width: 'calc(33.33% - 8px)', 
    height: '100px',
    padding: 0,
    border: 0,
    borderRadius: 0,
    overflow: 'hidden',
  },
  buttonLabel: {
    width: '100%',
    height: '100%',
  },
  buttonSelected: {
    border: '5px solid !important',
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
  },
});

// TODO: this should be called on link save, not every photo click
const download = (location) =>
  axios.get(`${process.env.REACT_APP_ANALYTICS_DOMAIN}/images/unsplash/download`, {
    params: {
      location,
    }
  });

const searchPhotos = (query, orientation, orderBy, color) =>
  axios.get(`${process.env.REACT_APP_ANALYTICS_DOMAIN}/images/unsplash/search`, {
    params: {
      query,
      orientation,
      orderBy,
      color,
      hits: 12,
    }
  }).then((res) => res.status === 200 ? res.data.response.results : []);

const Image = React.memo(({ onChange }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState('abstract');
  const [orientation, setOrientation] = useState('any');
  const [orderBy, setOrderBy] = useState('relevant');
  const [color, setColor] = useState('any');

  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState();

  const setQueryDebounced = useDebounce(setQuery, 500);

  const handleSearch = () =>
    searchPhotos(query, orientation, orderBy, color)
      .then((data) => {
        if (loading) setLoading(false);

        setImages(data)
      });

  const handleSelect = (e) => {
    const id = e.currentTarget.value;
    const image = images.find(image => image.id === id)

    // TODO: this should be called on link save, not every photo click
    download(image.links.download_location);

    setSelected(id);

    onChange({
      type: 'image',
      source: 'unsplash',
      urls: {
        thumb: image.urls.thumb,
        small: image.urls.small,
        regular: image.urls.regular,
        large: image.urls.regular.replace('1080', '1680'),
        full: image.urls.full,
      },
      author: {
        name: image.user.name,
        url: image.user.links.html,
      },
      credit: {
        name: 'Unsplash',
        url: 'https://unsplash.com',
      }
    });
  }

  // On query type
  useEffect(() => handleSearch(), [query, orientation, orderBy, color]);

  return (
    <Stack direction="column" spacing={2}>
      <TextField
        onChange={({ target }) => setQueryDebounced(target.value !== '' ? target.value : 'abstract')}
        placeholder="Search..."
        InputProps={{
          startAdornment:
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>,
        }}
        variant="standard"
        fullWidth
      />

      <Stack direction="row" spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="orientation-label" variant="standard">
            Orientation
          </InputLabel>

          <Select
            labelId="orientation-label"
            name="orientation"
            label="Orientation"
            value={orientation}
            onChange={(e) => setOrientation(e.target.value)}
            variant="standard"
          >
            <MenuItem value="any">
              Any
            </MenuItem>

            <MenuItem value="landscape">
              <Stack direction="row" alignItems="center" spacing={1}>
                <CropLandscapeIcon /> <span>Landscape</span>
              </Stack>
            </MenuItem>

            <MenuItem value="portrait">
              <Stack direction="row" alignItems="center" spacing={1}>
                <CropPortraitIcon /> <span>Portrait</span>
              </Stack>
            </MenuItem>

            <MenuItem value="square">
              <Stack direction="row" alignItems="center" spacing={1}>
                <CropSquareIcon /> <span>Square</span>
              </Stack>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="sortby-label" variant="standard">
            Sort by
          </InputLabel>

          <Select
            labelId="sortby-label"
            label="Sort by"
            name="orderBy"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            variant="standard"
          >
            <MenuItem value="relevant">
              Relevance
            </MenuItem>
            <MenuItem value="latest">
              Newest
            </MenuItem>
          </Select>
        </FormControl>
        
        <FormControl fullWidth>
          <InputLabel id="color-label" variant="standard">
            Color
          </InputLabel>

          <Select
            labelId="color-label"
            name="color"
            label="Color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            variant="standard"
          >
            <MenuItem value="any">
              Any
            </MenuItem>

            {colors.map((color, i) =>
              <MenuItem value={color.value} key={i}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <FiberManualRecordIcon sx={{ color: color.value }} />
                  <span>{color.label}</span>
                </Stack>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Stack>

      <Box>
        {loading &&
          <Grid container spacing={2}>
            {[...Array(12)].map((x, i) =>
              <Grid item key={i} xs={4}>
                <Skeleton variant="rectangular" width="100%" height="100px" />
              </Grid>
            )}
          </Grid>
        }

        <ToggleButtonGroup
          value={selected}
          onChange={handleSelect}
          classes={{ root: classes.groupRoot }}
          aria-label="select image"
        >
          {images.map((image) =>
            <ToggleButton
              key={image.id}
              value={image.id}
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel,
                selected: classes.buttonSelected,
              }}
            >
              <img
                src={image.urls.thumb}
                className={classes.buttonImage}
                alt={image.alt_description}
              />
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
    </Stack>
  );
});

export default Image;
