import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  connectAutoComplete,
} from 'react-instantsearch-dom';

import { HeaderContext } from 'stores/header';
import { OrganizationContext } from 'stores/organization';

// UI
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const searchIndex = process.env.REACT_APP_ENV;

const SearchBox = ({
  organization,
  hits,
  refine,
}) => {
  const handleChange = (e) => refine(e.target.value);
  const [ links, setLinks ] = useState([]);

  useEffect(() => {
    setLinks(hits);
  }, [hits]);

  return (
    <Stack spacing={2}>
      <TextField
        placeholder="Search"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
        fullWidth
      />

      {links.length
        ? links.map((item) =>
            <Link
              key={item.objectID}
              to={`/${organization}/${item.objectID}`}
            >
              {item.title}
            </Link>
          )
        : <p>No results</p>
      }
    </Stack>
  );
};

const Search = () => {
  const { organizationId, organizationData } = useContext(OrganizationContext);
  const { setHeader } = useContext(HeaderContext);

  const CustomAutocomplete = connectAutoComplete(SearchBox);

  useEffect(() => setHeader({ title: 'Search' }), []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        paddingTop: (theme) => theme.spacing(2),
        paddingBottom: (theme) => theme.spacing(2),
      }}
    >
      <InstantSearch
        indexName={searchIndex}
        searchClient={searchClient}
      >
        <Configure
          hitsPerPage={10}
          filters={`organization:${organizationId}`}
        />

        <CustomAutocomplete
          organization={organizationData.slug}
        />
      </InstantSearch>
    </Container>
  );
}

export default Search;
