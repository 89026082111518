import React, { useContext } from 'react';

import { UserContext } from 'stores/user';
import { SubscriptionContext } from 'stores/subscription';
import { OrganizationsContext } from 'stores/organizations';
import { InvitesContext } from 'stores/invites';
import { UsageContext } from 'stores/usage';

import CircularProgress from '@mui/material/CircularProgress';

const AccountLoading = ({ children }) => {
  const { userLoading } = useContext(UserContext);
  const { subscriptionLoading } = useContext(SubscriptionContext);
  const { organizationsLoading } = useContext(OrganizationsContext);
  const { invitesLoading } = useContext(InvitesContext);
  const { usageLoading } = useContext(UsageContext);

	if (userLoading || subscriptionLoading || organizationsLoading || invitesLoading || usageLoading)
		return (
      <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </div>
    );

  return children
};

export default AccountLoading;
