import React, { useState } from 'react';

import { ADD_CONDITION, DELETE_CONDITION } from '../Constants';

import Condition from './Condition';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReorderIcon from '@mui/icons-material/Reorder';
import DoneIcon from '@mui/icons-material/Done';

const Conditions = ({ id, conditions, dispatch }) => {
  const [ editing, setEditing ] = useState(false);

  const addCondition = () =>
    dispatch({
      type: ADD_CONDITION,
      value: id,
    })


  const deleteCondition = (index) => {
    dispatch({
      type: DELETE_CONDITION,
      value: {
        id: id,
        index, index,
      }
    })

    if (conditions.length < 2) setEditing(false);
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="p">
          Conditions:
        </Typography>

        <Stack
          direction="row"
          spacing={1}
        >
          {conditions.length > 1 &&
            <Button
              startIcon={editing ? <DoneIcon /> : <ReorderIcon />}
              size="small"
              onClick={() => setEditing(!editing)}
            >
              {editing ? 'Done' : 'Edit'}
            </Button>
          }

          <Button
            onClick={addCondition}
            startIcon={<AddBoxIcon />}
            size="small"
          >
            Add condition
          </Button>
        </Stack>
      </Stack>

      {conditions.map((condition, index) =>
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Condition
            id={id}
            index={index}
            operator={condition.operator}
            type={condition.type}
            target={condition.target}
            dispatch={dispatch}
          />

          {conditions.length > 1 && editing &&
            <IconButton onClick={() => deleteCondition(index)}>
              <DeleteIcon />
            </IconButton>
          }
        </Stack>
      )}
    </Stack>
  );
};

export default Conditions;
