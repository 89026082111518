import React from 'react';

import { EDIT_PARAM } from '../Constants';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Param = ({ index, data, dispatch, ...rest }) => {

  const handleChange = ({ target }) =>
    dispatch({
      type: EDIT_PARAM,
      value: {
        index: index,
        field: target.name,
        value: target.value,
      },
    });

  return (
    <Card {...rest}>
      <CardContent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
        >
          <TextField
            name="key"
            label="Key name"
            value={data.key}
            onChange={handleChange}
            variant="standard"
            fullWidth
            required
          />

          <TextField
            name="value"
            label="Key value"
            value={data.value}
            onChange={handleChange}
            variant="standard"
            fullWidth
            required
          />
        </Stack>
      </CardContent>
    </Card>
  );

};

export default Param;
