import React from 'react';
import { Link } from 'react-router-dom';

// Components
import ClickStatsCard from 'components/cards/ClickStatsCard';
import Card from 'components/Card';

// UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Group = ({ id, data, edit, remove }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleEdit = () => {
    edit(id, data);
    handleClose();
  };

  const handleRemove = () => {
    remove(id); 
    handleClose();
  };

  return (
    <React.Fragment>
      <Card
        title={
          <Link
            to={`${data.slug}/analytics`}
            style={{
              textDecoration: 'none',
              color: 'blue',
            }}
          >
            <Stack direction="row">
              <Typography>
                {data.title}
              </Typography>

              <ChevronRightIcon />
            </Stack>
          </Link>
        }
        action={
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
      >
        <Box
          component={Link}
          to={data.slug}
          sx={{ padding: 1 }}
        >
          <ClickStatsCard
            column="sections"
            id={id}
          />
        </Box>
      </Card>

			<Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleRemove}>Delete</MenuItem>
      </Menu>	
    </React.Fragment>
  );
};

export default Group;
