import React from 'react';
import Options from './Options';

import { SET_STATE } from '../../Constants';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const Programs = ({ dispatch }) => {

  const handleChange = (data) =>
    dispatch({
      type: SET_STATE,
      value: data
    });

  return (
    <Autocomplete
      autoHighlight
      filterSelectedOptions
      disableClearable={true}
      // value={data}
      onChange={(e, option) => handleChange(option)}
      options={Options}
      getOptionLabel={(option) => option.title}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select from predefined programs..."
          variant="standard"
        />
      )}
    />
  );
};

export default Programs;
