import React, { useContext } from 'react';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { ParamsContext } from 'stores/params';

// Components
import Create from './Components/Create';
import List from './Components/List';

// UI
import Title from 'components/Title';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const ParamsPage = () => {
  const { organizationData } = useContext(OrganizationContext);
  const { userId } = useContext(UserContext);
  const { paramsData } = useContext(ParamsContext);

  // TODO: Use utilites
  const hasPrivileges = () => ['owner'].includes(organizationData.roles[userId]);

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>
          Custom params
        </Title>

        {hasPrivileges() &&
          <Create />
        }
      </Stack>

      {paramsData.length
        ? <List
            data={paramsData}
            hasPrivileges={hasPrivileges}
          />
        : <Alert severity="info">
            You have not added any paramters yet.
          </Alert>
      }
    </Stack>
  );
};

export default ParamsPage;
