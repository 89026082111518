const SET_STATE = 'SET_STATE';

const SET_FIELD = 'SET_FIELD';
const DELETE_FIELD = 'DELETE_FIELD';

const EDIT_TITLE = 'EDIT_TITLE';
const EDIT_DESCRIPTION = 'EDIT_DESCRIPTION';

const EDIT_AVATAR = 'EDIT_AVATAR';
const DELETE_AVATAR = 'DELETE_AVATAR';

const EDIT_BACKGROUND = 'EDIT_BACKGROUND';
const DELETE_BACKGROUND = 'DELETE_BACKGROUND';

const ADD_CHOICE = 'ADD_CHOICE';
const EDIT_CHOICE = 'EDIT_CHOICE';
const MOVE_CHOICE = 'MOVE_CHOICE';
const DELETE_CHOICE = 'DELETE_CHOICE';

export {
  SET_STATE,

  SET_FIELD,
  DELETE_FIELD,

  EDIT_TITLE,
  EDIT_DESCRIPTION,

  EDIT_AVATAR,
  DELETE_AVATAR, 

  EDIT_BACKGROUND,
  DELETE_BACKGROUND,

  ADD_CHOICE,
  EDIT_CHOICE,
  MOVE_CHOICE,
  DELETE_CHOICE,
};
