import React, { useState, useContext, useEffect } from 'react';

// Data
import { ParamsContext } from 'stores/params';
import Param from './Param';
import AddParam from './Add';

// UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const CustomParams = ({ id, data, change }) => {
  const { paramsData } = useContext(ParamsContext);
  const [ params, setParams ] = useState([]);

  useEffect(() => {
    change(params);
  }, [params]);

  useEffect(() => {
    if (id !== 'custom') {
      const index = paramsData.findIndex((utm) => utm.id === id);

      if (index !== -1) setParams(paramsData[index].data().params);
    } else {
      data.length
        ? setParams(data)
        : setParams([]);
    };
  }, [id]);

  const handleAdd = (param) =>
    setParams(prevState => ([
      ...prevState,
      {
        ...param,
      }
    ]));

  const handleChange = (param, index) =>
    setParams(prevState => ([
      ...prevState.map((item, i) =>
        i === index
          ? {
              ...param,
            }
          : item
      )
    ]));

  const handleDelete = (index) =>
    setParams(prevState => ([
      ...prevState.filter((item, i) => i!== index),
    ]));

  return (
    <Stack spacing={2}>
      
      <Stack
        component={Paper}
        variant="outlined"
        spacing={2}
        sx={{ padding: (theme) => theme.spacing(params.length ? 2 : 0) }}
      >
        {params && params.length
          ? params.map((param, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <Param
                  index={index}
                  data={param}
                  change={handleChange}
                  disabled={id !== 'custom'}
                />

                {id === 'custom' &&
                  <IconButton
                    onClick={() => handleDelete(index)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              </Stack>
            )
          })
          : <Alert severity="info">
              Start by adding your first custom parameter
            </Alert>
        }
      </Stack>

      {id === 'custom' &&
        <AddParam
          add={handleAdd}
          error={params.length === 0}
        />
      }
    </Stack>
  );
};

export default CustomParams;
