import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Firebase from 'firebase/app';

import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ConfirmDialog from 'components/ConfirmDialog';

const Leave = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { organizationLoading, organizationData, organizationId } = useContext(OrganizationContext);
  const { userLoading, userId } = useContext(UserContext);
	const navigate = useNavigate();

  const openConfirm = () => setConfirmOpen(true);

  const leaveOrganization = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .set({
        roles: {
          [userId]: Firebase.firestore.FieldValue.delete()
        }
      }, { merge: true })
      .then(() => navigate('/'));

  return (
    <>
      {organizationLoading && userLoading && <p>Loading</p>}

      {!organizationLoading && !userLoading && organizationData &&
        <>
          <Container maxWidth="sm">
            <Typography variant="h4" component="h1" gutterBottom>
              Leave organization
            </Typography>

            <Typography variant="body1">
              If you are here, we hope you know what you doing. In this section you can remove your self from this organization, by doing this you will loose access to any of its data and links.

              All data you have created will remain.
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              onClick={openConfirm}>
                Leave organization
            </Button>
          </Container>

          <ConfirmDialog
            title={`Leave ${organizationData ? organizationData.title : 'organization'}`}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={leaveOrganization}
          >
            This operation is irreversible, are you sure you want to leave this organization?
          </ConfirmDialog>
        </>
      }
    </>
  );
};

export default Leave;
