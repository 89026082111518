import React, { useState } from 'react';
import Firebase from 'firebase/app'

import ConfirmDialog from 'components/ConfirmDialog';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Delete = ({ organization, program }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const removeProgram = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organization)
      .update({
        affiliates: Firebase.firestore.FieldValue.arrayRemove(program)
      })

  return (
    <>
      <IconButton onClick={() => setConfirmOpen(true)}>
        <DeleteIcon />
      </IconButton>

      <ConfirmDialog
        title="Remove affiliate program?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={removeProgram}
      >
       Are you sure you want to remove this affiliate program from your links? 
      </ConfirmDialog>
    </>
  );
};

export default Delete;
