import React from 'react';

// Components
import CustomParamsSelect from 'components/CustomParamsSelect';
import CustomParams from 'components/CustomParams';

// UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const deepEqual = (object1, object2) => {
  if (object1 === undefined) return false; 
  if (object2 === undefined) return false; 

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}

const isObject = (object) => {
  return object != null && typeof object === 'object';
}

const arePropsEqual = (prev, next) => deepEqual(prev.data, next.data);

const Params = React.memo(({ data, dispatch }) => {

  const handleAdd = () =>
    dispatch({
      type: 'SET_FIELD',
      value: {
        field: 'params',
        value: {
          id: 'custom',
          params: [],
        },
      },
    })

  const handleRemove = () =>
    dispatch({
      type: 'DELETE_FIELD',
      value: 'params',
    })

  const handleSelect = (selected) =>
    dispatch({
      type: 'SET_FIELD',
      value: {
        field: 'params',
        value: {
          id: selected.id,
          params: [],
        },
      },
    })

  const handleChange = (params) =>
    dispatch({
      type: 'SET_FIELD',
      value: {
        field: 'params',
        value: {
          ...data,
          params: [
            ...params,
          ],
        },
      },
    })

  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" component="p">
          Custom params:
        </Typography>

        <IconButton
          onClick={() => data ? handleRemove() : handleAdd()}
        >
          {data
            ? <RemoveIcon />
            : <AddIcon />
          }
        </IconButton>
      </Stack>

      {data && 
        <Stack spacing={2}>
          <CustomParamsSelect
            data={data.id}
            change={handleSelect}
          />

          <CustomParams
            id={data.id}
            data={data.params}
            change={handleChange}
          />
        </Stack>
      }
    </Stack>
  );
}, arePropsEqual);

export default Params;
