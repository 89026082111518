import React from 'react';

import { UPDATE_CONDITION } from '../Constants';

// Components
import ConditionOperator from './ConditionOperator';
import ConditionType from './ConditionType';
import ConditionValues from './ConditionValues';

// UI
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

const Condition = React.memo(({ id, index, operator, type, target, dispatch }) => {

  const dispatchChanges = (field, value) =>
    dispatch({
      type: UPDATE_CONDITION,
      value: {
        id: id,
        index: index,
        field: field,
        value: value,
      }
    });

  const handleChange = e =>
    dispatchChanges(e.target.name, e.target.value);

  const handleTypeChange = e => {
    dispatchChanges("target", {});
    dispatchChanges(e.target.name, e.target.value);
  }

  const handleValueChange = (data) =>
    dispatchChanges("target", data);

  return (
    <Stack
      component={Paper}
      elevation={3}
      spacing={2}
      sx={{ width: '100%', padding: '1rem' }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        sx={{ width: '100%' }}
      >
        <ConditionOperator
          value={operator}
          onChange={handleChange}
          sx={{ width: '30%' }}
        />

        <ConditionType
          value={type}
          onChange={handleTypeChange}
        />
      </Stack>

      <ConditionValues
        type={type}
        target={target}
        onChange={handleValueChange}
      />
    </Stack>
  );
});

export default Condition;
