import React, { useState, useContext } from 'react';
import { Outlet } from 'react-router';
import { Link, useNavigate, useParams, useMatch } from 'react-router-dom';

// Data
import { OrganizationProvider } from 'stores/organization';
import { GroupsProvider } from 'stores/groups';
import { UTMProvider } from 'stores/utms';
import { ParamsProvider } from 'stores/params';
import { LinksProvider } from 'stores/links';
import { HeaderContext } from 'stores/header';

// Components
import OrganizationLoading from 'components/OrganizationLoading';
import Alerts from 'components/Alerts';
import ResponsiveMenu from 'components/ResponsiveMenu';
import MobileNavigation from 'components/MobileNavigation';
import HeaderBar from 'components/HeaderBar';
import Info from 'views/organizations/components/Info';
import FabNewLink from 'components/FabNewLink';

// UI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkIcon from '@mui/icons-material/Link';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import Fab from '@mui/material/Fab';
import GroupsIcon from '@mui/icons-material/Groups';

const Organization = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => setMenuOpen(!menuOpen);

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIndex = useMatch('/:slug');

  const { title } = useContext(HeaderContext);
  const { slug } = useParams();

  return (
    <OrganizationProvider>
      <GroupsProvider>
        <UTMProvider>
          <ParamsProvider>
            <LinksProvider>
              <OrganizationLoading>
              <ResponsiveMenu
                open={menuOpen}
                handleClose={handleMenuToggle}
              >
                <Toolbar>
                  <Button
                    component={Link}
                    to="/"
                    color="inherit"
                    aria-label="navigate back"
                    startIcon={<HomeIcon />}
                  >
                    Home
                  </Button>
                </Toolbar>

                <Divider />

                <Box sx={{ p: 2 }}>
                  <Info />
                </Box>

                <Divider />

                <List onClick={handleMenuToggle}>
                  <ListItem component={ Link } to={`/${slug}`}>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>

                    <ListItemText primary="Dashboard" />
                  </ListItem>

                  <ListItem component={ Link } to="links">
                    <ListItemIcon>
                      <LinkIcon />
                    </ListItemIcon>

                    <ListItemText primary="Links" />
                  </ListItem>

                  <ListItem component={ Link } to="groups">
                    <ListItemIcon>
                      <GroupsIcon />
                    </ListItemIcon>

                    <ListItemText primary="Groups" />
                  </ListItem>

                  <ListItem component={ Link } to="search">
                    <ListItemIcon>
                      <ManageSearchIcon />
                    </ListItemIcon>

                    <ListItemText primary="Search" />
                  </ListItem>

                  <ListItem component={ Link } to="analytics">
                    <ListItemIcon>
                      <AnalyticsIcon />
                    </ListItemIcon>

                    <ListItemText primary="Analytics" />
                  </ListItem>

                  <ListItem component={ Link } to="settings">
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>

                    <ListItemText primary="Settings" />
                  </ListItem>
                </List>
              </ResponsiveMenu>

              <Box
                component="main"
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  pb: () => isMobile ? 7 : 0,
                }}
              >
                <HeaderBar title={title}>
                  {!isIndex &&
                    <IconButton
                      onClick={() => navigate(-1)}
                      edge="start"
                      color="inherit"
                      aria-label="navigate back"
                      sx={{
                        display: {
                          md: 'none',
                        }
                      }}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  }
                </HeaderBar>

                <Alerts />

                <Outlet />

                {!isMobile && <FabNewLink />}
              </Box>

              <MobileNavigation>
                <BottomNavigationAction
                  label="Menu"
                  onClick={handleMenuToggle}
                  icon={<MenuIcon />}
                /> 

                <BottomNavigationAction
                  label="Analytics"
                  value="analytics"
                  icon={<AnalyticsIcon />}
                  onClick={() => navigate(`/${slug}/analytics`)}
                /> 

                <Box sx={{ flexGrow: 1 }} />

                <Fab
                  color="primary"
                  aria-label="create link"
                  sx={{
                    position: 'absolute',
                    zIndex: 1,
                    top: -10,
                    left: 0,
                    right: 0,
                    margin: '0 auto',
                  }}
                  size="medium"
                  onClick={() => navigate(`/${slug}/new`)}
                >
                  <AddIcon />
                </Fab>

                <BottomNavigationAction
                  label="Links"
                  value="links"
                  icon={<LinkIcon />}
                  onClick={() => navigate(`/${slug}/links`)}
                /> 

                <BottomNavigationAction
                  label="Search"
                  value="search"
                  icon={<SearchIcon />}
                  onClick={() => navigate(`/${slug}/search`)}
                /> 
              </MobileNavigation>
              </OrganizationLoading>
            </LinksProvider>
          </ParamsProvider>
        </UTMProvider>
      </GroupsProvider>
    </OrganizationProvider>
  );
};

export default Organization;
