import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'

import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { canEdit, canDelete } from 'utilities/member';

import LinksRefs from 'components/LinksRefs';
import LinkActions from 'views/organizations/components/LinkActions';
import ClickCounter from 'views/organizations/components/ClickCounter';
import CopyLinkToClipboard from 'components/CopyLinkToClipboard';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Alert from '@mui/material/Alert';

const LinksList = React.memo(({ data }) => {
  const { userId } = useContext(UserContext);
  const { organizationData } = useContext(OrganizationContext);

  return (
    <React.Fragment>
      {data.length
        ? <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="right">Created</TableCell>
                  <TableCell align="right">Link</TableCell>
                  <TableCell align="center">Clicks</TableCell>
                  <TableCell align="right" width={20}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((link) =>
                  <TableRow key={link.id}>
                    <TableCell component="th" scope="row">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        {organizationData.link === link.id &&
                          <Tooltip title="This is a primary link of this organization">
                            <FiberManualRecordIcon
                              fontSize="small"
                              sx={{ color: 'blue' }}
                            />
                          </Tooltip>
                        }

                        <Link to={`/${organizationData.slug}/${link.id}`}>
                          {link.data().title}
                        </Link>
                  
                        {link.data().refsFrom && link.data().refsFrom.length
                          ? <LinksRefs data={link.data().refsFrom}>
                              <AttachFileIcon fontSize="small" />
                            </LinksRefs>
                          : null
                        }
                      </Stack>
                    </TableCell>
                    
                    <TableCell component="th" scope="row">
                      <p>{link.data().type}</p>
                    </TableCell>

                    <TableCell align="right">
                      {format(link.data().createdOn.toDate(), 'dd MMM')}
                    </TableCell>

                    <TableCell align="right">
                      <CopyLinkToClipboard link={link.id} />
                    </TableCell>

                    <TableCell align="center">
                      <ClickCounter link={link.id} />
                    </TableCell>

                    <TableCell align="right">
                      <LinkActions
                        linkId={link.id}
                        linkData={link.data()}
                        canEdit={canEdit(organizationData.roles[userId])}
                        canDelete={canDelete(organizationData.roles[userId])}
                        isPrimary={organizationData.link === link.id}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
      : <Alert severity="info">
          Oh, you haven't added any links yet
        </Alert>
      }
    </React.Fragment>
  );
});

export default LinksList;
