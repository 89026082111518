import React, { useContext } from 'react';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { UTMContext } from 'stores/utms';

// Components
import Create from './Components/Create';
import List from './Components/List';

// UI
import Title from 'components/Title';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const UTMPage = () => {
  const { organizationData } = useContext(OrganizationContext);
  const { userId } = useContext(UserContext);
  const { utmsData } = useContext(UTMContext);

  // TODO: Use utilites
  const hasPrivileges = () => ['owner'].includes(organizationData.roles[userId]);

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>
          UTM Codes  
        </Title>

        {hasPrivileges() &&
          <Create />
        }
      </Stack>

      {utmsData.length
        ? <List
            data={utmsData}
            hasPrivileges={hasPrivileges}
          />
        : <Alert severity="info">
            You have not added any UTM codes yet
          </Alert>
      }
    </Stack>
  );
};

export default UTMPage;
