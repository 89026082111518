import React, { useState, useEffect, createContext } from "react";
import Firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';

const UserContext = createContext();

const UserProvider = (props) => {
	const [user, loading, error] = useAuthState(Firebase.auth());

  const [state, setState] = useState({
    userLoading: true,
    userError: false,
    userId: null,
    userData: null,
  });

  const setUser = (value) => setState(prevState => ({
    ...prevState,
    ...value,
  }));

  useEffect(() => {
    if (!loading) {
      if (user) {
        const unsubscribe = Firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .onSnapshot((doc) => {
            setState(prevState => ({
              ...prevState,
              userLoading: false,
              userError: false,
              userId: user.uid,
              userData: doc.data(),
              setUser,
            }));
          });

        return () => unsubscribe();

      } else {
        setState({
          userLoading: false,
          userError: false,
          userId: null,
          userData: null,
        });
      };
    };
  }, [loading, user, error]);

  return (
    <UserContext.Provider value={state}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
