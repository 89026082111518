import React from 'react';
import { UPDATE_DEFAULT } from '../Constants';

// Components
import Link from 'components/Link';

// UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const DefaultDestination = React.memo(({ type, url, dispatch }) => {

  const dispatchAction = (field, value) =>
    dispatch({
      type: UPDATE_DEFAULT,
      value: {
        field: field,
        value: value
      }
    });

  const handleLinkChange = (link) => {
    dispatchAction('url', link.url);
    dispatchAction('type', link.type);
  }
  
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Typography
          variant="h6"
          component="p"
        >
          Default URL:
        </Typography>

        <Tooltip title="This URL will be used in case redirects don't match">
          <IconButton size="small" sx={{ padding: 0 }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>

      <Link
        type={type}
        url={url}
        save={handleLinkChange}
      />
    </Stack>
  );
});

export default DefaultDestination;
