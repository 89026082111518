import React from 'react';

const imageStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

const Color = ({ data }) => {
  return <div style={{...imageStyle, backgroundColor: data.value}} />
}

const Gradient = ({ data }) =>
  <div style={{...imageStyle, backgroundImage: data.value}} />

const Image = ({ data }) => {
  const image = data.upload ? URL.createObjectURL(data.upload) : data.urls.small;

  return <img src={image} style={{...imageStyle}} />
}

const getBackgroundUI = (background) => {
  switch (background.type) {
    case 'color':
      return <Color data={background} />;
    case 'gradient':
      return <Gradient data={background} />;
    case 'image':
      return <Image data={background} />;

    default:
      return <Color data={background} />;
  };
};

const Preview = React.memo(({ background }) => {
  return getBackgroundUI(background)
});

export default Preview;
