import React, { useState } from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Conditions from './Conditions';
import Link from 'components/Link';
import ConditionsPreview from './ConditionsPreview';

import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { UPDATE_REDIRECT, DELETE_REDIRECT } from '../Constants';

const Redirect = React.memo(({ id, data, editing, dispatch }) => {
  const [ expanded, setExpanded ] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dispatchAction = (field, value) =>
    dispatch({
      type: UPDATE_REDIRECT,
      value: {
        id: id,
        field: field,
        value: value
      }
    });

  const handleLinkChange = (link) => {
    dispatchAction('url', link.url);
    dispatchAction('type', link.type);
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      ref={setNodeRef}
      style={style}
    >
      {editing &&
        <IconButton {...attributes} {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      }

      <Accordion
        component={Paper}
        elevation={expanded && !editing ? 6 : 1}
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded && !editing}
        sx={{ width: '100%' }}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            }
          }}
        >
          <Stack
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Link
              type={data.type}
              url={data.url}
              save={handleLinkChange}
            />

            {(editing || !expanded) &&
              <ConditionsPreview conditions={data.conditions} />
            }
          </Stack>

          {!editing &&
            <IconButton onClick={() => setExpanded(!expanded)}>
              {(expanded && !editing) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Conditions
            id={id}
            conditions={data.conditions}
            dispatch={dispatch}
          />
        </AccordionDetails>
      </Accordion>

      {editing &&
        <IconButton
          onClick={() =>
            dispatch({
              type: DELETE_REDIRECT,
              value: id,
            })
          }
        >
          <DeleteIcon />
        </IconButton>
      }
    </Stack>
  );
});

export default Redirect;
