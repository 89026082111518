import React from 'react';

import { EDIT_HOSTNAME } from '../Constants';

import TextField from '@mui/material/TextField';

const Hostname = ({ data, dispatch }) => {

  const handleChange = ({ target }) =>
    dispatch({
      type: EDIT_HOSTNAME,
      value: target.value,
    });

  return (
    <TextField
      name="hostname"
      label="Hostname"
      value={data}
      onChange={handleChange}
      variant="standard"
      fullWidth
      required
    />
  );
};

export default Hostname;
