import React from 'react';

// UI
import TextField from '@mui/material/TextField';

const arePropsEqual = (prev, next) => prev.data === next.data;

const Title = React.memo(({ data, dispatch }) => {

  const handleChange = (e) =>
    dispatch({
      type: 'SET_FIELD',
      value: {
        field: 'title',
        value: e.target.value,
      }
    })

  return (
    <TextField
      label="Title"
      name="title"
      value={data}
      onChange={handleChange}
      variant="standard"
      required
      fullWidth
    />
  );
}, arePropsEqual);

export default Title;
