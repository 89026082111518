import React, { useState, useContext, useEffect } from 'react';

// Data
import { GroupsContext } from 'stores/groups';

// Components
import GroupForm from 'forms/Group';

// UI
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const compareArrays = (a1, a2) => {
  if (a1 === undefined) return true; 
  if (a2 === undefined) return true; 

  let i = a1.length;

  while (i--) {
    if (a1[i] !== a2[i]) return false;
  }

  return true
}

const arePropsEqual = (prev, next) =>
  compareArrays(prev.data, next.data);

const GroupsSelect = React.memo(({ data, change }) => {
  const { groupsData } = useContext(GroupsContext);

  const [ loading, setLoading ] = useState(true);
  const [ selected, setSelected ] = useState(data ? data : []);
  const [ options , setOptions  ] = useState([]);
  const [ formOpen, setFormOpen ] = useState(false);
  const [ formData, setFormData ] = useState();

  const toggleForm = () => setFormOpen(!formOpen);

  useEffect(() => {
    change(selected); 
  }, [selected]);

  useEffect(() => {
    const parsed = groupsData.reduce((acc, group) => {
      acc.push({
        id: group.id,
        title: group.data().title,
        slug: group.data().slug,
      });

      return acc;
    }, []);

    setOptions(parsed);
    setLoading(false);
  }, [groupsData.length]);

  const handleCreate = (newGroup) => {
    setSelected(prevState => ([
      ...prevState,
      newGroup.id
    ]));

    toggleForm();
  };

  return (
    <React.Fragment>
      {!loading 
        ? <Autocomplete
            multiple
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            value={selected}
            options={options}
            noOptionsText="No groups"
            isOptionEqualToValue={(option, value) => option.id === value}

            onChange={(e, newValue) => {
              const value = newValue[newValue.length - 1];

              if (value && value.inputValue) {
                setFormData({
                  title: value.inputValue,
                });

                toggleForm();
              } else {
                const ids = newValue.reduce((acc, sel) => {
                  acc.push(sel.id ? sel.id : sel);

                  return acc;
                }, [])

                setSelected(ids);
              };
            }}

            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '')
                filtered.push({
                  id: Math.floor(Math.random() * 1000000),
                  inputValue: params.inputValue,
                  title: `Create "${params.inputValue}" group`,
                });

              return filtered;
            }}

            getOptionLabel={(option) => option.inputValue
              ? option.inputValue
              : option.title
            }

            renderOption={(props, option) =>
              <li
                {...props}
                key={option.id}
              >
                {option.title}
              </li>
            }

            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const indexInOptions = options.findIndex(item => item.id === option);

                if (indexInOptions !== -1)
                  return (
                    <Chip
                      label={options[indexInOptions].title}
                      {...getTagProps({ index })}
                    />
                  )

                return (
                  <Chip
                    label="Group deleted"
                    {...getTagProps({ index })}
                  />
                )
              })
            }

            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select groups"
                variant="standard"
              />
            )}
          />
        : <p>Loading</p>
      }

      <GroupForm
        open={formOpen}
        data={formData}
        submit={handleCreate}
        cancel={toggleForm}
      />
    </React.Fragment>
  );
}, arePropsEqual);

export default GroupsSelect;
