import Firebase from 'firebase/app';
import { nanoid } from 'nanoid';

import { prepareDocForCreate } from '../helpers/firestoreHelpers';

const createLink = async (values) => {
  const linkId = nanoid(6);

  // Default redirect
  if (values.default) {
    if (values.default.type !== "direct")
      await Firebase
        .firestore()
        .collection('links')
        .doc(values.default.url)
        .update({
          refsFrom: Firebase.firestore.FieldValue.arrayUnion(linkId)
        })
  };

  // Redirects/Splits/Choices
  if (values.items) {
    const refs = values.items.reduce((acc, item) => {
      if (item.type !== "direct")
        acc.push(item.url)
        
      return acc;
    }, []);

    if (refs.length)
      await Promise.all(refs.map((ref) =>
        Firebase
          .firestore()
          .collection('links')
          .doc(ref)
          .update({
            refsFrom: Firebase.firestore.FieldValue.arrayUnion(linkId)
          })
      ));
  };

  await Firebase
    .firestore()
    .collection('links')
    .doc(linkId)
    .set(prepareDocForCreate(values));

  return {
    id: linkId,
  };
}

export default createLink
