import React from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';

const MobileNavigation = ({ children }) => {
  return (
    <Paper
      sx={{
        display: {
          md: 'none',
        },
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
      }}
      elevation={3}
    >
      <BottomNavigation sx={{ justifyContent: 'space-around' }}>
        {children}
      </BottomNavigation>
    </Paper>
  );
};

export default MobileNavigation;
