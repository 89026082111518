const initialState = () => {
  return {
    type: 'short',
    title: '',
    url: '',
    groups: [],
  }
};

export { initialState }
