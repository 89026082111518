import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { HeaderContext } from 'stores/header';

// Utilities
import { canCreate } from 'utilities/member';

// Components
import ShortForm from 'forms/short/Index';
import RedirectForm from 'forms/redirect/Index';
import ChoicesForm from 'forms/choices/Index';
import SplitForm from 'forms/split/Index';

// UI
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LinkNew = () => {
  const { userId } = useContext(UserContext);
  const { organizationLoading, organizationData } = useContext(OrganizationContext);
  const { setHeader } = useContext(HeaderContext);

  const [type, setType] = useState('short');
	const navigate = useNavigate();

  useEffect(() => setHeader({ title: 'New link'}), []);

  const handleChange = (event) => setType(event.target.value);

  const handleSubmit = () => navigate(-1);

  const handleCancel = () => navigate(-1);

  if (organizationLoading) return <LinearProgress />

  return (
    <React.Fragment>
      {organizationLoading
        ? <LinearProgress />
        : <Container
            maxWidth="sm"
            sx={{ marginTop: (theme) => theme.spacing(2) }}
          >
            <FormControl
              variant="standard"
              sx={{ marginBottom: (theme) => theme.spacing(2) }}
              fullWidth
            >
              <InputLabel id="link-type-label">
                Type
              </InputLabel>

              <Select
                labelId="link-type-label"
                id="link-type"
                value={type}
                label="Type"
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value={'short'}>Short</MenuItem>
                <MenuItem value={'redirect'}>Redirect</MenuItem>
                <MenuItem value={'choice'}>Choice</MenuItem>
                <MenuItem value={'split'}>Split A/B</MenuItem>
              </Select>
            </FormControl>

            {canCreate(organizationData.roles[userId])
              ? (type === 'short' &&
                  <ShortForm
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
                || (type === 'redirect' &&
                  <RedirectForm
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
                || (type === 'split' &&
                  <SplitForm
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
                || (type === 'choice' &&
                  <ChoicesForm
                    submit={handleSubmit}
                    cancel={handleCancel}
                  />)
              : <p>Huh, it seems like you don't have a permission to create</p>
            }
          </Container>
      }
    </React.Fragment>
  );
};

export default LinkNew;
