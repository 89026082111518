import React, { useContext } from 'react';
import { Link } from 'react-router-dom'

import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TuneIcon from '@mui/icons-material/Tune';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DeleteIcon from '@mui/icons-material/Delete';

const Menu = () => {
  const { organizationData } = useContext(OrganizationContext);
  const { userId } = useContext(UserContext);

  const isOwner = () => ['owner'].includes(organizationData.roles[userId]);

  return (
    <List component="nav">
      <ListItem component={Link} to='general'>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>

        <ListItemText primary="General" />
      </ListItem>

      <Divider component="li" />

      <ListItem component={Link} to='members'>
        <ListItemIcon>
          <GroupAddIcon />
        </ListItemIcon>

        <ListItemText primary="Members" />
      </ListItem>

      <Divider component="li" />

      <ListItem component={Link} to='utm'>
        <ListItemIcon>
          <TrackChangesIcon />
        </ListItemIcon>

        <ListItemText primary="UTM Codes" />
      </ListItem>

      <Divider component="li" />

      <ListItem component={Link} to='params'>
        <ListItemIcon>
          <TuneIcon />
        </ListItemIcon>

        <ListItemText primary="Custom params" />
      </ListItem>

      <Divider component="li" />

      {isOwner() &&
        <React.Fragment>
          <ListItem component={Link} to="affiliates">
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>

            <ListItemText primary="Affiliate programs" />
          </ListItem>

          <Divider component="li" />
        </React.Fragment>
      }
      
      {isOwner() &&
        <React.Fragment>
          <ListItem component={Link} to='delete'>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>

            <ListItemText primary="Delete organization" />
          </ListItem>

          <Divider component="li" />
        </React.Fragment>
      }

      {!isOwner() &&
        <ListItem component={Link} to='leave'>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>

          <ListItemText primary="Leave organization" />
        </ListItem>
      }
    </List>
  );
};

export default Menu;
