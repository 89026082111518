const SET_STATE = 'SET_STATE';

const SET_FIELD = 'SET_FIELD';
const DELETE_FIELD = 'DELETE_FIELD';

const UPDATE_DEFAULT = 'UPDATE_DEFAULT';

const ADD_REDIRECT = 'ADD_REDIRECT';
const UPDATE_REDIRECT = 'UPDATE_REDIRECT';
const DELETE_REDIRECT = 'DELETE_REDIRECT';
const MOVE_REDIRECT = 'MOVE_REDIRECT';

const ADD_CONDITION = 'ADD_CONDITION';
const UPDATE_CONDITION = 'UPDATE_CONDITION';
const DELETE_CONDITION = 'DELETE_CONDITION';

export {
  SET_STATE,

  SET_FIELD,
  DELETE_FIELD,

  UPDATE_DEFAULT,

  ADD_REDIRECT,
  UPDATE_REDIRECT,
  DELETE_REDIRECT,
  MOVE_REDIRECT,

  ADD_CONDITION,
  UPDATE_CONDITION,
  DELETE_CONDITION,
};
